import React, { useEffect, useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AmitGrnle from "../../assets/img/AmitGrnle.png";
import DpRoy from "../../assets/img/DpRoy.webp";
import BumtSh from "../../assets/img/BumtSh.png"
import DepkPrjapti from "../../assets/img/DepkPrjapti.png"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1345 },
    items: 4,
   
  },
  superdesktop: {
    breakpoint: { max: 1345, min: 1000 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1000, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};


function  Trendingsection() {
  const [isAutoplay, setIsAutoplay] = useState(false);

  useEffect(() => {
    // Check screen size on initial load and whenever it changes
    const checkScreenSize = () => {
      const isSmallScreen = window.matchMedia("(max-width: 1340px)").matches;
      setIsAutoplay(isSmallScreen); // Enable autoplay if below 1300px
    };

    checkScreenSize(); // Run on mount
    window.addEventListener("resize", checkScreenSize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", checkScreenSize); // Cleanup
    };
  }, []);
  return (
    <div
      id="lifeaays"
      className="blog-success-section2 scroll-section"
    >
      <div className="customer-section2-body margin-box">
        <div className="success-story-page-section-2-Header-text">
        Trending Employee Content
        </div>
        <div className="customer-section2-body">
          <div className="section2-box">
           

            <Carousel
              responsive={responsive}
              dotListClass="custom-dot-list-style"
             
              arrows={false}
              autoPlay={isAutoplay}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
            >
                  <CustomerSectionBox
                picture={BumtSh }
                title1="Building a Thriving Analytics Career: Insights from Bhumit Shah, AVP - Analytics"
                date="Dec 24, 2024 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/analytics-career-bhumit-shah-interview"
              />
             
               <CustomerSectionBox
                picture={DpRoy}
                title1="Dip Roy on Building Successful Data Science Careers: Insights, Skills, and Emerging Trends"
                date="Dec 18, 2024 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/data-science-careers-dip-roy-interview"
              />
             
              
              
              <CustomerSectionBox
                picture={AmitGrnle}
                title1="Empowered to Learn and Grow: Amit Girnale's Data Analytics Career Growth at Aays"
                date="Oct 24, 2024 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/amit-girnale-data-analytics-career-growth"
              />
              <CustomerSectionBox
                picture={DepkPrjapti}
                title1="Deepak's Data Engineering Career: Insights, Challenges, and Advice for Aspiring Professionals"
                date="Nov 21, 2024 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/deepak-data-engineering-career-insights"
              />
             
              
              
            </Carousel>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trendingsection;
