import React from "react";
import ParticleBackground from "./Network";
import background from "../../assets/img/test-animation-background.webp";
import "./NewHeader.scss";
import gridImage from "../../assets/img/test-animation-grid.webp";

function NewHeaderSectionMobile(props) {
  return (
    <>
      <div
        className="test-animation-mobile "
        style={{
          height: "calc(100vh - 65px)",
          backgroundImage: `url(${background})`,
          position: "relative",
        }}
      >
        <div className="main-container-mobile">
          <div className="grid-container-mobile">
            <div className="top-mobile">
              <img src={props.picture} alt="cpg" />
            </div>
            <div className="bottom-mobile">
              <div className="main-bottom">
                <p className="heading-mobile">
                  {props.text1 && (
                    <>
                      {props.text1} <br />
                    </>
                  )}
                  {props.text2 && (
                    <>
                      {props.text2} <br />
                    </>
                  )}
                  {props.text3 && (
                    <>
                      {props.text3} <br />{" "}
                    </>
                  )}
                  {props.text4 && <>{props.text4}</>}
                </p>

                <p className="subHeading-mobile">
                  {props.subtext1} <br /> {props.subtext2}{" "}
                </p>
              </div>
              <div className="grid-image">
                <img src={gridImage} alt="bottom-grid" />
              </div>
            </div>
          </div>
        </div>

        <ParticleBackground />
      </div>
      {/* <div
        className="section2"
        style={{
          height: "80vh",
          backgroundColor: "#212121",
        }}
      ></div> */}
    </>
  );
}

export default NewHeaderSectionMobile;
