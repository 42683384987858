import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiBanner from "../../assets/img/ind_blogs_Apache_Spark.jpg";
import apache_img from "../../assets/img/apache_img.png";
import blogs_15_body_img from "../../assets/img/blogs_15_body_img.png";
import { Helmet } from "react-helmet";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import Blgfvcn from "../../assets/img/Blgfvcn.png";
import SocialShare from "./newBLog/SocialShare";



function ApacheSparkPerformanceOptimization() {
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };
  

  useEffect(() => {

    document.title = "Apache Spark | Performance Optimization";
    document.getElementsByTagName("META")[3].content =
      "Apache Spark assists businesses in resolving data processing issues due to its quick data-processing capabilities. Here are key performance optimization strategies. ";
  })

  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];

  return (
    <div id="homePage">
      <Helmet>
        <meta name="keywords" content="Apache Spark" />
      </Helmet>
      <Navbar bg="black" />
      <GoogleAnalytics />

      <div className="GenAi-body newclas">
        <div className="margin-blogMore">
          <div className="GenAi-main">
            <div className="border-genai">
              <div className="new-box-seventy">
                <div className="back-button" style={{ paddingTop: "2rem" }}>
                  {data.map((ele, index) => (
                    <div className="back" key={index}>
                      <a href={ele.url} className="content" key={index} style={{ color: 'black' }}>
                        {ele.text}
                      </a>
                      {index !== data.length - 1 && (
                        <div style={{ color: "black" }}>/</div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flexteamaays" style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                  <div>
                    <img src={Blgfvcn} alt="aays" className="imagi" />
                  </div>
                  <div style={{ fontWeight: '500' }}>
                    <span className="cntheading" style={{ marginRight: '6px' }}>Team Aays</span>
                    <span className="cntheading sixjan" style={{ marginRight: '6px' }} >.</span>
                    <span className="cntheading sixjan" >Nov 30</span>

                  </div>
                </div>
                <h1 className="main-heading fontweight mainheaders">
                  {" "}
                  Performance Optimization for Apache Spark

                </h1>

                <img
                  className="blogImg"
                  // style={{ filter: "grayscale(1)" }}
                  src={GenAiBanner}
                  alt="blog1"
                />

                <div className="imgContent">
                  <p className="heading-content cntheading">

                    Apache Spark assists businesses in resolving data processing issues due to its quick data-processing capabilities. Any data processing program's performance is a crucial factor to consider while it's being developed. The following are the most important performance optimization strategies in Apache Spark.
                  </p>
                  <h2 className="heading headings-headingss">
                    Shuffling - Key Performance Tuning Method


                  </h2>
                  <p className="heading-content cntheading">
                    Data is sent across distinct nodes in a spark cluster in the case of spark. The graph below depicts the shuffling process in Spark. Data is shuffled between the left-hand processing nodes and the right-hand processing nodes.
                  </p>
                  <h4 className="heading-four fontweights headingfour">
                    Shuffling process in Spark
                  </h4>

                  <p className="heading-content">
                    <img src={apache_img} alt="apache_img" />
                  </p>







                  <h2 className="heading headings-headingss">Cost associated with Shuffling


                  </h2>



                  <p className="heading-content cntheading">
                    The data sent during the shuffling process is transferred over the network, which is why shuffling is costly. Large data transfers over the network are always going to be costly in terms of system performance.
                  </p>
                  <h2 className="heading headings-headingss">Key points




                  </h2>
                  <ul>
                    <li className="liclass"> Shuffling of data across different nodes on a spark cluster is expensive in terms of performance.

                    </li>
                    <li className="liclass">Depending on the fields on which the aggregate operations are carried out on a cluster, the data shuffling can be reduced.

                    </li>                </ul>


                  <h2 className="heading headings-headingss"> Partitioning for Optimized Performance







                  </h2>
                  <p className="heading-content cntheading">
                    Partitioning a spark dataframe appropriately can help you go a long way in optimizing the performance. For instance, consider a group by operation in a spark dataframe so as to get the sum of a field for each group. If there are further operations on the same group where you need to find out various other parameters for this group, then partitioning the entire dataframe based on this key will be a great performance optimization step. This ensures that the data that belongs to the same group will always go to the same node. This reduces the need for shuffling as data is already segregated as we want.                  </p>
                  <p className="heading-content cntheading">
                    The graph below depicts how partitioning on a spark dataframe works.


                  </p>
                  <p className="heading-content">
                    <img src={blogs_15_body_img} alt="apache_img" />
                  </p>
                  <p className="heading-content cntheading">
                    So, partitioning is a process by which data is segregated into small chunks on the basis of a certain field. As a result, the data belonging to the same group segregated this way always goes to the same location or partition. Since the data goes to the same location for the records with the same field value, the shuffling is reduced. For example, if the employees are segregated based on the department, each employee record belonging to the same department goes to the same partition. Any operations on the employee records grouped by department will thus avoid any data shuffling between nodes as the data is already segregated and is ready for the operation.
                  </p>

                  <h2 className="heading headings-headingss">Key Points related to Shuffling and Partitioning









                  </h2>
                  <ul>
                    <li className="liclass"> Repartitioning data on a spark dataframe involves shuffling. Partitioning is an operation where the data with similar key values needs to be grouped together which requires transfer of data across different nodes.

                    </li>
                    <li className="liclass">Join operation combines 2 or more data sets on the basis of a key. This often leads in values getting transferred across the nodes so as to aggregate data across different nodes. So effectively a join operation leads to shuffling.

                    </li>
                    <li className="liclass"> Shuffling of data across different nodes on a spark cluster is expensive in terms of performance.Effectively any operations based by keys can result in a shuffling.

                    </li>
                    <li className="liclass"> Contrary to the above, mapping operation doesn’t require data to be transferred across the nodes. Similar are the cases with operations like the filter and the unions. These operations specifically deal with the data transformations in each of the individual nodes and hence they don’t add to the shuffling

                    </li>
                    <li className="liclass"> It’s always handy to do the filtering operations on a dataframe beforehand so that the operations will be on this filtered smaller dataset.



                    </li>   </ul>


                  <h2 className="heading headings-headingss">Lazy Execution in Spark











                  </h2>
                  <p className="heading-content cntheading">
                    The execution of operations in spark is in the lazy mode. For example, the code for a filter operation in spark dataframe doesn’t get executed at the same point where the code is written. Instead, it maintains some information about the order in which these steps will be executed which are called the DAGs. Effectively if the filtered result is going to be used by several processes, the calculation of the final filter dataframe happens in each of these cases.
                  </p>
                  <p className="heading-content cntheading">
                    To explain this more clearly, consider the below code. This is a looped operation in which for each execution of the loop a computation is done on a dataframe called the filtered_df. Assume that the filtere_df is a result of the filtering operation from another dataframe called inbound_df. Every time the below operation is called on the filtered_df, the filtering operation is performed on the inbound_df as the spark does the lazy evaluation.
                  </p>
                  <p
                    className="heading-content"
                    style={{
                      width: "48%",
                      backgroundColor: "#D3D3D3",
                      padding: "1rem",
                      borderRadius: "6px",
                      fontWeight: 700,
                    }}
                  >
                    for obj in list_objects:
                    <br />
                    compute_df = compute_dataframe(filtered_df,obj) percentage_df =
                    calculate_percentage(compute_df) export_as_csv(percentage_df)
                  </p>

                  <h2 className="heading headings-headingss">Caching in Spark


                  </h2>

                  <p className="heading-content cntheading">
                    To avoid the recomputation of the data frame as shown above, every time an operation is called, a technique called caching is employed. The idea is to store the dataframe on which the computations are called in a cache. So, instead of recomputing the data frame on the invocation of an operation, the dataframe that is previously stored in the cache is retrieved and subsequent operations are invoked                  </p>
                  <h2 className="heading headings-headingss">Conclusion












                  </h2>
                  <p className="heading-content cntheading">
                    As it can be seen from the examples above, Apache Spark is an excellent tool for processing massive amounts of data really quickly. However, when it comes to use cases involving large-scale data processing, it's important to remember its best practices. The things mentioned above are some of the most crucial suggestions for maximizing Apache Spark's performance.                  </p>


       <SocialShare />


                </div>
              </div>
            </div>
          </div>
        </div>










        <div>

        </div>

      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default ApacheSparkPerformanceOptimization;
