import React, { useEffect, useState } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiBanner from "../../assets/img/Spotlight- Aays-Avinash.webp";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import Blgfvcn from "../../assets/img/Blgfvcn.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Acoordion from "./newBLog/Acoordion"
import { freq29 } from "./newBLog/Acdiondatas";
import Newsectionmobile from "../../pageLayout/CustomerSuccessStoryLayout/Newsectionmobile";
import Futureai from "../../assets/img/FutureAI-driven.png"
import Stratgeties from "../../assets/img/AI-First-Strategies.png";
import { useMediaQuery } from "@mui/material";
import dwarika from "../../assets/img/Blogs-Dwarika.png"

import SocialShare from "./newBLog/SocialShare";
import AvinashSection from "./AvinashSection";




const SupplyChainDat = [
  {
    id: 1,
    //heading: "Innovation Analytics ",
    content:
      "Aays at CDO Vision NYC 2025: Key Takeaways on AI-First Strategies for Enterprises",
    redirectURL: "/blogs/ai-first-strategy-enterprise-transformation-cdo-vision-2025",
    image: Stratgeties,
  },
  {
    id: 2,
    // heading: "Decision Intelligence",
    content:
      "The Future of AI-Powered Decision Intelligence: Insights from Industry Leaders",
    redirectURL:
      "/blogs/ai-powered-decision-intelligence",
    image: Futureai,
  },
  {
    id: 3,
    // heading: "Inventory Analytics",
    content:
      "Generative AI in Finance – Dwarika Patro Discusses AaDi’s Impact on Analytics and Decision-Making",
    redirectURL: "https://blog.aaysanalytics.com/post/generative-ai-in-finance-analytics-dwarika-patro-and-aim-research",
    image: dwarika,
  },
 
]

function AvinashBlogs() {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });



  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };



  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];

  return (
    <div id="homePage" >
      <Helmet>
        <meta
          name="keywords"
          content="Power BI Beginner Power BI Best Practices"
        />
      </Helmet>

      <Navbar bg="black" />
      <GoogleAnalytics />

      <div className="GenAi-body newclas">
        <div className="margin-blogMore">
          <div className="GenAi-main">
            <div className="border-genai">
              <div className="new-box-seventy">
                <div className="back-button" style={{ paddingTop: "2rem" }}>
                  {data.map((ele, index) => (
                    <div className="back" key={index}>
                      <a href={ele.url} className="content" key={index} style={{ color: 'black' }}>
                        {ele.text}
                      </a>
                      {index !== data.length - 1 && (
                        <div style={{ color: "black" }}>/</div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flexteamaays" style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                  <div>
                    <img src={Blgfvcn} alt="aays" className="imagi" />
                  </div>
                  <div style={{ fontWeight: '500' }}>
                    <span className="cntheading" style={{ marginRight: '6px' }}>Team Aays</span>
                    <span className="cntheading sixjan" style={{ marginRight: '6px' }} >.</span>
                    <span className="cntheading sixjan" >March 26</span>

                  </div>
                </div>
                <h1 className="main-heading fontweight mainheaders">
                  {" "}


                  Building Data Science Teams: A Leadership Perspective with Avinash Sidha                  </h1>

                <img
                  className="blogImg"
                  // style={{ filter: "grayscale(1)" }}
                  src={GenAiBanner}
                  alt="blog1"
                />

                <div className="imgContent">
                 
                  <div style={{ borderLeft: '4px solid rgb(30,179,200)' }}>
                    <p className="heading-italic">
                      "Data science isn’t just about algorithms and models; it’s about the people behind them. A strong data science team is built on trust, collaboration, and continuous learning. Investing in upskilling, mentorship, and employee-centric policies is the key to long-term success in this field."                                               </p></div>

                  <p className="italic-para" style={{ marginTop: '2rem', lineHeight: '1.3' }}>

                    Building a successful data science team goes beyond just hiring the right talent—it requires a culture of learning, mentorship, and employee-centric policies that foster innovation and long-term success. At Aays, Avinash Sidha, VP - Operations, has been instrumental in shaping high-performing teams that thrive in a fast-evolving AI and data landscape. In this interview, Avinash shares his leadership insights on building data science teams, upskilling talent, and creating an employee-centric workplace culture.                   </p>
                  <h4 className="heading-four fontweights headingfour mrgintop">You've been with Aays since its early days, playing a key role in shaping its growth. What inspired you to join Aays, and what has kept you motivated on this journey?  </h4>

                  <p className="heading-content cntheading">
                    Avinash: I was drawn to Aays because of its dynamic approach to technology and AI. Rather than sticking to a single technology, Aays embraces technological advancements, integrating <a target="_blank"
                      rel="noopener noreferrer" className="anchor" href="https://www.aaysanalytics.com/ai-innovations-enterprise-solutions">cutting-edge innovations </a>. What has kept me motivated is the company's strong employee-centric culture, which fosters both professional and personal growth. Every challenge here is an opportunity to learn, and being surrounded by such talented individuals makes the journey truly rewarding.
                  </p>

                  <h4 className="heading-four fontweights headingfour mrgintop"> Aays is known for its employee-centric policies. How do you ensure a great experience for teams from an operational perspective?
                  </h4>

                  <p className="heading-content cntheading">
                    Avinash: At <a className="anchor" target="_blank"
                      rel="noopener noreferrer" href="https://www.aaysanalytics.com/ ">Aays</a>, we prioritize a work environment where employees feel valued and empowered. Here’s how we make it happen:                   </p>
                  <ol>
                    <li className="liclass">Transparent Communication – Open discussions, team huddles, and anonymous feedback loops ensure that every voice is heard</li>
                    <li className="liclass">Upskilling and Mentorship – We provide access to certifications, training workshops, and one-on-one mentoring to help employees grow. </li>
                    <li className="liclass">Flexible Work Arrangements – Our hybrid work model allows teams to balance personal and professional commitments effectively. </li>
                    <li className="liclass">Recognition and Career Growth – Regular appreciation programs and internal promotions help retain top talent. </li>
                    <li className="liclass">Health and Well-being Initiatives – From mental health resources to fitness reimbursements, we invest in employee well-being. </li>

                  </ol>
                  <p className="heading-content cntheading">
                    Our dedication to these principles has been recognized through accolades such as the <a target="_blank"
                      rel="noopener noreferrer" className="anchor" href="https://blog.aaysanalytics.com/post/aays-great-place-to-work-certified">Great Place to Work certification </a>, reflecting our commitment to creating an empowering environment for our teams. By embedding compassion and empathy in our operations, we  are trying to ensure that Aays remains a place where employees feel supported, valued, and inspired to achieve excellence.                   </p>
                  <h4 className="heading-four fontweights headingfour mrgintop"> What are the key factors to consider when building a high-performing data science team?
                  </h4>

                  <p className="heading-content cntheading">
                    Avinash: Building <a target="_blank"
                      rel="noopener noreferrer" className="anchor" href="https://blog.aaysanalytics.com/post/data-science-careers-dip-roy-interview">data science </a>teams requires a mix of technical expertise and a strong collaborative culture. Here’s what I focus on: </p>                  <ol>
                    <li className="liclass">Hiring for a Growth Mindset – Technical skills are important, but adaptability and curiosity are what make a great fit at Aays. </li>
                    <li className="liclass">Cross-functional Collaboration – A strong data science team doesn’t work in silos; it works closely with business teams to drive impact.  </li>
                    <li className="liclass">Structured Learning and Upskilling – Providing continuous upskilling and mentorship opportunities ensures that our team stays ahead in this rapidly evolving field.  </li>
                    <li className="liclass">Diversity of Thought – Bringing together people with different backgrounds fosters <a target="_blank"
                      rel="noopener noreferrer" href="https://blog.aaysanalytics.com/post/aays-culture-innovation-career-growth" className="anchor"> innovation </a> and better problem-solving.  </li>

                  </ol>
                  <p className="heading-content cntheading">
                    By emphasizing these principles, I try to create a resilient team that delivers real business value.
                  </p>
                  <h4 className="heading-four fontweights headingfour mrgintop"> How does Aays invest in upskilling and mentorship for data scientists?
                  </h4>

                  <p className="heading-content cntheading">
                    Avinash: We believe that upskilling and mentorship are essential for staying relevant in the changing data science field. Some of our initiatives are:
                  </p>
                  <ul>
                    <li className="liclass">100% Certification Reimbursement – We encourage employees to get industry-recognized certifications in AI, ML, and analytic</li>
                    <li className="liclass">Mentorship Programs – Senior leaders guide junior team members through real-world projects, helping them gain practical insights. </li>
                    <li className="liclass">Regular Knowledge-Sharing Sessions – We conduct internal workshops to keep our teams updated.  </li>
                    <li className="liclass">AI and Data Science Hackathons – These events encourage creative problem-solving and hands-on learning.  </li>

                  </ul>
                  <p className="heading-content cntheading">
                    By continuously investing in our people, we ensure that our data science teams are always ahead of the curve.
                  </p>
                  <h4 className="heading-four fontweights headingfour mrgintop"> What leadership principles guide you in managing and scaling data science teams?
                  </h4>

                  <p className="heading-content cntheading">
                    Avinash:  I am a people person and strongly believe leadership is about creating an environment where people feel supported, valued, and empowered to do their best work. My style is rooted in trust and collaboration — I strive to build genuine relationships with my team, where open communication and mutual respect are at the core. For me, leadership is not about authority but about enabling others to shine and helping them navigate challenges with confidence. Core principles that guide me are:                    </p>
                  <ul>
                    <li className="liclass">Empowerment Through Trust – Giving my team autonomy leads to higher engagement and better results. </li>
                    <li className="liclass">Data as a Strategic Compass – Making informed, data-driven decisions is key to delivering business impact.  </li>
                    <li className="liclass">Learning and Growing Together – Change is constant, and I encourage my team to embrace continuous learning.   </li>
                    <li className="liclass">Collaboration Over Hierarchy – I believe in flat structures where ideas, not titles, drive decisions.   </li>

                  </ul>
                  <h4 className="heading-four fontweights headingfour mrgintop">What makes Aays' workplace culture unique for data science professionals?
                  </h4>

                  <p className="heading-content cntheading">
                    Avinash: Aays is not just another analytics firm; it’s a company that prioritizes people. From our employee-centric policies to our emphasis on upskilling and mentorship, we create an environment where employees thrive.
                  </p>
                  <p className="heading-content cntheading">Our culture is built on: </p>
                  <ul>
                    <li className="liclass">A flat hierarchy – Encouraging open discussions and fast decision-making.  </li>
                    <li className="liclass">Flexible work policies – Allowing employees to work in a way that best suits them.   </li>
                    <li className="liclass">Diversity and Inclusion – Ensuring every voice is valued and heard.    </li>

                  </ul>
                  <p className="heading-content cntheading">
                    This culture of empowerment and inclusivity is what makes Aays not just a workplace, but <a target="_blank"
                      rel="noopener noreferrer" href="https://blog.aaysanalytics.com/post/50-best-firms-for-data-scientists-2024" className="anchor">one of the best companies in the industry </a> .                   </p>

                  <h4 className="heading-four fontweights headingfour mrgintop"> What do you enjoy doing outside of work that helps you stay energized and bring your best self to Aays? </h4>

                  <p className="heading-content cntheading">


                    Avinash: Outside of work, I find joy and balance in simple things that fuel my creativity and keep me grounded. Writing children’s stories, for instance, is something close to my heart. It lets me tap into a sense of wonder and imagination that often sparks new ways of thinking. There’s something magical about seeing the world through a child’s eyes — it reminds me to stay curious and open to possibilities.                   </p>
                  <p className="heading-content cntheading"> And of course, staying active is essential for me. Whether it’s hitting the gym or simply taking a walk to clear my head, these moments help me stay centered. They’re a quiet reminder that taking care of yourself is just as important as taking care of business.

                    In everything I do, whether at work or in life, I try to show up with kindness, curiosity, and a willingness to learn. That’s what keeps me energized and brings out my best self every day. </p>

                  <div style={{ borderTop: '1px solid rgb(221, 221, 221)', borderBottom: '1px solid rgb(221, 221, 221)', marginTop: '2rem', marginBottom: '2rem' }}>
                    <h4 className="heading-four fontweights headingfour mrgintop" style={{ marginTop: '1rem', marginBottom: '1rem' }}> About Avinash Sidha  </h4>

                    <p className="heading-content cntheading" style={{ marginBottom: '1rem' }}>
                      <a target="_blank"
                        rel="noopener noreferrer" className="anchor" href="https://www.linkedin.com/in/avinashsidha/">  Avinash Sidha </a>is the VP of Operations at Aays, specializing in driving operational excellence. With a strong belief in trust, mentorship, and employee-centricity, he fosters a culture of continuous learning and collaboration.                   </p>

                  </div>
                  <div style={{ paddingTop: '2rem', fontSize: '22px' }}> Frequently Asked Questions</div>
                  <div className="flex-containersi">
                    <div className="leftscontainers">
                      <div className="accordian-filessi">
                        <Acoordion datas={freq29} />
                      </div>
                    </div>
                  </div>
                  < SocialShare />

                </div>
              </div>

            </div>
          </div>
        </div>
        {isMobile ? (

          <div className="case-studies-pages-cls" style={{ paddingTop: "2.5rem", backgroundColor: "white" }}>
            <Newsectionmobile
              heading=" Our Recent Posts"
              data={SupplyChainDat}
            />
          </div>
        ) : (
          <div className="section" id="caseStudySection2">
            <AvinashSection />
          </div>
        )}
        <div>

        </div>

      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default AvinashBlogs;
