import React from 'react';
import linkdn from "../../../assets/img/Billionimages/linkedin-symbol.png";
import twitr from "../../../assets/img/Billionimages/twitter-symbol.png";
import facebook from "../../../assets/img/Billionimages/facebook-app-symbol.png";

const SocialShare = () => {
  const currentUrl = encodeURIComponent(window.location.href);

  const socialLinks = [
    { href: `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`, src: linkdn, alt: 'LinkedIn', width: 43, height: 20 },
    { href: `https://x.com/intent/tweet?url=${currentUrl}`, src: twitr, alt: 'X (formerly Twitter)', width: 40, height: 16 },
    { href: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`, src: facebook, alt: 'Facebook', width: 43, height: 20 },
  ];

  return (
    <div style={{ borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '10px 0' }}>
      <div style={{ display: 'flex', gap: '8px' }}>
        {socialLinks.map(({ href, src, alt, width, height }, index) => (
          <a key={index} href={href} target="_blank" rel="noopener noreferrer">
            <img 
              src={src} 
              alt={alt} 
              className="link-iconss cursor" 
              style={{ background: 'white', cursor: 'pointer', height: `${height}px`, width: `${width}px`, objectFit: 'contain' }} 
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialShare;
