import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import blog12 from "../../assets/img/Aays-First-Strategie.webp"
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import { useMediaQuery } from "@mui/material";
import greatplace from "../../assets/img/Dwarika-Gen-Ai.png"
import AaysFinancial from "../../assets/img/Aays_Financial Times_Statista .Square.jpg"
import Dataengin from "../../assets/img/Data Engineering.square.jpg";
import Leadersintop from "../../assets/img/Leadersintop.png"
import Section2Mobile from "../../pageLayout/CustomerSuccessStoryLayout/Section2Mobile";
import BlogsFinancialtimess from "../../assets/img/BlogsFinancialtimess.webp";
import { Helmet } from "react-helmet";



// import Section2Mobile from "../../pageLayout/CustomerSuccessStoryLayout/Section2Mobile";

import Blgfvcn from "../../assets/img/Blgfvcn.png";
import FirstPost from "./FirstPost";
import Newsectionmobile from "../../pageLayout/CustomerSuccessStoryLayout/Newsectionmobile";
import { SchemaFirstStrageties } from "../BlogSchema";
import SocialShare from "./newBLog/SocialShare";


const SupplyChainDat = [
  {
    id: 1,
    // title1: "Generative AI in Finance – Dwarika Patro",
    // title2: "Discusses AaDi’s Impact on Analytics and Decision-Making",
   content: "Generative AI in Finance – Dwarika Patro Discusses AaDi’s Impact on Analytics and Decision-Making ",
    redirectURL:"https://blog.aaysanalytics.com/post/generative-ai-in-finance-analytics-dwarika-patro-and-aim-research",
    image: greatplace,
  },
  {
    id: 2,
    heading: "Aays Listed in 500 High Growth Companies Asia Pacific 2024 by Financial Times",
    //content:
      //"The Next Era of Supply Chain Risk Management with AI Intelligence",
    redirectURL: "https://blog.aaysanalytics.com/post/aays-high-growth-companies-asia-pacific-2024",
    image: BlogsFinancialtimess ,
  },
  {
    id: 3,
    heading: "Aays Recognized as one of the Leaders in Data Engineering PeMa",
   // content:
     // "Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge",
    redirectURL: "https://blog.aaysanalytics.com/post/aim-ranks-aays-a-leader-in-data-engineering-service-providers",
    image:Leadersintop,
  },
 
  
];









function FirstStrageties() {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

 
  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];
  

  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };

  return (

    <div id="homePage" className="case-studies-pages-cls">
       <Helmet>
         <script type="application/ld+json">
                 {JSON.stringify(SchemaFirstStrageties)}
               </script>
      </Helmet>

      <Navbar bg="black" />
      <GoogleAnalytics />

      <div className="GenAi-body newclas">
        <div className="margin-blogMore">
          <div className="GenAi-main">
            <div className="border-genai">
            <div className="new-box-seventy">
              <div className="back-button" style={{paddingTop:"2rem"}}>
                {data.map((ele, index) => (
                  <div className="back" key={index}>
                    <a href={ele.url} className="content" key={index} style={{ color: 'black' }}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "black" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
              <div className="flexteamaays" style={{paddingBottom:'1rem',paddingTop:'1rem'}}>
                <div>
                <img src={ Blgfvcn} alt="aays" className="imagi" />
                </div>
                <div style={{fontWeight:'500'}}>
                <span className="cntheading" style={{marginRight:'6px'}}>Team Aays</span>
                <span className="cntheading sixjan"  style={{marginRight:'6px' }} >.</span>
                <span className="cntheading sixjan" >Feb 14</span>
                
                </div>
              </div>
              <h1 className="main-heading fontweight mainheaders">
                {" "}
                Aays at CDO Vision NYC 2025: Key Takeaways on AI-First Strategies for Enterprises 
              </h1>

              <img
                className="blogImg"
                // style={{ filter: "grayscale(1)" }}
                src={blog12}
                alt="blog1"
              /> 

              <div className="imgContent">
               



                <h3 className="heading heading-heading fontweight hadingthree"> Embracing AI-First Thinking in Enterprise Tech  </h3>
                <p className="heading-content cntheading">
                CDO Vision NYC 2025 brought together top Data Officers and AI leaders to
                 discuss the future of AI-driven enterprise transformation. As a partner of the event, Aays contributed to the discussions on
                 how enterprises can adopt AI-first strategies to drive innovation, efficiency, and competitive advantage. 
                </p>
               
                <h3 className=" heading heading-heading fontweight hadingthree">Why AI-First Strategies Matter  </h3>

                <p className="heading-content cntheading">
                Forward-thinking organizations are restructuring their tech stack around intelligence. The shift is clear - businesses that start with AI and build processes around it will outpace traditional workflows that add AI as an afterthought.                 </p>
                




                <h2 className="heading headings-headingss"> Takeaways from CDO Vision NYC 2025  </h2>

                <p className="heading-content cntheading">
                Here are the key insights from the all the discussions that took place during the event among the industry experts:                  </p>
                
                <h4 className="heading-four fontweights headingfour"> AI-First Strategy is the Future</h4>

              <p className="heading-content cntheading">
              Companies that deeply embed AI into their core operations will unlock new efficiencies, stronger decision-making
               capabilities, and a lasting competitive 
              edge. AI will no longer be just a tool.It will be  the foundation of modern enterprise tech. 
              </p>

              <h4 className="heading-four fontweights headingfour"> Data is the Foundation of AI Success </h4>

<p className="heading-content cntheading">
Even the most advanced AI models will fail without high-quality, well-governed data. Enterprises must prioritize data integrity, governance, and accessibility to fully capitalize on AI’s potential. 
</p>
<h4 className="heading-four fontweights headingfour">  Speed vs. Accuracy – Knowing the Trade-offs </h4>

<p className="heading-content cntheading">
AI applications vary—some require absolute precision (e.g., finance & risk management), while others prioritize real-time speed (e.g., customer service automation). Enterprises must balance these factors strategically
</p>
<h4 className="heading-four fontweights headingfour">  AI + Human Collaboration for Ethical AI </h4>

<p className="heading-content cntheading">
AI can enhance decision-making, but human oversight remains critical for ensuring ethical, fair, and empathetic AI implementation. The best AI solutions combine machine intelligence with human intuition. 
</p>
<h4 className="heading-four fontweights headingfour">Breaking Silos – AI Thrives in Unified Teams </h4>

<p className="heading-content cntheading">
The biggest AI-driven transformations will  happen when tech teams and business leaders align. Breaking silos and fostering cross-functional AI collaboration is key to unlocking real enterprise value.  
</p>

                <h2 className="heading headings-headingss">Aays’ Role in the AI-First Enterprise Evolution  </h2>

                <p className="heading-content cntheading">
                At Aays, we specialize in AI-powered solutions for finance and supply chain transformation. Our AI-first approach helps enterprises:                 </p>
                <ul>
                  <li className="liclass">Leverage AI for decision intelligence</li>
                  <li className="liclass">Build AI-powered financial analytics & risk management tools  </li>
                  <li className="liclass">Optimize supply chains with AI-driven insights  </li>
                </ul>

                

                <p className="heading-content cntheading">
                As a trusted AI & data engineering leader, we are committed to driving real business impact through AI-first innovation.                 </p>
                
             

              
                 

                

               


               

              
                <h2 className="heading headings-headingss">Looking Ahead     </h2>

                <p className="heading-content cntheading">
                CDO Vision NYC 2025 was an incredible opportunity to exchange ideas, connect with industry leaders, and shape the future of enterprise AI. We look forward to continuing these discussions and helping businesses unlock the full potential of AI. 
</p>
 <p className="heading-content cntheading">
 See you in <a href="https://aimresearch.co/events/cdo-vision/dallas-2025" className="anchor">CDO Vision Dallas 2025</a> and  <a href="https://aimresearch.co/events/cdo-vision/chicago-2025" className="anchor">CDO Vision Chicago 2025</a> .   
 </p>

 <SocialShare />


              </div>
            </div>
          </div>
          </div>
        </div>

             
            


       
      


       
        <div>
     
      </div>
      <div>
      {isMobile ? (
        <div style={{ paddingTop: "2.5rem",  }}>
          <Newsectionmobile
            heading="Recent Posts"
            data={SupplyChainDat}
          />
        </div>
      ) : (
        <div className="section" id="caseStudySection2">
          < FirstPost />
        </div>
      )} 
     
      
     
      </div>
      </div>

      



      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>


  );
}


export default FirstStrageties;
