import React, { useEffect, useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AmitGrnle from "../../assets/img/AmitGrnle.png";
import Tanishka from "../../assets/img/Tanishka-Shah.webp";
import Surbhjain from "../../assets/img/Surbhjain.png";
import LeftArrow from "../../pageLayout/CustomerSuccessStoryLayout/LeftArrow";
import RightArrow from "../../pageLayout/CustomerSuccessStoryLayout/RightArrow";
import { useMediaQuery } from "@mui/material";





  const data = [
    {
        id: 1,
        title1: "Data Analyst Consultant Insights ",
        title2: ": Career Growth Journey with Tanishka Shah",
        //content: "Inventory transformation and optimization: How Aays helped a global CPG company enhance its inventory management processes",
        class: "content-box-section2",
        url: "https://blog.aaysanalytics.com/post/data-analyst-consultant-insights-tanishka-shah",
        image:Tanishka,
      },
      {
        id: 2,
        title1: "Data Analyst Career Insights:",
         title2: " Interview with Saurabh Jain, Senior Data Analyst",
        //content: "The Next Era of Supply Chain Risk Management with AI Intelligence",
        class: "content-box-section2 inventrynew",
        url: "https://blog.aaysanalytics.com/post/data-analyst-career-insights-interview-with-saurabh-jain-senior-data-analyst",
        image: Surbhjain ,
      },
      {
        id: 3,
        title1: "Empowered to Learn and Grow: ",
         title2: "Amit Girnale's Data Analytics Career Growth at Aays",
       // content: "Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge",
        class: "content-box-section2 inventrynew",
        url: "https://blog.aaysanalytics.com/post/amit-girnale-data-analytics-career-growth",
        image: AmitGrnle,
      },
    
  ];
  
  

const Aaysllife = () => {
    const [startIndex, setStartIndex] = useState(0);

    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });
  
    const showLength = isIpad ? 2 : 3;
    const visibleItems = isIpad ? data.slice(startIndex, startIndex + 2) : data.slice(startIndex, startIndex + 3);
  
    return (
      <div
        id="section2-customer-success-story"
        className="customer-success-section2 scroll-section"
        style={{position: 'relative',backgroundColor:'black'}}
      >
        <div className="customer-section2-body margin-box">
          <h1 className="success-story-page-section-2-Header-text" style={{color:'white'}}>
          Life at Aays 
          </h1>
          
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {data.length > showLength && <LeftArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
            <div className="success-story-page-section-2-grid">
              {visibleItems.map((item) => (
                <div>
                  <CustomerSectionBox
                    picture={item.image}
                    title1={item.title1}
                    title2={item.title2}
                    content={item?.content}
                    class={item.class}
                    redirectURL={item.url}
                  />
                </div>
              ))}
            </div>
            {data.length > showLength && <RightArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
          </div>
        </div>
      </div>
    );
  }
export default Aaysllife