import React from "react";
import designElement from "../../assets/img/careerPageDesignElement.webp";
import EmployeeFeedback from "./EmployeeFeedback";
import Carousel from "react-multi-carousel";
import Bhumit from "../../assets/img/Bhumit.webp";
import RahulDesai from "../../assets/img/RahulDesai.webp";

const Data = [
  {
    id: 1,
    name: "Rahul Desai",
    designation: "Senior Data Engineer",
    image: RahulDesai,
    content:
      "The most rewarding aspect of being part of Aays is the unwavering support from its leaders. The leadership team here provides everyone with support and guidance to excel, fostering the growth of individuals into leaders in their respective domains.",
  },
  {
    id: 2,
    name: "Bhumit Shah",
    designation: "AVP - Analytics",
    image: Bhumit,
    content:
      "Working at Aays is like stepping into the forefront of analytics! This journey propels your growth holistically – Techno-managerial expertise, business know-how and communication skills. I would say, Aays is where the adventure of continuous learning happens!",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Section4({ isMobile = false }) {
  return (
    <div className="career-section4-new ">
      <div className="section4-main">
        <div className="item1">
          <div className="center">
            <h1 className="heading">Our Team Speaks </h1>
            <p>Get an inside look into Aays through the words of our team.</p>
          </div>
        </div>
        <div className="item2">
          <div className="carousel">
            <Carousel
              className="customer-success-main"
              responsive={responsive}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={false}
              draggable={false}
              showDots={!isMobile}
            >
              {Data.map((data) => (
                <EmployeeFeedback
                  content={data.content}
                  image={data.image}
                  name={data.name}
                  designation={data.designation}
                  key={data.id}
                />
              ))}
            </Carousel>
          </div>
        </div>
        <div className="background-design">
          <img loading="lazy" src={designElement} alt="Background-img" />
        </div>
      </div>
    </div>
  );
}

export default Section4;
