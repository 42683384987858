import React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./modalpopup.scss";

// Import images
import dataEngineeringImg from "../../assets/img/accolades/image4.png";
import genai from "../../assets/img/accolades/image1.png";
import dataScientist from "../../assets/img/accolades/FT_APac_FGC2024_Logo_CMYK_White.png";
import GreatPlac from "../../assets/img/accolades/newimage12.png";

// Array of images, texts, and links
const items = [
  {
    image: dataScientist,
    heading:  "INDUSTRY RECOGNITIONS", 
    text: (
      <>
       Ranked 31st among Asia-Pacific’s  Top <br/><span style={{ fontWeight: 700 }}> 500 High-Growth Companies</span> by <br/> Financial Times and Statista
      </>
    ),
    link: "https://blog.aaysanalytics.com/post/aays-high-growth-companies-asia-pacific-2024",
  },
  {
    image: dataEngineeringImg,
    heading:  "INDUSTRY RECOGNITIONS",
    text: (
      <>
     Positioned as a Leader in the Penetration <br/>and Maturity Quadrant for <span style={{ fontWeight: 700 }}>Top  Data  <br/> Engineering Service Providers 2024 </span> 
      </>
    ),
    link: "https://blog.aaysanalytics.com/post/aim-ranks-aays-a-leader-in-data-engineering-service-providers",
  },
  {
    image: GreatPlac,
    heading:  "INDUSTRY RECOGNITIONS",
    text: (
      <>
       Recognized as a Premier Data Science <br/>Employer with <span style={{ fontWeight: 700 }}>Great Place to  Work  <br/> Certification</span> and Ranking among the <br/> Top  50 Firms for Data Scientists 

       </>
    ),
    link: "https://blog.aaysanalytics.com/post/aays-great-place-to-work-certified",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ModalPopup = ({ setShowPopup, showPopup }) => {
  return (
    <Dialog
      open={showPopup}
      onClose={() => setShowPopup(false)}
      aria-labelledby="popup-dialog"
      PaperProps={{
        style: {
          width: "715px",
          maxWidth: "700px",
          height: "344px",
         // padding: "370px,350",
          paddingTop: "22px",
          backgroundColor: "#181818",
        },
      }}
    >
      <div className="main">
        {/* Popup Content */}
        <DialogContent>
          <div className="popup-box">
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={true} // Hide default arrows
              showDots={true} // Display dots for navigation
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
             
            >
              {items.map((item, index) => (
                <div className="mainsheadingss" key={index}>
                  <h2 className="headinmain">{item.heading}</h2>
                <div className="popup-content" >
                  {/* Left side: Image */}
                  <div className="popup-image">
                    <img
                      src={item.image}
                      alt="AIM"
                      style={{  height: "161px", width:"229px" ,objectFit:'contain'  }}
                    />
                  </div>

                  {/* Right side: Text and Link */}
                  <div className="popup-text">
                    <p>{item.text}</p>
                    <div className="button-div">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button
                          style={{
                            background: "#bb1ccc",
                            color: "white",
                            marginTop: "16px",
                           // borderRadius: "19px",
                            padding: "5px",
                            fontSize:'14px',
                            border: "none",
                            outline: "none",
                            fontFamily:'Montserrat',
                            fontWeight:'bold',
                          }}
                        >
                          Read More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                </div>
              ))}
            </Carousel>
          </div>
        </DialogContent>

        {/* Close Icon */}
        <div className="close-icon">
          <IconButton
            className="iconButton"
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
             
            }}
            onClick={() => setShowPopup(false)}
          >
            <Close
              sx={{
                color: "white",
                fontSize: "20px",
              }}
            />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalPopup;
