import React from "react";
import ayslogo from  "../assets/img/Billionimages/ayslogo.png";
import insta from "../assets/img/Billionimages/insta.png";
import linkedin from "../assets/img/Billionimages/linkedin.png";
import fsd from "../assets/img/Billionimages/fsd.png";
import foot from "../assets/img/Billionimages/foot.webp";
import greatimage from "../assets/img/Billionimages/greatimage.png";



import "./Footer.scss";






const Footer = () => {
  const currentYear = new Date().getFullYear();


  return (
    <>
   <div className='mainssss'>
   
   <div className='footer-section'>
    <div className='logoss'>
      <a href="/" target="_self" rel="noopener noreferrer">
    <img  loading="lazy" src={ayslogo}  alt='AAYS' />
    </a>

    </div>
    <div>
    <p className="innovation">  Powered by Innovation, Driven by People
        </p>
    </div>
    <div className='first-imagesss' >
    
    <img src= {foot} alt="high growth companies" className="high-growth"  loading="lazy"  />
    
      
       
          
       
        

      </div>
      <div className="second-imgs" >
      <img src={fsd} alt="best firm of data science "  className="best-firmss"  loading="lazy" />
      <img src={greatimage} alt="Great Place to work" className="great-placess"  loading="lazy" />
      </div>
   </div>
   
   <div className='footer-section'>
   <p className='footer-details'> FUNCTIONS</p>
          <ul className="list-no-style" >
          <li className='cursor' ><a href="/supply-chain-analytics">Supply Chain & Procurement </a></li>
          <li className='cursor cur'><a href="/ai-and-finance-analytics">Commercial &  Finance</a> </li>
            </ul>  
           
   </div>
   <div className='footer-section'>
    <p className='footer-details'>INDUSTRIES</p>
  
          <ul  className="list-no-style"  >
            <li className='cursor'><a href="/ai-solutions-cpg">Consumer Packaged Goods</a></li>
            <li className='cursor'><a href="/ai-solutions-for-food-and-beverage-companies">Foods & Beverage</a></li>
            <li className='cursor'><a href="/ai-solutions-retail">Retail</a></li>
            <li className='cursor'><a href="/ai-and-analytics-in-manufacturing">Manufacturing</a></li>
            <li className='cursor cur'><a href="/ai-and-analytics-in-automotive-industry">Automotive</a></li>
          </ul>
         
   </div>
   <div className='footer-section'>
   <p className='footer-details'> CAPABILITIES</p>
            <ul className="list-no-style"  >
            <li className='cursor' style={{marginTop:'5px'}}><a href="/ai-and-data-engineering">Data Engineering  </a></li>
            <li className='cursor'><a href="/ai-innovations-enterprise-solutions">AI Innovation  </a></li>
            </ul>
   </div>
   <div className='footer-section'>
   <p className='footer-details'  >APPLICATIONS</p>
   <ul  className="list-no-style">
            <li className='cursor'  ><a href="/aays-decision-intelligence-aadi">Aays Decision Intelligence (AaDi)</a></li>
            </ul>
            <div className='links' >
              <p className='resour'>RESOURCES</p>
            <ul className="list-no-style"  >
            
            <li className='cursor' ><a href="/Blog">Blogs </a></li>
            <li className='cursor' ><a href="/customer-success-stories">Customer Success Stories </a></li>
            <li  className='cursor'><a href="/career">Career </a></li>
            <li className='cursor'><a href="/contact">Contact us </a> </li>
            </ul>
            </div>
            <p className='folow'>FOLLOW US </p>
            <div className='insta'>
            <a href="https://www.instagram.com/lifeataays/" target="_blank" rel="noopener noreferrer">
          <img src={insta} alt="linkedin" className="insta-iconss cursor"  />
          </a>
          <a href="https://www.linkedin.com/company/aays-analytics/" target="_blank" rel="noopener noreferrer">
          <img src={linkedin} alt="linkedin" className=' link-iconss cursor ' />
          </a>
            </div>
    
   </div>


 
   </div>
   <div className="copyright" >
    Copyright &copy; Aays {currentYear} | All Right Reserved 
    <p>ISO 27001:2022 Certified</p>
   <p className="policys"><a href="/privacyPolicy">Privacy Policy</a></p>
   </div>
   </>
  
   
  )
}
 

export default Footer;




