import dashboard from "../../assets/img/Aadi/firstScreenGif.gif";
import videoRecordGif from "../../assets/img/Aadi/screenRecord.gif";
import mobileGif from "../../assets/img/Aadi/mobile-section3-gif.gif"
import AccordionFile from "./AccordionFile";
import { firstAccordion } from "./AccordionData";
import { useMediaQuery, useTheme } from "@mui/material/";


const Section3 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <div id="section3" className="aadi-section3 scroll-section ">
        <div className="main">
          <h1 className="heading">Q&A Interpreter</h1>
          <div className="text">
            Ability to understand and contextualise questions in line with
            enterprise defined framework and generate response based on analyst
            mind-graph techniques{" "}
          </div>
          <div className="flex-container">
            <div className="left">
              <div className="accordian-file">
                <AccordionFile data={firstAccordion} />
              </div>
            </div>
            <div className="right">
              {isMobile ? (
                <img src={mobileGif} alt="Aays_Finance Co-Pilot" />
              ) : (
                <img src={dashboard} alt="Aays_Finance Co-Pilot" />
              )}
              {/* <img src={videoRecordGif} alt="Aays_Finance Co-Pilot" /> */}
              {/* <video autoPlay muted playsInline className="custom-video">
                <source
                  src="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/Video/Screen-Recording.mov"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section3;
