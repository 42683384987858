import React from 'react'
import Blogdatascientists from "../../assets/img/Blogdatascientists.webp"

const JoinScientist = () => {
  return (
    <div className='main-mainss scroll-section'  id="section6a-customer-success-story">
    <div className='market'>Why Join Aays as a Data Scientist? </div>
    <p className='clasname'>Join one of the 50 Best Firms for Data Science professionals, where innovation, growth, and impactful work come together to shape your career. 
</p>
<div className="applicatio">
  <div className="images-images">
    <img src={Blogdatascientists} alt="Working Capital Analytics" className="imging" />
  </div>
  <div className="textss">
    <div className="heades">Aays Recognized as One of the 50 Best Firms for Data Scientists to Work for in 2024 </div>
    <div className="header-para">
    <span className="header-text">
    Aays ranked 13th in AIM's 50 Best Firms for Data Scientists 2024, recognized for fostering growth, engagement, and supportive work culture.<br></br></span>
      <a href="https://blog.aaysanalytics.com/post/50-best-firms-for-data-scientists-2024" target="_blank" rel="noopener noreferrer">
      <button className='btns-btn'>Read More</button>
      </a>
      
    </div>
    
  </div>

 
</div>
</div>
  )
}

export default JoinScientist