import React,{useEffect} from "react";
// import Section1 from "../pageLayout/CustomerSuccessStoryMoreLayout/Section1";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/primeCost-caseStudy.png"
import { Helmet } from "react-helmet";
import { SchemaPrimeCost } from "../BlogSchema";

const storyData = {
  title:
    "Driving strategic initiatives through enhanced prime cost visibility: Success story of a global CPG company",
  content:
    "Prime cost is an important element to track and monitor for FMCG companies as it contributes to c.30%-40% of NSV. The enhanced visibility and control over raw material and packaging spends, coupled with actionable insights and improved process alignment, enabled one of the largest CPG companies to make informed pricing decisions on finished goods. This also allowed them to optimise raw material spending and improve profitability.",
  subTitle1: "3%",
  subText1: "optimisation in prime spending v/s last year",
  subTitle2: "$5 Mn",
  subText2: "of productivity improvement",
  imageData: picture
};

function Prime_Cost() {

   useEffect(() => {
     document.title =
       "Enhanced Prime Cost Visibility: CPG Success Story | Aays";
     document.getElementsByTagName("META")[3].content =
       "Explore how our prime cost visibility empowered a global CPG company to make informed pricing decisions, optimize raw material spending, and boost profitability. ";
   });

  return (
    <div className="backround-black">
      {/* <Helmet>
        <meta
          name="keywords"
          content="Prime cost visibility, NSV contribution, pricing decisions, profitability improvement."
        />
      </Helmet> */}
      <Helmet>
     <script type="application/ld+json">
        {JSON.stringify( SchemaPrimeCost)}
       </script>
         </Helmet>

      <div>
        <Indicusstomersuccessstories data={storyData} />
      </div>

      {/* <Resources /> */}
      <Footer />
    </div>
  );
}

export default Prime_Cost;
