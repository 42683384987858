import React,{useEffect} from "react";
// import Section1 from "../pageLayout/CustomerSuccessStoryMoreLayout/Section1";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/marketing-caseStudy.jpg"
import { Helmet } from "react-helmet";

const storyData = {
  title:
    "Optimizing marketing mix strategies for a leading snack foods manufacturer",
  content:
    "A fast-growing snack foods manufacturer sought to understand the complex interplay of marketing activities and their impact on sales. Market Mix Modeling was employed to analyze revenue generation, including promotions, distribution, competition, advertising, sponsorships, seasonality etc. This approach uncovered $10 million in budget optimization, a 5% sales boost, and the potential for a 15% ROI increase for their $130 million annual marketing investments.",
  subTitle1: "$10 million",
  subText1: "marketing budget optimization",
  subTitle2: "15%",
  subText2: "improvement in ROI",
  imageData: picture
};

function Market_Mix_Modeling() {

   useEffect(() => {
     document.title =
       "Marketing Mix Strategy Optimization | Snack Foods Manufacturer Case Study ";
     document.getElementsByTagName("META")[3].content =
       "A leading snack foods manufacturer optimized marketing mix strategies. Explore the impact of Market Mix Modeling on revenue generation and marketing investments. ";
   });

  return (
    <div className="backround-black">
      <Helmet>
        <meta
          name="keywords"
          content="Marketing mix strategies, snack foods manufacturer case study, Market Mix Modeling"
        />
      </Helmet> 
     

      <div>
        <Indicusstomersuccessstories data={storyData} />
      </div>

      {/* <Resources /> */}
      <Footer />
    </div>
  );
}

export default Market_Mix_Modeling;
