import Carousel from "react-multi-carousel";
import EmployeeFeedback from "../../pageLayout/CareerPageLayout/EmployeeFeedback"
import Kavitha from "../../assets/img/Kavitha.jpg";
import Gurleen from "../../assets/img/Gurleen.webp";
import Ajith11 from "../../assets/img/Ajith11.jpg";
import designElement from "../../assets/img/careerPageDesignElement.webp";

const Data = [
    {
      id: 1,
      name: " Kavitha Viswanathan ",
      designation: "Principal AI Engineer ",
      image:  Kavitha,
      content:
        " Most companies hire people for their skills, but at Aays, it’s about continuously building and expanding those skills. That’s what sets Aays apart. We have a growth-focused culture with plenty of opportunities to explore new career paths, learn, earn promotions, and more—which is why our attrition rate is so low. Leadership’s humility and mentorship keep us motivated, and the flexible work culture supports a healthy work-life balance. Even with our fast-paced growth and tight deadlines, Aays respects our time off. It’s truly a place where you can grow and thrive",
    },
    {
      id: 2,
      name: "Ajith Sasidharan ",
      designation: "Principal Data Architect ",
      image: Ajith11,
      content:
      "Our data science team works with cutting-edge AI and ML technologies, empowering them to push the boundaries of what’s possible. What excites me the most is how we seamlessly align these advanced tools with the business's needs, driving the best outcomes for our clients. It’s all about using the latest tech to enable smarter, more efficient business solutions, and that’s what makes our work so impactful. "
        },
    {
        id: 3,
        name: "Gurleen Kaur ",
        designation: "Associate Data Analyst ",
        image: Gurleen,
        content:
          "Working at Aays has been an incredibly positive experience. I’ve had the chance to take on exciting, challenging projects that push me to grow and learn. What really stands out is the strong sense of teamwork and support - whenever you are facing a challenge or pursuing a new idea, you know you have a team that’s ready to help. It’s a place where both personal and professional growth are truly valued, making it easy to stay motivated and continue developing in your career. ",
      },
  ];
  
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  
  function TeamSpeak({ isMobile = false }) {
    return (
      <div className="career-section4-new " style={{backgroundColor:'black'}}>
        <div className="section4-main" style={{backgroundColor:"#181818"}}>
          <div className="item1">
            <div className="center">
              <h1 className="heading">Our Team Speaks </h1>
              <p>Get an inside look into Aays through the words of our team.</p>
            </div>
          </div>
          <div className="item2 twoitem">
            <div className="carousel">
              <Carousel
                className="customer-success-main"
                responsive={responsive}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                arrows={false}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                infinite={true}
                slidesToSlide={1}
                swipeable={false}
                draggable={false}
                showDots={!isMobile}
              >
                {Data.map((data) => (
                  <EmployeeFeedback
                    content={data.content}
                    image={data.image}
                    name={data.name}
                    designation={data.designation}
                    key={data.id}
                  />
                ))}
              </Carousel>
            </div>
          </div>
          <div className="background-design">
            <img loading="lazy" src={designElement} alt="Background-img" />
          </div>
        </div>
      </div>
    );
  }
  
  export default TeamSpeak;
  