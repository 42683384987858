import React, { useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import ai2 from "../../assets/img/Gen-AI-Investment.webp";
import ai2as from "../../assets/img/AI-ML-for-decision.webp";
import EUAIAct from "../../assets/img/EUAIAct.webp";
import AipwrdmdrnMarket from "../../assets/img/AipwrdmdrnMarket.png";
import AIAct from "../../assets/img/AIAct.webp"
import LglnguegeModel from "../../assets/img/LglnguegeModel.png"
import Drivingautomatic from "../../assets/img/Driving-Automatic.webp";
import AiandGenai from "../../assets/img/AiandGenai.png"
import { useMediaQuery, useTheme } from "@mui/material/";
import RightArrow from "../CustomerSuccessStoryLayout/RightArrow";
import LeftArrow from "../CustomerSuccessStoryLayout/LeftArrow";
import Futureai from "../../assets/img/FutureAI-driven.png"

const items = [
  {
    id: 1,
    content:
      "The Future of AI-Powered Decision Intelligence: Insights from Industry Leaders",
    class: "content-box-lifeaa",
    image: Futureai,
    date: "Feb 17 ,2025",
    read :'2 min read',
    url: "/blogs/ai-powered-decision-intelligence",
  },
  {
    id: 2,
    content:
      "Techniques for Ensuring Responsible and Efficient Use of Large Language Models (LLMs)",
    class: "content-box-lifeaa",
    image: LglnguegeModel,
    date: "Nov 29 ,2024",
    read :'2 min read',
    url: "https://blog.aaysanalytics.com/post/enhancing-techniques-for-effective-governance-of-llms",
  },
  {
    id: 3,
    content:
    "EU AI Act and Its Far-Reaching Implications for Businesses and Innovation",
  class: "content-box-lifeaa",
  image: AIAct,
  date: "Oct 4 ,2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/eu-ai-act",
  },
  {
    id: 4,
    content:
    "The Role of AI-Driven Personalization in Modern Marketing",
  class: "content-box-lifeaa",
  image: AipwrdmdrnMarket,
  date: "September 24 ,2024",
  read :'2 min read',
    url: "https://blog.aaysanalytics.com/post/ai-driven-personalization-in-marketing",
  },
  {
    id: 5,
    content:
    "Leveraging AI and ML for Business Decision-Making?",
  class: "content-box-lifeaa",
  image:ai2as,
  date: "September 4, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/leveraging-ai-and-ml-for-business-decision-making",
  },
  {
    id: 6,
    content:
    "Prioritizing AI and GenAI Use Cases: A Strategic Quadrant Framework for Enterprise Adoption",
  class: "content-box-lifeaa",
  image: AiandGenai,
  date: "Jul 1, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/prioritizing-ai-and-gen-ai-use-cases",
  },
  {
    id: 7,
    content:
    "Why Invest in Generative AI Now? Exploring AI Investment Strategies",
  class: "content-box-lifeaa",
  image:ai2,
  date: "Jul 8, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/why-invest-in-gen-ai-now",
  },
  {
    id: 8,
    content:
    "How is big data and automotive analytics are transforming the automotive industry?",
  class: "content-box-lifeaa",
  image:Drivingautomatic,
  date: "Feb 16, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/how-is-big-data-and-analytics-helping-the-automotive-industry",
  },
]


const AiAndGenerativeAI = () => {
  const [startIndex, setStartIndex] = useState(0);

  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const showLength = isIpad ? 2 : 3;
  const visibleItems = isIpad
    ? items.slice(startIndex, startIndex + 2)
    : items.slice(startIndex, startIndex + 3);
  

  return (
    <div  id="aiAndGeneratives" className="customer-success-section3 content-box-lifeaays section scroll-section">      {/* customer-success-section3 */}
      <div className="customer-section3-body">
        <div className="success-story-page-section-3-Header-text">
        {/* id="caseStudySection3"  */}
        AI and Generative AI

        </div>
       
        <div className="success-story-page-section-3-grid">
          {items.length > showLength && (
            <LeftArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
          {visibleItems.map((item) => (
            <div key={item.id}>
              <CustomerSectionBox
                picture={item.image}
                date={item.date}
                read={item.read}
                title1={item.title1}
                title2={item.title2}
                content={item?.content}
                class={item.class}
                redirectURL={item.url}
              />
            </div>
          ))}
          {items.length > showLength && (
            <RightArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default AiAndGenerativeAI;
