import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiBanner from "../../assets/img/ind_blogs_Architectural_pattern.jpg";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import Blgfvcn from "../../assets/img/Blgfvcn.png";
import SocialShare from "./newBLog/SocialShare";


function BusinessAnalyticsArchitecture() {
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };
  

  useEffect(() => {

    document.title = "Business Analytics Architecture";
    document.getElementsByTagName("META")[3].content =
      "Setting up your Business Analytics Architecture in Azure Cloud? Create proper infrastructure for storing the data available in your organization. ";
  })
  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];

  return (
    <div id="homePage">
      <Helmet>
        <meta name="keywords" content="data architecture" />
      </Helmet>

      <Navbar bg="black" />
      <GoogleAnalytics />

      <div className="GenAi-body newclas">
        <div className="margin-blogMore">
          <div className="GenAi-main">
            <div className="border-genai">
              <div className="new-box-seventy">
                <div className="back-button" style={{ paddingTop: "2rem" }}>
                  {data.map((ele, index) => (
                    <div className="back" key={index}>
                      <a href={ele.url} className="content" key={index} style={{ color: 'black' }}>
                        {ele.text}
                      </a>
                      {index !== data.length - 1 && (
                        <div style={{ color: "black" }}>/</div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flexteamaays" style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                  <div>
                    <img src={Blgfvcn} alt="aays" className="imagi" />
                  </div>
                  <div style={{ fontWeight: '500' }}>
                    <span className="cntheading" style={{ marginRight: '6px' }}>Team Aays</span>
                    <span className="cntheading sixjan" style={{ marginRight: '6px' }} >.</span>
                    <span className="cntheading sixjan" >Dec 06</span>

                  </div>
                </div>
                <h1 className="main-heading fontweight mainheaders">
                  {" "}
                  Architectural Patterns for Analytics Journey Leveraging Azure Cloud Tech-Stack
                </h1>

                <img
                  className="blogImg"
                  // style={{ filter: "grayscale(1)" }}
                  src={GenAiBanner}
                  alt="blog1"
                />

                <div className="imgContent">
                  <p className="heading-content cntheading">


                    We are excited to embark on this renewed journey with Microsoft, as we continue to lead the way in excellence and innovation.


                  </p>




                  <h2 className="heading headings-headingss">Importance of Data Infrastructure in an Organization



                  </h2>



                  <p className="heading-content cntheading">
                    In today’s world any organization which is not utilizing its data for gaining insights will fall behind its competitors. Data has become an important part of the working of an organization be it for monitoring, analyzing, or predicting requirements.
                  </p>
                  <p className="heading-content cntheading">
                    Every organization has a certain type of data requirement. Some require historical data; some require periodic data and others require live data. To ensure your current and future data requirements are met it is advisable to create proper infrastructure for storing the data available in the organization.
                  </p>

                  <p className="heading-content cntheading">
                    The stages which an organization goes through can be broadly classified as:


                  </p>
                  <ul>
                    <li className="liclass">  Low-Tier (Very low data volumes/PoC).

                    </li>
                    <li className="liclass"> Mid-Tier (Small to medium data volume).



                    </li>
                    <li className="liclass">  Advanced-Tier (Large data volume with data science requirements).





                    </li>             </ul>


                  <h3  className=" heading heading-heading fontweight hadingthree">
                    Cloud Technologies for Corporate Analytics Implementation


                  </h3>
                  <p className="heading-content cntheading">
                    Before discussing the technologies available for each of the above classifications, let us have a quick overview of cloud technologies.


                  </p>
                  <p className="heading-content cntheading">
                    Backend cloud technologies are technologies available in the cloud for storing and processing data in the cloud. Just like a basic computer system, computation in cloud also needs a CPU, a RAM and Storage. In cloud we have this advantage where we can scale-up or scale-down or even shut down the resources as per our needs.
                  </p>
                  <p className="heading-content cntheading">
                    Different clouds have different technologies which can be used for processing data. Technologies differ depending upon the cloud used, reporting needs, frequency of reporting, pre-aggregation on the dataset, etc.
                  </p>

                  <p className="heading-content cntheading">
                    Though all the clouds have technologies to handle all kinds and volumes of data, we will be looking into the Azure Cloud for our discussion.


                  </p>
                  <h3  className=" heading heading-heading fontweight hadingthree">
                    Analytic architectures available in the Azure Cloud


                  </h3>
                  <p className="heading-content cntheading">
                    Now that we know what backend technologies are, let us discuss some of the options available when setting up your Business Analytics Architecture in Azure Cloud depending on the volume of data available.
                  </p>




                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>       1)
                   Low-Tier:</b> Low-tier is for exceedingly small organizations with data volumes in a few MBs. These technologies can be used for Proof-of-Concept analytics in larger organizations. These technologies are very cost effective, and the turnaround time is very small.                  </p>

                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>       a)
                     Microsoft Excel:</b> The easiest option for getting your dashboards is to use Microsoft excel/csv files as an input to Power BI. The files can be stored in a Data Lake or an FTP server and can be refreshed by adding new data to the location. This option is suggested only when the data volume is low, and the computation does not require complicated measures/columns. Once the data volume increases, this architecture cannot sustain the increased load as it cannot be scaled up. This architecture is the easiest to develop as well because it does not require expertise in complicated backend technologies.                  </p>


                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>      2)
                     Mid-Tier:</b> Mid-tier technologies work best when data volumes are from 10 to 15 GBs. They are comparatively inexpensive, and the development costs are moderate.                  </p>

                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>  a)
                    Azure Analysis Services/SQL Server Analysis Services (SSAS):
</b>


                  </p>
                  <ul>
                    <li className="liclass">
                      SSAS is the traditional service provided by Microsoft for adding computation capacity to Power BI reports. This service has the advantage that all the calculated columns and measures could be moved from Power BI to an SSAS model. The server created to support the backend can store multiple models and can be scaled up or down. This is the “go-to” option for adding computation capacity for Power BI Reports .

                    </li>
                    <li className="liclass">
                      Traditional SSAS server can be scaled up from one tier to another and it does not automatically scale up/down depending on the requirements. To address this, Microsoft has launched the Azure Analysis Service which gives more control over the server size and hence optimizes cost of operation depending upon the load.



                    </li>
                    <li className="liclass">
                      Once the model is created, we can connect it with a direct query to Power BI report. Power BI report created using direct query does not store any report on the Power BI server. All the data is computed in the backend server and then displayed in the Power BI report. If we create an import connection in Power BI, then all the advantages of getting the SSAS/Azure AS server would be gone.





                    </li>             </ul>



                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>     b)
                    SQL Server:         </b>         </p>
                  <ul>
                    <li className="liclass">

                      SQL Servers are the most used databases when it comes to storing corporate data. For starting your journey in BI, these can be the steppingstones for creating dashboards directly from the SQL databases. Power BI supports both direct and import query modes when connecting with SQL servers. Moreover, Power BI also gives an option of writing SQL queries and sending them to SQL server. This saves the need for creating views/stored procedures in SQL.
                    </li>
                    <li className="liclass">

                      This works well because complicated queries can be stored as tables in SQL Server and then used directly for reporting. Also, data can be aggregated for visualization in the backend, reducing the computation required in Power BI.
                    </li>
                  </ul>

                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>    Azure Function with Storage Account:</b>

                  </p>
                  <ul>
                    <li className="liclass">

                      All the technologies we have seen above support an elevated level of transformation and aggregation. In case your requirement is to run complicated transformations or machine learning models, then you would need the flexibility of a programming language for your analysis.


                    </li>
                    <li className="liclass">
                      Azure Function becomes the answer to your needs in this case as it gives a variety of languages to code your requirements. As an Azure Function has no built-in memory, the output of the analysis is stored in a Storage Account (ADLS) available in Azure. The Power BI dashboard can then be created using the processed data from Storage account. We can also use an SQL server in this case if the data volume is large.
                    </li>
                    <li className="liclass">
                      Azure function supports programming languages like Node.js, python, PowerShell, .NET, and Java.



                    </li>
                  </ul>



                  <p className="heading-content cntheading">
                    After all the processing is completed, it is a best practice to do reconciliation of data with the source using different metrics because as a techie we might often miss some business logic especially if we are dealing with calculated fields. Once we have our golden data (verified with source systems) we can start creating pipelines to load our data into the server database.                </p>



                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}> 3)
                    Advanced-Tier:</b> Advanced-Tier technologies are for very large data volumes and AI/ML requirements using large datasets up to 100GBs. These technologies are expensive, highly scalable, and immensely powerful. They can even be used for live streaming data as well.

                  </p>





                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>      a)
                    Power BI Premium</b>: Power BI Premium is the latest cloud-based technology which can be used for giving more computation power for handling large datasets in Power BI Service. Power BI Premium costs more compared to SSAS server but there is no extra development cost when it comes to moving the model to SSAS server. It uses dedicated Power BI embedded capacity for storage and computing. An embedded capacity can be shared by multiple workspaces.</p>

                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>        b)
                    SQL Server Hyperscale Tier</b>: In case the General Purpose/Standard or Premium tier is insufficient for your data needs, Microsoft has come up with a hyper-scale tier that is based on the vCore purchasing model. This enables your database to scale up to 100TB. It also gives an option to add more computing capacity depending on the processing requirements. The option for scaling up or down is available and optimizes the cost according to your computing requirements.</p>

                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>          c)
                    Databricks with Storage Account:</b>
                  </p>
                  <ul>
                    <li className="liclass">
                      Databricks is a managed PaaS (platform as a service) based on Apache Spark. It is available on major cloud platforms. Databricks supportsparallel processing . It is very well integrated with Azure Storage Accounts which enables it to process a large dataset very quickly and efficiently. It is user-friendly as it allows users to code in SparkSQL, Scala, PySpark and R. These are the major languages used by data engineers/data analysts all over the world. It also supports machine learning and artificial intelligence libraries which makes the data science part easy to implement and productionize.

                    </li>
                    <li className="liclass">

                      Databricks uses Jupyter like notebooks which enables multiple users to collaborate. It has Git/DevOps integration, enabling users to move back to a previous version of the notebook.

                    </li>
                    <li className="liclass">
                      As Databricks does not have any storage in its servers, data is stored in azure storage accounts after processing. Databricks clusters can be switched-on using Azure Data Factory and shutdown automatically if not in use. This makes it a very cost-effective solution. Also, the same cluster can be used by multiple notebooks.

                    </li>
                    <li className="liclass">


                      Once the data is processed and saved in storage account, it can be accessed by Power BI directly or through an SQL Server.
                    </li>

                  </ul>

                  <p className="heading-content cntheading">
                  <b style={{ color: 'black' }}>     d)
                    Azure Synapse with Storage Account </b>:
                  </p>
                  <ul>
                    <li className="liclass">Azure Synapse is the proprietary Data analytics, Machine Learning and Artificial Intelligence platform developed by Microsoft. It supports serverless SQL DW pooland Apache spark pool for data warehousing, code free data flow for big data transformation, data integration & orchestration to integrate and operationalize your data and a web-based studio to access all these capabilities.</li>
                    <li className="liclass">As it supports a serverless architecture, the cost can be optimized to a minimum. It also supports a variety of coding languages like SQL when using SQL DW, python, Scala, PySpark , C# and SparkSQL when using Apache spark pool.


                    </li>

                    <li className="liclass">This architecture can use the SQL pool for storing all the data that is generated after processing raw data. It is priced higher than all the technologies discussed above but gives a complete package for your analytic needs.


                    </li>

                  </ul>


                  <h2 className="heading headings-headingss">Conclusion




                  </h2>
                  <p className="heading-content cntheading">
                    The technologies discussed above are just a few examples of starting your Analytics journey in the Cloud. The technologies we have discussed are more inclined towards big data. There are other technologies used for analyzing streaming data like Kafka, event hub, etc. which will be discussed in subsequent articles.
                  </p>

                  <p className="heading-content cntheading">
                    Overall, designing a sustainable and scalable architecture is the goal when starting your BI journey as each of the technologies has their pros and cons and a cost attached to it. There are no standard rules when designing architectures for Analytics. Hence, it is advisable to get help from a cloud architect when starting your journey as it will ensure that you have less trouble when addressing your future needs.

                  </p>

        <SocialShare />




                </div>
              </div>
            </div>
          </div>
        </div>










        <div>

        </div>

      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default BusinessAnalyticsArchitecture;
