import React from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import  DvshPal from "../../assets/img/DvshPal.png";
import RhulDesai from "../../assets/img/RhulDesai.webp"; 
import AkshyKumr from "../../assets/img/AkshyKumr.webp";
import DepkPrjapti from "../../assets/img/DepkPrjapti.png"

const responsive = {
   desktop: {
     breakpoint: { max: 3000, min: 1345 },
     items: 4,
   },
  superdesktop: {
    breakpoint: { max: 1345, min: 1000 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1000, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};


function   Meetexperts() {
  return (
    <div
      id="meet"
      className="blog-success-section2 scroll-section "
    >
      <div className="customer-section2-body margin-box">
        <div className="success-story-page-section-2-Header-text">
        Get to Know Our Experts 
        </div>
        <div className="customer-section2-body">
          <div className="section2-box">
           

            <Carousel
              responsive={responsive}
               dotListClass="custom-dot-list-style"
              arrows={false}
              autoPlay={false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
            >
               
              
               <CustomerSectionBox
                picture={DvshPal}
                title1="Devesh Pal's Journey in Data Engineering at Aays"
                date="Oct 16, 2024"
                class="content-box-section2a"
                read=" 2 min read"
                redirectURL="https://blog.aaysanalytics.com/post/data-engineering-at-aays-devesh-pal-interview"
              />
          <CustomerSectionBox
                picture={AkshyKumr}
                title1="Balancing Innovation and Education: Akshay Kumar's Data Engineering Journey at Aays"
                date="Dec 10, 2024"
                class="content-box-section2a"
                read=" 2 min read"
                redirectURL="https://blog.aaysanalytics.com/post/akshay-data-engineering-journey-aays"
              /> 

               <CustomerSectionBox
                picture={RhulDesai}
                title1="Data Engineering Career Journey: Rahul Desai on Growth, Challenges, and Teamwork"
                date="Nov 25, 2024 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/rahul-desai-data-engineering-career-journey"
              />
               <CustomerSectionBox
                picture={DepkPrjapti}
                title1="Deepak's Data Engineering Career: Insights, Challenges, and Advice for Aspiring Professionals"
                date="Nov 21, 2024 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/deepak-data-engineering-career-insights"
              />
              
              
            </Carousel>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default  Meetexperts;
