import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/accountReceivable-caseStudy.png"
import { SchemaAccountreciveable } from "../BlogSchema";

const storyData = {
  title:
    "How a global CPG conglomerate strengthened its accounts receivable function by leveraging advanced analytics",
  content:
    "The global consumer goods company was helped by Aays to improve its accounts receivable for increasing financial operational efficiency. A comprehensive solution was developed by us in collaboration with business stakeholders, which resulted in a reduction of DSO by 15 days, positive working capital of around $50 million, and enhanced financial operational efficiency.",
  subTitle1: "15 days",
  subText1: "time by which the customer reduced its DSO",
  subTitle2: "$50 million",
  subText2: "positive working capital generated",
  imageData: picture
};

function Account_Receivable() {

  useEffect(() => {
    document.title = "Strengthened Accounts Receivable for a Global CPG";
    document.getElementsByTagName("META")[3].content="Learn how Aays improved accounts receivable for a global CPG, reducing DSO by 15 days, boosting working capital, and enhancing financial efficiency.";
  });
    
  return (
    <div className="backround-black">
      <Helmet>
        <meta
          name="keywords"
          content="Accounts receivable, financial operational efficiency, DSO reduction"
        />
        <script type="application/ld+json">
        {JSON.stringify(SchemaAccountreciveable)}
         </script>
      </Helmet>
      
      <div>
        <Indicusstomersuccessstories data = {storyData} />
      </div>

      {/* <Resources/> */}
      <Footer />

    </div>
  );
}

export default Account_Receivable;
