import React, {useEffect} from "react";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/supplyChain-caseStudy.png"
import { Helmet } from "react-helmet";
import { Schemasupplycustomer } from "../BlogSchema";


const storyData = {
  title:
    "How a global CPG conglomerate strengthened its supply chain with optimised supply planning and improved AI-enabled demand forecasting",
  content:
    "Aays conceptualised and implemented supply chain visibility tool on a large and complex supply chain data of a multi-billion-dollar consumer products company in the APAC region spanning five distinct countries and fifteen distinct manufacturing locations, warehouses, and factories. The solution helped the company realise over $300 Mn of business impact.",
  subTitle1: "$220 Mn",
  subText1: "worth of stock outages identified, prioritizing demand across 450 items",
  subTitle2: "$11 Mn",
  subText2: "worth of expiring stock, identified prioritizing sales/ marketdown strategies",
  imageData: picture
};

function Supply_Chain() {

  useEffect(() => {
    document.title =
      "Optimized Supply Chain & AI-Enabled Demand Forecasting | Aays ";
     document.getElementsByTagName("META")[3].content =
       "Global CPG boosted its supply chain with optimized planning and AI-driven forecasting. Supply chain visibility tool implemented across diverse locations.   ";
  })


  return (
    <div className="backround-black">
      <Helmet>
       <script type="application/ld+json">
        {JSON.stringify(Schemasupplycustomer)}
       </script>
         </Helmet>
      <div>
        <Indicusstomersuccessstories data = {storyData} />
      </div>

      {/* <Resources/> */}
      <Footer />

    </div>
  );
}

export default Supply_Chain;
