import React, { useEffect } from "react";
// import Section1 from "../pageLayout/CustomerSuccessStoryMoreLayout/Section1";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/vendor-risk-management-indi-case-studies.png"
import { Helmet } from "react-helmet";
import { SchemaVendorRiskManagement } from "../BlogSchema";


const storyData = {
  title:
    "Managing vendor risks in a complex supply chain: Case study of a global consumer goods manufacturer",
  content:
    "A multi-billion-dollar global consumer goods company with a complex supply chain sought a solution for managing vendor risks efficiently. The implementation resulted in enhanced visibility into vendor risks. With comprehensive vendor risk profiles readily available, the customer could proactively manage risks, prevent disruptions, and maintain business continuity. They experienced estimated cost savings of $2 million, attributed to prompt risk identification and mitigation, and improved productivity.",
  subTitle1: "$2Mn",
  subText1: "of productivity improvements",
  subTitle2: "40,000",
  subText2: "vendor risk profiles generated",
  imageData: picture
};

function Vendor_Risk_Management() {

  useEffect(() => {
    document.title = "Efficient Vendor Risk Management | Consumer Goods Case Study";
    const metaTags = document.getElementsByTagName("META");
    if (metaTags.length > 3) {
      metaTags[3].content = "A global CPG company efficiently managed vendor risks, finding 40,000 vendor risk profiles, ensuring proactive risk mitigation strategies and cost savings of $2Mn.";
    }
  });

  return (
    <div className="backround-black">
      <Helmet>
        <meta
          name="keywords"
          content="Vendor risk management, supply chain risks, consumer goods manufacturer, vendor risk profiles."
        />
        <script type="application/ld+json">
        {JSON.stringify(SchemaVendorRiskManagement)}
       </script>
      </Helmet>

      <div>
        <Indicusstomersuccessstories data={storyData} />
      </div>

      {/* <Resources /> */}
      <Footer />
    </div>
  );
}

export default Vendor_Risk_Management;
