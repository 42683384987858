import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import Navbar from "../nav/NavBar";
import Section2 from "../pageLayout/DataEngineering/Section2";
import Section4 from "../pageLayout/DataEngineering/Section4";
import Section5 from "../pageLayout/DataEngineering/Section5";
import Footer from "../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import HomePageSection5 from "../pageLayout/HomePageLayout/HomePageSection5";
import CustomerSuccessSection from "../pageLayout/AboutPageLayout/Section5";
import { CustomerSuccessStoriesData } from "../constant/CustomerSuccessStoriesData";
import { Helmet } from "react-helmet";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import mobileBannerImage from "../assets/img/data-engineer-banner-right.webp";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import BottomBanner from "../reuseLayout/BottomBanner";
import { SchemaDataengineering } from "./BlogSchema";

const nextstep = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y + (70 * $(window).height()) / 100);
};

const nextstepDown = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y - (70 * $(window).height()) / 100);
};

const Dataengineering = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  useEffect(() => {
    document.title = "Aays | Data Engineering and AI Solutions";
   
  });



  return (
    <div id="homePage" className="dataEngMobile">
     
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(SchemaDataengineering)}
        </script>
      </Helmet>

      <Navbar />
      <GoogleAnalytics />

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Data Engineering Solutions"
          text2="for Enterprises"
          subtext1="Unleashing Excellence for "
          subtext2="Strategic Enterprise Success"
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Data Engineering Solutions"
          text2="for Enterprises"
          subtext1="Unleashing Excellence for "
          subtext2="Strategic Enterprise Success"
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1="Data Engineering Solutions"
          text2="for Enterprises"
          subtext1="Unleashing Excellence for "
          subtext2="Strategic Enterprise Success"
          picture={mobileBannerImage}
        />
      )}

      <MiddleBanner text="Delivered ~ 2 PB of data engineering projects in a multi-domain complex data landscape" />

      <Section2 />
      <Section4 />
      <Section5 techHeading=" Our AI Stack " secheading=" Our Tech Partners"/>

      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <CustomerSuccessSection
            data={CustomerSuccessStoriesData}
            tag="data engineering"
            show="false"
          />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="data engineering"
            show="false"
          />
        )}
      </div>
      <BottomBanner contentChange={true} />
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default Dataengineering;
