import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import blog12 from "../../assets/img/ResearchLeader.png"
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import Alert from "@mui/material/Alert";
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";


// import Section2Mobile from "../../pageLayout/CustomerSuccessStoryLayout/Section2Mobile";

import Blgfvcn from "../../assets/img/Blgfvcn.png";
import Supplychain from "../../Pdfs/Supply-chain.pdf";
import {  SchemaFutureAi } from "../BlogSchema";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Customerindian from "../../pageLayout/CustomerSuccessStoryMoreLayout/Customerindian";
import SocialShare from "./newBLog/SocialShare";





function PowerdDecison() {
 
    const isMobile = useMediaQuery("(max-width: 767px)");
    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });
  
   
    const data = [
      {
        text: "Home",
        url: "/",
      },
      {
        text: "All Blogs",
        url: "/Blog",
      },
    ];
    
  
    const nextstep = () => {
      var y = $(window).scrollTop(); //your current y position on the page
      $(window).scrollTop(y + 700);
    };
  
    const nextstepDown = () => {
      var y = $(window).scrollTop(); //your current y position on the page
      $(window).scrollTop(y - 700);
    };
   
    return (
  
      <div id="homePage" className="case-studies-pages-cls">
         <Helmet>
           <script type="application/ld+json">
                   {JSON.stringify(SchemaFutureAi)}
                 </script>
        </Helmet>
  
        <Navbar bg="black" />
        <GoogleAnalytics />
  
        <div className="GenAi-body newclas">
          <div className="margin-blogMore">
            <div className="GenAi-main">
              <div className="border-genai">
              <div className="new-box-seventy">
                <div className="back-button" style={{paddingTop:"2rem"}}>
                  {data.map((ele, index) => (
                    <div className="back" key={index}>
                      <a href={ele.url} className="content" key={index} style={{ color: 'black' }}>
                        {ele.text}
                      </a>
                      {index !== data.length - 1 && (
                        <div style={{ color: "black" }}>/</div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flexteamaays" style={{paddingBottom:'1rem',paddingTop:'1rem'}}>
                  <div>
                  <img src={ Blgfvcn} alt="aays" className="imagi" />
                  </div>
                  <div style={{fontWeight:'500'}}>
                  <span className="cntheading" style={{marginRight:'6px'}}>Team Aays</span>
                  <span className="cntheading sixjan"  style={{marginRight:'6px' }} >.</span>
                  <span className="cntheading sixjan" >Feb 17</span>
                  
                  </div>
                </div>
                <h1 className="main-heading fontweight mainheaders">
                  {" "}
                  The Future of AI-Powered Decision Intelligence: Insights from Industry Leaders 
                </h1>
  
                <img
                  className="blogImg"
                  // style={{ filter: "grayscale(1)" }}
                  src={blog12}
                  alt="blog1"
                /> 
  
                <div className="imgContent">
                 
  
  
  
                  <h3 className="heading heading-heading fontweight hadingthree"> Embracing AI-First Thinking in Enterprise Tech  </h3>
                  <p className="heading-content cntheading">
                  As enterprises embrace AI-driven transformation, decision intelligence platforms are emerging as a key enabler of smarter, faster, and more strategic decision-making. At an industry roundtable discussion, hosted by Aays and organized by AIM, featuring leaders from Marsh McLennan, McKesson, and Charles Schwab, experts shared insights on the challenges,
                   best practices, and future of<a href="https://www.aaysanalytics.com/aays-decision-intelligence-aadi" className="anchor"> AI-powered decision intelligence </a>. 
                  </p>
                 
  
                  <p className="heading-content cntheading">
                  Here’s what they had to say. 
</p>                  
  
  
  
  
                  <h2 className="heading headings-headingss">From Data Collection to AI-Powered Decision Intelligence   </h2>
  
                  <p className="heading-content cntheading">
                  According to Dwarika Patro, Founder & COO at Aays, organizations often focus too much on gathering data instead of making real-time, AI-driven decisions. He introduced four
                   critical pillars for building effective decision intelligence platforms:    </p>
                   <ul>
                    <li className="liclass">Data Integration – Breaking down data silos and consolidating information for better insights. </li>
                    <li className="liclass">Intelligence – Embedding AI and machine learning into <a href="https://blog.aaysanalytics.com/post/leveraging-ai-and-ml-for-business-decision-making" className="anchor"> decision-making</a> frameworks.  </li>
                    <li className="liclass">Decision Modeling – Creating autonomous or human-assisted AI decision workflows.   </li>
                    <li className="liclass">Governance – Ensuring decisions improve over time with continuous learning models.   </li>

                  </ul>
                  <h2 className="heading headings-headingss">Challenges in Implementing AI-Powered Decision Intelligence    </h2>
                  <p className="heading-content cntheading">
                  Despite its potential, AI-driven decision intelligence still faces several key hurdles: 
                    </p>
                    <ol>
                        <li className="liclass">Sreenivas Gadhar (VP Global Data and Analytics at Marsh McLennan) discussed challenges posed by data silos and legacy systems </li>
                        <li className="liclass">Senguttuvan Thangaraju (Sr. Director, Enterprise Data Governance at McKesson) highlighted an internal framework for improving data quality. </li>
                        <li className="liclass">Satheesh R. (Head of Analytics, AI and Data Science Product at Charles Schwab) emphasized the importance of human oversight in regulated environments where AI aids predictions but cannot replace human judgment</li>
                    </ol>
                  
                  <h2 className="heading-four fontweights headingfour"> Best Practices for AI-First Decision Intelligence </h2>
  
                <p className="heading-content cntheading">
                Industry leaders at the roundtable discussion shared three best practices for successfully implementing AI-powered decision intelligence: 
                </p>
  
                <h3 className="heading-four fontweights headingfour">Enhancing Data Quality  </h3>
  
  <p className="heading-content cntheading">
  Investing in data integrity tools to ensure clean, structured data for AI models.   </p>
  <h3 className="heading-four fontweights headingfour">Embedding AI into Decision Intelligence    </h3>
  
  <p className="heading-content cntheading">
  Moving beyond dashboards to real-time, AI-driven decision frameworks.    </p>
  <h3 className="heading-four fontweights headingfour">Using ML for Unstructured Data    </h3>
  
  <p className="heading-content cntheading">
  Leveraging AI to analyze emails, documents, and text-based data for deeper insights.    </p>
  <p className="heading-content cntheading">
  Companies that prioritize these strategies will be in a stronger position to leverage AI for business transformation.    </p>



  <h2 className="heading headings-headingss">The Future of AI-Powered Decision Intelligence     </h2>
                  <p className="heading-content cntheading">
                  Looking ahead, the next evolution of AI in enterprise decision-making will be shaped by: 
                    </p>
                    <h3 className="heading-four fontweights headingfour">AI with Human Oversight     </h3>
  
  <p className="heading-content cntheading">
  Ensuring compliance and trust by maintaining human-in-the-loop AI systems.    </p>
  <h3 className="heading-four fontweights headingfour">Data Quality as a Strategic Imperative     </h3>
  
  <p className="heading-content cntheading">
  AI is only as strong as its data foundation, making data integrity a top priority.    </p>
  <h3 className="heading-four fontweights headingfour">Scalability & Adaptability    </h3>
  
  <p className="heading-content cntheading">
  Future AI-powered platforms will be built to scale seamlessly across different business functions.    </p>

  <h3 className="heading-four fontweights headingfour">Continuous Learning Systems     </h3>
  
  <p className="heading-content cntheading">
  AI will evolve by learning from past decisions, making future recommendations even more precise    </p>
  <p className="heading-content cntheading">
  The key takeaway? AI-powered decision intelligence is not just about technology; it is about enabling better, faster, and smarter business decisions.     </p>




  <h2 className="heading headings-headingss">AI-Powered Decision Intelligence: Where Are We Headed ?      </h2>
  <p className="heading-content cntheading">
  In our discussion with industry leaders, one thing stood out - AI is no longer just an add-on, but is it truly at the core of decision-making yet? As enterprises rethink their tech stack, the shift toward AI-first decision intelligence is gaining momentum.     </p>
  <p className="heading-content cntheading">
  With AI adoption accelerating, data quality, governance, and scalability continue to shape how organizations move forward.      </p>
  <p className="heading-content cntheading">
  Want to explore more? Download the research report here.     </p>

  < SocialShare />




  {/* <Indicusstomersuccessstories data={storyData} /> */}


  
                  
  
  
                </div>

              </div> 
               <Customerindian />
               


            </div>

            </div>
          </div>
  
               
              
  
  
         
        
  
  
         
          <div>
       
        </div>
        </div>
  
        
  
  
  
        <Footer />
  
        <div onClick={nextstep} className="scroll-btn">
          <button className="arrowBtn">
            <KeyboardArrowDownIcon
              sx={{
                fontSize: "40px",
              }}
            />
          </button>
        </div>
  
        <div className="scroll-btn-down" onClick={nextstepDown}>
          <button className="arrowBtn">
            <KeyboardArrowUpIcon
              sx={{
                fontSize: "40px",
              }}
            />
          </button>
        </div>
      </div>
  
  
    );
  }



  


export default PowerdDecison;
