import React from "react";
import { useMediaQuery } from "@mui/material/";
import Navbar from "../nav/NavBar";
import Section1 from "../pageLayout/AboutPageLayout/Section1";
import Section2 from "../pageLayout/AboutPageLayout/Section2";
import Section3 from "../pageLayout/AboutPageLayout/Section3";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import Footer from "../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import mobileBannerImage from "../assets/img/aboutUs-banner-right.png";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import Sectionnew from "../pageLayout/AboutPageLayout/Sectionnew";
import BottomBanner from "../reuseLayout/BottomBanner";
import { AboutUsSchema } from "./BlogSchema";
import { Helmet } from "react-helmet";


const nextstep = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y + (90 * $(window).height()) / 100);
};

const nextstepDown = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y - (90 * $(window).height()) / 100);
};

function AboutUs() {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  return (
    <div className="about-main" id="homePage">
      <Helmet>
         <script type="application/ld+json">
        {JSON.stringify(AboutUsSchema)}
      </script>
          </Helmet>
      <Navbar />
      <GoogleAnalytics />

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Leaders in"
          text2="Data & AI Solutions"
          subtext1="Driving Innovation and"
          subtext2="Transforming Businesses"
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Leaders in"
          text2="Data & AI Solutions"
          subtext1="Driving Innovation"
          subtext2="and Transforming Businesses"
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1="Leaders in"
          text2="Data & AI Solutions"
          subtext1="Driving Innovation"
          subtext2="and Transforming Businesses"
          picture={mobileBannerImage}
        />
      )}

      <MiddleBanner text="200+ data engagements | Over 2Bn+ business impact" />

      <Section1 prop="Who Are We ?"/>
      <div
        style={{ width: "100%", background: "#212121", position: "relative" }}
      >
        <Section2 />
      </div>
      <Section3 />

      <BottomBanner contentChange={true} />
      <Footer />
      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default AboutUs;
