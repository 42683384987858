import React from 'react'
import  { useState } from "react";
import { useMediaQuery } from "@mui/material";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import "react-multi-carousel/lib/styles.css";
import supplyImg1 from "../../assets/img/Supply Chain.png";
import supplyImg2 from "../../assets/img/inventory-case-studies-page-new.png";
import supplyImg4 from "../../assets/img/Innovation Analytics.png";
 import RightArrow from "../../pageLayout/CustomerSuccessStoryLayout/RightArrow";
 import LeftArrow from "../../pageLayout/CustomerSuccessStoryLayout/LeftArrow";
 import Supplychain from "../../assets/img/SupplychainriskAi.webp";
 import AiandInventory from "../../assets/img/Case-Study-Innovation-Analytics.png"
 import Casestudyinventoanalytics from "../../assets/img/Blog-Img-Square.webp"
 import StrengtheningSupplyChains from "../../assets/img/StrengtheningSupplyChains.webp";





const data = [
    {
        id: 1,
         title1: "Supply Chain ",
         title2: "Risk",
        content: "The Next Era of Supply Chain Risk Management with AI Intelligence",
        class: "content-box-section2 inventrynew",
        url: "https://blog.aaysanalytics.com/post/the-next-era-of-supply-chain-risk-management-with-ai-intelligence",
        image:Supplychain ,
      },
    {
        id: 2,
        title1: "Supply Chain ",
        title2: "Management",
        content:"The Future of Supply Chain Management: How AI is Leading the Way?",
        class: "content-box-section2",
        url: "https://blog.aaysanalytics.com/post/the-future-of-supply-chain-management-how-ai-is-leading-the-way",
        image: Casestudyinventoanalytics,
      },
      
      {
        id: 3,
        title1: "Innovation ",
         title2: " Analytics",
        content: "How a CPG conglomerate leveraged advanced analytics to uncover the value of innovation",
        class: "content-box-section2 inventrynew",
        url: "/customer-success-stories/analytics-to-assess-impact-of-innovation",
        image:  AiandInventory,
      },
    
  ];

const OurRecentPost = () => {
    const [startIndex, setStartIndex] = useState(0);

    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });
  
    const showLength = isIpad ? 2 : 3;
    const visibleItems = isIpad ? data.slice(startIndex, startIndex + 2) : data.slice(startIndex, startIndex + 3);
  
    return (
      <div
        id="section2-customer-success-story"
        className="customer-success-section2 scroll-section"
        style={{position: 'relative',backgroundColor:'white'}}
      >
        <div className="customer-section2-body margin-box">
          <h2 className="success-story-page-section-2-Header-text" style={{color:'black'}}>
         Our Recent Posts 
          </h2>
         
          
          
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {data.length > showLength && <LeftArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
            <div className="success-story-page-section-2-grid">
              {visibleItems.map((item) => (
                <div>
                  <CustomerSectionBox
                    picture={item.image}
                    title1={item.title1}
                    title2={item.title2}
                    content={item?.content}
                    class={item.class}
                    redirectURL={item.url}
                  />
                </div>
              ))}
            </div>
            {data.length > showLength && <RightArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
          </div>
        </div>
      </div>
    );
  }
  

export default OurRecentPost