import "./aadi.scss";
import "./aadiResponsive.scss";
import ParticleBackground from "../NewHeaderSection/Network";
import Navbar from "../../nav/NavBar";
// import Section1 from "./Section1_old";
// import Section2 from "./Section2_old";
import Section1 from "./Section1";
import Section2 from "./Section2";
import NewSection from "./NewSection";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StickyButtons from "./StickyButtons";
import StickyTabs from "./StickyTabs";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import BottomBanner from "../../reuseLayout/BottomBanner";
import { AadiSchema } from "../BlogSchema"; 

const Aadi = () => {
  useEffect(() => {
    document.title = "Aays Decision Intelligence | Finance Co-Pilot";
    // document.getElementsByTagName("META")[3].content =
    //   "Discover AaDI, Decision Intelligence Co-Pilot for Finance. Leverage AI-driven workflows to perform root-cause analysis, flux reporting, anomaly detection and more.  ";
  });

  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + (90 * $(window).height()) / 100);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page

    $(window).scrollTop(y - (90 * $(window).height()) / 100);
  };

  return (
    <>
      <div id="homePage">
         <Helmet>
          <meta
            name="keywords"
            content="Decision Intelligence Co-Pilot, Finance Co-Pilot, AI for Finance, Flux Reporting, AI Workflows, Enterprise AI Solutions, Finance Function Optimization, AI-driven Finance Solutions"
          />
          <script type="application/ld+json">
         {JSON.stringify(AadiSchema)}
       </script>
        </Helmet> 

        <Navbar />

        <div id="aadi-main">
          <Section1 />
          <Section2 />
          {/* <div> */}
          <NewSection />
          {/* <StickyButtons /> */}
          <Section3 />
          <Section4 />
          <Section5 />
          <Section6 />
          {/* </div> */}
          <Section7 />
          <Section8 />
          <Section9 />
          <BottomBanner />
          <Footer />
          <div onClick={nextstep} className="scroll-btn">
            <button className="arrowBtn">
              <KeyboardArrowDownIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </button>
          </div>

          <div className="scroll-btn-down" onClick={nextstepDown}>
            <button className="arrowBtn">
              <KeyboardArrowUpIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </button>
          </div>
        </div>
        <ParticleBackground />
      </div>
    </>
  );
};

export default Aadi;
