import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const Acoordion = ({ datas }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {datas &&
        datas.map((item) => (
          <Accordion
            expanded={expanded === `panel${item.id}`}
            onChange={handleChange(`panel${item.id}`)}
            key={item.id}
            sx={{
              //background: "transparent",
              color: "black",
              border: "none", // Remove all borders
              marginBottom: "10px",
              zIndex: "1",
              boxShadow: "none", // Remove box shadow#646464;
              borderBottom:"none",
              position:'static'
            }}
          >
            <AccordionSummary
              className="accordian-title"
              expandIcon={<ExpandMoreIcon sx={{ color: "#646464;", fontSize: "38px" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                display: 'flex', // Enable flexbox for alignment
                flexDirection: 'row-reverse', // Move the icon to the left
                padding: "0px",
                fontSize: "14px",
                lineHeight: "22.82px",
                fontWeight: "500",
              }}
            >
              {item.title}
            </AccordionSummary>
            <AccordionDetails
              className="accordian-description"
              sx={{
                padding: "0px 0px 10px 0px",
                fontSize: "14px",
                lineHeight: "26.82px",
                fontWeight: "200",
              }}
            >
              {item.description}
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

export default Acoordion;
