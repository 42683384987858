import React, {useState} from "react";
import HeaderSection from "../../reuseLayout/HeaderSection";
import banner from "../../assets/img/DataEngBanner.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import ApplyJobs from "./ApplyJobs";
import GoogleAnalytics from "../../../app/GoogleAnalytics";

const Data = [
  {
    id: 1,
    heading: "What is in it for you",
    data: [
      {
        content:
          "Opportunity to work with a world class team of business consultants and engineers solving some of the most complex business problems by applying data and analytics techniques.",
      },
      {
        content:
          "Fast track career growth in a highly entrepreneurial work environment.",
      },
      {
        content: "Best-in-industry renumeration package.",
      },
    ],
  },
  {
    id: 2,
    heading: "Technical skills",
    data: [
      {
        content:
          "Java, Spring, Spring boot, Rest API, Docker, Jenkins, GitHub/GIT and Kubernetes",
      },
      {
        content: "Knowledge of Java EE for backend service development",
      },
      {
        content:
          "Production experience with JavaScript based single page applications and frameworks (Vue, React, Angular, etc).",
      },
      {
        content:
          "Experience in developing Services on Kubernetes (Microservices) and Docker (Containerisation)",
      },
      {
        content:
          "Experience in developing RESTful APIs using either Spring, Spring Boot or Jersey",
      },
      {
        content:
          "Experience in one of the following: SQL Server, PostgreSQL, No-SQL, MongoDB, or DynamoDB",
      },
      {
        content:
          "Experience in one of the following Build and CICD technologies: GitHub, Maven, Jenkins, Nexus or Sonar, Docker, Terraform",
      },
      {
        content: "Understanding of SQL and Elasticsearch",
      },
      {
        content:
          "Working experience and good understanding of public cloud environments (Azure, and/or AWS)",
      },
      {
        content:
          "Ability to match design direction with markup and styling languages (HTML, CSS, SCSS, PostCSS)",
      },
      {
        content:
          "Agile / Lean software development experience e.g. Scrum, Kanban, XP",
      },
      {
        content:
          "Experience building highly scalable concurrent large volume event driven systems",
      },
      {
        content:
          "Strong object-oriented skills with deep knowledge of design patterns",
      },
      {
        content: "Great communication skills and ability to work independently",
      },
    ],
  },
  {
    id: 3,
    heading: "What You’ll Do",
    data: [
      {
        content:
          "Be responsible for the internal and external quality of software and help address any client satisfaction issues",
      },
      {
        content:
          "Employ agile techniques such as task estimation test, automation deployment, and continuous integration to enhance overall execution speed and product quality",
      },
      {
        content:
          "Think through hard problems and work with teams to make them reality",
      },
      {
        content:
          "Provide strong communication skills via education and mentoring to team members, business users and technology colleagues",
      },
      {
        content:
          "Develop your career by focusing on elements you are passionate about",
      },
    ],
  },
];

const data = [
  {
    text: 'Home',
    url: '/'
  },
  {
    text: 'View all Jobs',
    url: '/career'
  }
]

function FullStackDeveloper() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div id="homePage" className="openPosition-box">
      <Navbar />
      <GoogleAnalytics/>

      <HeaderSection
        text1="Full Stack Developer"
        picture={banner}
        button={true}
      />

      <div className="position-main">
        <div className="position-body">
        <div className="new-box-seventy">

        <div className="back-button">
          {data.map((ele, index) => (
            <div style={{display: 'flex'}}>
            <a href={ele.url}className="content" key={index}>
              {ele.text}
            </a>
            {index !== data.length - 1 && <div style={{color: 'white'}}>/</div>}
            </div>
          ))}
        </div>

          <div className="top">
            <div className="heading">
              <span>
                <b>Job description</b>
              </span>
            </div>
            <div className="position">
              <span>Position: Full Stack Developer</span>
            </div>
            <div  className="locationss">
              <span>Desired experience: 1-4 years</span>
            </div>
          </div>
          <hr
            style={{
              height: "5px",
              backgroundColor: "#bb1ccc",
              border: "none",
            }}
          />
          <div className="heading1">
            <div className="content">
              You will act as a key member in our R&D team developing our
              enterprise analytics product. Communication and exposure to agile
              development are keys for this position, along with a
              problem-solution attitude.
            </div>
          </div>

          <div className="heading2-list">
            {Data.map((item) => (
              <>
                <div className="heading">
                  <span>{item.heading}</span>
                </div>
                <div className="list">
                  <ul>
                    {item.data.map((item1) => (
                      <li>{item1.content}</li>
                    ))}
                  </ul>
                </div>
              </>
            ))}
          </div>
          <div className="heading1">
            <div className="heading">
              <span>
                <b>About Aays</b>
              </span>
            </div>
            <div className="content">
              Aays is an AI & data consulting firm which specialises in
              democratising data science and ML in corporate finance space. We
              partner with global conglomerates having large, complex data
              footprints and help them contextualise their data science journey
              with deep functional focus. Since our inception in 2018, we have
              solved some of the most complex business problems faced by fortune
              1000 and fast-growing companies in the world utilising big data,
              cloud computing, and AI/ML. <br />
              <br /> We are backed by a strong management team with IIT/IIM
              background and over a decade of industry experience with fortune
              100 companies. We are ranked among the
              <a
                href="https://analyticsindiamag.com/top-data-science-providers-in-india-2022-penetration-and-maturity-pema-quadrant/"
                style={{
                  color: "#bb1ccc",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {" "}
                top 5 fastest growing companies{" "}
              </a>
              in Analytics India magazine's (AIM) list of the best data science
              providers in India for 2022.
            </div>
          </div>
          <div className="apply-now">
              <button onClick={() => setOpenModal(true)}>Apply Now</button>
          </div>
        </div>
        </div>
      </div>

      <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position="Full Stack Developer"/>

      <Footer />
    </div>
  );
}

export default FullStackDeveloper;
