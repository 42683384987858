export const CustomerSuccessStoriesData = [
  {
    id: 1,
    subHeaderText: "MARKET MIX MODELLING",
    content:
      "Optimizing marketing mix strategies for a leading snack foods manufacturer",
    redirectUrl:
      "/customer-success-stories/market-mix-modelling-snacks-food-manufacturer",
    tags: ["blog", "newsroom"],
  },
  {
    id: 2,
    subHeaderText: "VENDOR RISK MANAGEMENT",
    content:
      "Managing vendor risks in a complex supply chain: Case study of a global consumer goods manufacturer",
    redirectUrl: "/customer-success-stories/vendor-risk-management-case-study",
    tags: ["cpg", "supplyChain", "blog", "about"],
  },
  {
    id: 3,
    subHeaderText: "INNOVATION ANALYTICS",
    content:
      "How a CPG conglomerate leveraged advanced analytics to uncover the value of innovation and assess the impact of new product launches",
    redirectUrl:
      "/customer-success-stories/analytics-to-assess-impact-of-innovation",
    tags: ["cpg", "supplyChain", "newsroom"],
  },

  {
    id: 4,
    subHeaderText: "AI TOOL FOR CONTROLLERS",
    content:
      "Equipping finance controllers with an AI-powered tool for faster closure of books and outlier detection",
    redirectUrl:
      "/customer-success-stories/ai-powered-tool-for-finance-controllers",
    tags: ["home", "commercial", "blog", "about"],
  },
  {
    id: 5,
    subHeaderText: "SUPPLY CHAIN",
    content:
      "How a global CPG conglomerate strengthened its supply chain with optimised supply planning and improved AI-enabled demand forecasting",
    redirectUrl:
      "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting",
    tags: ["home", "cpg", "supplyChain", "about"],
  },

  {
    id: 6,
    subHeaderText: "INVENTORY ANALYTICS",
    content:
      "Inventory transformation and optimization: How Aays helped a global CPG company enhance its inventory management processes",
    redirectUrl: "/customer-success-stories/inventory-optimization-global-CPG",
    tags: ["home", "supplyChain", "blog", "newsroom", "about"],
  },
  {
    id: 7,
    subHeaderText: "PRIME COST",
    content:
      "Driving strategic initiatives through enhanced prime cost visibility: Success story of a global CPG company",
    redirectUrl:
      "/customer-success-stories/prime-cost-visibility-global-CPG-success-story",
    tags: ["cpg", "commercial"],
  },
  {
    id: 8,
    subHeaderText: "WORKING CAPITAL ANALYTICS",
    content:
      "Achieving financial success: How a global CPG company enhanced working capital efficiency with advanced analytics",
    redirectUrl:
      "/customer-success-stories/working-capital-analytics-global-CPG",
    tags: ["commercial"],
  },
  {
    id: 9,
    subHeaderText: "VENDOR PAYMENTS",
    content:
      "Leveraging AI-based predictive models to optimize Vendor Payments for a multi-billion-dollar consumer goods company",
    redirectUrl:
      "/customer-success-stories/vendor-payments-optimization-case-study",
    tags: ["commercial"],
  },
  {
    id: 10,
    subHeaderText: "ACCOUNT RECEIVABLE",
    content:
      "How a global CPG conglomerate strengthened its accounts receivable function by leveraging advanced analytics",
    redirectUrl:
      "/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics",
    tags: ["story"],
  },
  {
    id: 11,
    subHeaderText: "DAILY SALES POSITIONING",
    content:
      "Normalising daily sales position leveraging advanced smoothening and forecasting techniques",
    redirectUrl:
      "/customer-success-stories/advanced-daily-sales-positioning-case-study",
    tags: ["home", "newsroom"],
  },
  {
    id: 12,
    subHeaderText:
      "Data Platform & Ingestion for a $40 Bn Conglomerate in Azure (1 PB data)",
    content:
      "Supported and enhanced a Global Data Platform in Azure with over 170 pipelines which included ingesting data from over 25 source system - multiple instances of SAP ECC, S4 HANA, Oracle, Salesforce and other legacy sources spread across multiple geographies and segments.",
    redirectUrl: "",
    tags: ["data engineering"],
  },
  {
    id: 13,
    subHeaderText:
      "Tenancy & Report Migration from a legacy BI tool to Azure Cloud",
    content:
      "Developed an end to end data platform in Azure for an Australian fleet management company which included migrating over 350+ reports accessed by over c. 3,000 business users including external 3rd party users. Performed tenancy migration and improvised data architecture leading to over 60% cost savings. ",
    redirectUrl: "",
    tags: ["data engineering"],
  },
  {
    id: 14,
    subHeaderText: "Enterprise Data lake for Oracle PLM application in Azure",
    content:
      "Build the complete end to end platform and architectural blueprint for maintaining, supporting and enancing c.50 reports used by c. 200 users across multiple business functions, regions and segments.",
    redirectUrl: "",
    tags: ["data engineering"],
  },
  {
    id: 15,
    subHeaderText:
      "Advanced Anaytics use cases summary leveraging Statistics and Data science techniques",
    content:
      "Developed forecasting use cases leveraging ML based techniques viz. FB prophet, ARIMA, OLS regression etc. Undertook several data science initiatives for segmentation, NLP, casual AI, classification related use cases. ",
    redirectUrl: "",
    tags: ["data engineering"],
  },
  {
    id: 16,
    subHeaderText: "Revolutionizing In-Car Telematics for a Global Auto Leader",
    content:
      "Partnering with a leading global automotive manufacturer, we analyzed the data collected by vehicle sensors, focusing on external (road, weather) and internal (driver, passengers) aspects. Our collaborative efforts uncovered avenues for improving existing vehicle capabilities while strategizing for new model integrations. By monitoring monthly advancements, we provided insights to forecast and shape future trends in automotive technology.",
    redirectUrl: "",
    tags: ["automotive"],
  },
  {
    id: 17,
    subHeaderText:
      "Forecasting through Telematics Insights to Streamline Inventory Management for a large OEM ",
    content:
      "Leveraging telematics data and comprehensive vehicle usage statistics, we revolutionized inventory management by accurately predicting spare parts requirements. Our analysis enabled precise forecasting, optimizing inventory levels for enhanced efficiency and cost-effectiveness. ",
    redirectUrl: "",
    tags: ["automotive"],
  },
  {
    id: 18,
    subHeaderText:
      "Used Telematics to Enhance the Customer Experience for a Global Automotive Company ",
    content:
      "Our Telematics solutions improved the global automotive company's customer experience by monitoring key vehicle statistics. Tracking critical components like engine and oil filter health allowed for proactive service recommendations. In-depth vehicle trouble code analysis ensured precise assessment of engine diagnostics codes and overall vehicle health parameters, minimizing unexpected failures and reducing recall risks. ",
    redirectUrl: "",
    tags: ["automotive"],
  },
  {
    id: 19,
    subHeaderText:
      "Helped a Large Automotive Manufacturer Transform Warranty Claims with Driver-Centric Analytics",
    content:
      "For this auto manufacturer, the basic warranty lacked alignment with actual driver usage, resulting in frequent unexpected car faults. Our analysis correlated vehicle usage with diverse driving styles to anticipate future claims for each style. Proactively, we communicated 'drive right' messages to at-risk drivers, mitigating potential issues and improving overall warranty claims​.",
    redirectUrl: "",
    tags: ["automotive"],
  },
  {
    id: 20,
    subHeaderText: "QM",
    content:
      "Identify & document failure trends happening with vehicles of the same model​ Track it with the manufacturer  Supplier and plant code etc.​ Advance clustering and Machine learning model to pre-empt likely issues, root cause analysis etc.​​",
    redirectUrl: "",
    tags: ["automotive"],
  },
  {
    id: 21,
    subHeaderText: "R&D",
    content:
      "Vehicle usage stats helps R&D to design the right solutions for future Reporting on maintenance tasks can reveal weak points in machine components. ​Diagnostic Trouble Codes (DTCs) show machine malfunctions during operations and give an overall picture of error sources that can help your R&D team design better machines.​Historical weather reporting gives you information about how conditions may impact your machine's performance in the field.​You can generate reports to gain more information about the machine's performance during a testing​​",
    redirectUrl: "",
    tags: ["automotive"],
  },
  {
    id: 22,
    subHeaderText: "AI-enabled Supply Chain resiliency tool",
    content:
      "Deployed AI powered smart-insights to manage supply chain disruptions leveraging cutting-edge gen-AI and simulation capabilities. Contextualizes into a comprehensive risk measurement framework relevant to the organizational requirements.",
    redirectUrl: "",
    tags: "aiInnovation",
  },
  {
    id: 23,
    subHeaderText: "Autonomous Flux reporting",
    content:
      "Deployed AI powered reasoning & smart insights engine to provide autonomous flux reporting on a set of financial data with defined hierarchies. The tool saves a lot of analyst and audit time to spot anomalies and report on P&L variances.",
    redirectUrl: "",
    tags: "aiInnovation",
  },
  {
    id: 24,
    subHeaderText: "Advanced Forecasting ",
    content:
      "Developed a comprehensive forecasting solution by considering seasonality, trend analysis, and other macro-economic indicators to improve the accuracy of forecasting solutions.",
    redirectUrl: "",
    tags: "aiInnovation",
  },
  {
    id: 25,
    subHeaderText: "Inventory Optimization",
    content:
      "Deployed advanced analytics for a $40 Bn CPG company which provided info on Inventory coverage, freshness/ expiry, and end to end visibility. Identified opportunities worth $220 Mn of potential stock outages and $11Mn of potential stock expiry",
    redirectUrl: "",
    tags: "aiInnovation",
  },
  {
  id: 26,
     
      content:
        "We believe in creating an environment at Aays where innovation, collaboration, and growth can thrive. With a strong focus on work-life balance and continuous learning, we empower our team to reach their full potential",
      redirectUrl:
        "https://blog.aaysanalytics.com/post/aays-great-place-to-work-certified",
      tags: "abc",
  }
    ];
