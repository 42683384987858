import React, { useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import ai54 from "../../assets/img/Blog-Img-Square.webp"
import SupplychainriskAi from "../../assets/img/SupplychainriskAi.webp";
import DemndFrcasting from  "../../assets/img/DemndFrcasting.webp";
import AIandInventoryMngement from "../../assets/img/AIandInventoryMngement.png";
import Analytics from "../../assets/img/Analytics-reliable-chain.webp"
import Transformingcpg from "../../assets/img/Transforming-cpg-pricing.webp"
import Optimise from "../../assets/img/Optimise-chain-cost.webp";
import ai23 from "../../assets/img/Supply-Reduction.webp";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RightArrow from "../CustomerSuccessStoryLayout/RightArrow";
import LeftArrow from "../CustomerSuccessStoryLayout/LeftArrow";
import { useMediaQuery, useTheme } from "@mui/material/";


const items = [
  {
    id: 1,
    content:
      "Supply Chain Cost Reduction Strategies: How AI and Analytics Revolutionize CPG Efficiency",
    class: "content-box-lifeaa",
    image: ai23,
    date: "29-Jan, 2025",
    read :'2 min read',
    url: "/blog/cpg-supply-chain-cost-reduction",
  },
  {
    id: 2,
    content:
    "The Next Era of Supply Chain Risk Management with AI Intelligence",
  class: "content-box-lifeaa",
  image: SupplychainriskAi,
  date: "Dec 19, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/the-next-era-of-supply-chain-risk-management-with-ai-intelligence",
  },
  {
    id: 3,
    content:
    "How AI is Reshaping the Future of Demand Forecasting",
  class: "content-box-lifeaa",
  image: DemndFrcasting,
  date: "Dec 3, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/ai-demand-forecasting",
  },
  {
    id: 4,
    content:
    "Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge",
  class: "content-box-lifeaa",
  image: AIandInventoryMngement,
  date: "Sep 11, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/ai-inventory-management",
  },
  {
    id: 5,
    content:
    "The Future of Supply Chain Management: How AI is Leading the Way?",
  class: "content-box-lifeaa",
  image: ai54,
  date: "Sep 13, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/the-future-of-supply-chain-management-how-ai-is-leading-the-way",
  },
  {
    id: 6,
    content:
    "Increase Sales with CPG Pricing Analytics: A 5-10% Boost Opportunity",
  class: "content-box-lifeaa",
  image: Transformingcpg,
  date: "Aug 8, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/transforming-cpg-pricing-analytics",
  },
  {
    id: 7,
    content:
    "Supply Chain Cost Reduction in CPG Firms through Data-Driven Optimization",
  class: "content-box-lifeaa",
  image: Optimise,
  date: "May 4, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/cpg-supply-chain-cost-reduction",
  },
  {
    id: 8,
    content:
    "Enhancing Supply Chain Management with Analytics: Cost Reduction and Efficiency",
  class: "content-box-lifeaa",
  image: Analytics,
  date: "April 13, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/analytics-in-supply-chain-management",
  },

]
const Section3 = () => {
 
  const [startIndex, setStartIndex] = useState(0);

  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const showLength = isIpad ? 2 : 3;
  const visibleItems = isIpad
    ? items.slice(startIndex, startIndex + 2)
    : items.slice(startIndex, startIndex + 3);
  

  return (
    <div  id="supplychainss" className="customer-success-section3 content-box-lifeaays section scroll-section">      {/* customer-success-section3 */}
      <div className="customer-section3-body">
        <div className="success-story-page-section-3-Header-text">
        {/* id="caseStudySection3"  */}
        Supply Chain & CPG
        </div>
       
        <div className="success-story-page-section-3-grid">
          {items.length > showLength && (
            <LeftArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
          {visibleItems.map((item) => (
            <div key={item.id}>
              <CustomerSectionBox
                picture={item.image}
                date={item.date}
                read={item.read}
                title1={item.title1}
                title2={item.title2}
                content={item?.content}
                class={item.class}
                redirectURL={item.url}
              />
            </div>
          ))}
          {items.length > showLength && (
            <RightArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Section3;
