import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../nav/Footer";
import Navbar from "../nav/NavBar";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import infographic1 from "../assets/img/Automotive_Infographic-1.png";
import infographic2 from "../assets/img/Automotive_Infographic-2.png";
import HomePageSection5 from "../pageLayout/HomePageLayout/HomePageSection5";
import { CustomerSuccessStoriesData } from "../constant/CustomerSuccessStoriesData";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMediaQuery } from "@mui/material";
import mobileViewImage1 from "../assets/img/automotive-mobile-view-1.png";
import mobileViewImage2 from "../assets/img/automotive-mobile-view-2.png";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import Section5 from "../pageLayout/AboutPageLayout/Section5";
import mobileBannerImage from "../assets/img/automotive-banner-right.png";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import BottomBanner from "../reuseLayout/BottomBanner";
import { SchemaAutomotive } from "./BlogSchema"; 

const Automotive = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + (90 * $(window).height()) / 100);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - (90 * $(window).height()) / 100);
  };

  useEffect(() => {
    document.title =
      "Aays AI Solutions: Transformation in Automotive Industry with AI and Analytics";
    // document.getElementsByTagName("META")[3].content =
    //   "Discover Aays' enterprise AI capabilities for the automotive industry. Leverage AI in automotive to harness telematics analytics, vehicle data insights and more. ";
  });

  return (
    <div id="homePage" className="automotive-main">
      {/* <Helmet>
        <meta
          name="keywords"
          content="Aays AI Solutions, Enterprise AI capabilities, AI in Automotive, telematics analytics, vehicle data insights, AI tools, Advanced Analytics"
        />
      </Helmet> */}
      <Helmet>
       <script type="application/ld+json">
        {JSON.stringify(SchemaAutomotive)}
           </script>
       </Helmet>

      <Navbar />
      <GoogleAnalytics />

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Transforming Automotive Value Chain with AI and Analytics"
          subtext1="From Architecture Setup to"
          subtext2="Operational Excellence"
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Transforming Automotive Value"
          text2="Chain with AI and Analytics"
          subtext1="From Architecture Setup to"
          subtext2="Operational Excellence"
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1="Transforming Automotive Value Chain"
          text2="with AI and Analytics"
          subtext1="From Architecture Setup to"
          subtext2="Operational Excellence"
          picture={mobileBannerImage}
        />
      )}

      <MiddleBanner text="200+ Data Engagements | Over $2Bn+ Business Impact " />

      <div className="automotive-section1">
        <div className="margin-box">
          <h1 className="header-text">
            Transforming Automotive Value Chain with AI
          </h1>
          {isMobile ? (
            <img
              loading="lazy"
              style={{
                width: "100%",
                objectFit: "contain",
                objectPosition: "center",
              }}
              src={mobileViewImage1}
              alt="infographic1"
            />
          ) : (
            <img
              loading="lazy"
              style={{
                width: "100%",
                objectFit: "contain",
                objectPosition: "center",
              }}
              src={infographic1}
              alt="infographic1"
            />
          )}
        </div>
      </div>

      <div className="automotive-section2">
        <div className="margin-box">
          <div className="header-text">
            How Aays is powering outcomes using Telematics driven analytics
          </div>

          <p className="text">
            Aays drives enterprise success by decoding vehicle data into
            actionable insights. From predictive maintenance to optimizing
            operations, our Telematics-driven solutions redefine efficiency and
            empower industries to make informed decisions, ensuring seamless
            operations and elevated performance.
          </p>
          {isMobile ? (
            <img
              loading="lazy"
              style={{
                width: "100%",
                objectFit: "contain",
                objectPosition: "center",
              }}
              src={mobileViewImage2}
              alt="infographic1"
            />
          ) : (
            <img
              loading="lazy"
              style={{
                width: "100%",
                objectFit: "contain",
                objectPosition: "center",
              }}
              src={infographic2}
              alt="infographic1"
            />
          )}
        </div>
      </div>

      <div className="automotive-section3">
        <div className="margin-box">
          <div className="header-text">
            Transforming Connectivity and Mobility Solutions
          </div>
          <p className="text">
            Aays specializes in delivering tailored solutions to key
            stakeholders within the connectivity and mobility ecosystem, driving
            innovation and optimizing operations.
          </p>

          <div className="automotive-section3-grid">
            <div className="automotive-section3-item-box">
              <span className="item-header">Empowering OEMs</span>
              <span className="item-content text_overFlow ">
                Our solutions empower OEMs to leverage data insights for
                enhanced performance and seamless customer experiences.
              </span>
            </div>

            <div className="automotive-section3-item-box">
              <span className="item-header">Roadside Assistance</span>
              <span className="item-content text_overFlow ">
                Utilize our platform to efficiently dispatch rescue vehicles,
                ensuring swift and effective roadside assistance and optimizing
                crucial response times.
              </span>
            </div>

            <div className="automotive-section3-item-box">
              <span className="item-header">Mobility Providers</span>
              <span className="item-content text_overFlow ">
                Our tailored solutions empower fleet management companies to
                leverage mobility data to create new solutions for businesses.
              </span>
            </div>

            <div className="automotive-section3-item-box">
              <span className="item-header">Beyond Automotive </span>
              <span className="item-content text_overFlow ">
                Non-auto players like restaurants and retailer companies are
                leveraging telematics data to offer value-added mobility
                services.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <Section5
            data={CustomerSuccessStoriesData}
            tag="automotive"
            show="false"
          />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="automotive"
            show="false"
          />
        )}
      </div>
      <BottomBanner contentChange={true} />
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default Automotive;
