import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import { Helmet } from "react-helmet";
import GenAiBanner from "../../assets/img/indi_New_to_Databricks.jpg";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import Blgfvcn from "../../assets/img/Blgfvcn.png";
import SocialShare from "./newBLog/SocialShare";


function DatabricksTipsForBeginners() {
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };
  

  useEffect(() => {
    document.title = "Databricks | Useful Tips";
    document.getElementsByTagName("META")[3].content =
      "Databricks tips for the beginners. The transition to Spark from python can be quite challenging. Here are some fundamental & useful Databricks' concepts. ";
  })
  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];

  return (
    <div id="homePage">
      <Helmet>
        <meta name="keywords" content="Databricks Tips" />
      </Helmet>

      <Navbar bg="black" />
      <GoogleAnalytics />

      <div className="GenAi-body newclas">
        <div className="margin-blogMore">
          <div className="GenAi-main">
            <div className="border-genai">
              <div className="new-box-seventy">
                <div className="back-button" style={{ paddingTop: "2rem" }}>
                  {data.map((ele, index) => (
                    <div className="back" key={index}>
                      <a href={ele.url} className="content" key={index} style={{ color: 'black' }}>
                        {ele.text}
                      </a>
                      {index !== data.length - 1 && (
                        <div style={{ color: "black" }}>/</div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flexteamaays" style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                  <div>
                    <img src={Blgfvcn} alt="aays" className="imagi" />
                  </div>
                  <div style={{ fontWeight: '500' }}>
                    <span className="cntheading" style={{ marginRight: '6px' }}>Team Aays</span>
                    <span className="cntheading sixjan" style={{ marginRight: '6px' }} >.</span>
                    <span className="cntheading sixjan" >Dec 06</span>

                  </div>
                </div>
                <h1 className="main-heading fontweight mainheaders">
                  {" "}
                  New to Databricks? Here Are Some Useful Tips
                </h1>

                <img
                  className="blogImg"
                  // style={{ filter: "grayscale(1)" }}
                  src={GenAiBanner}
                  alt="blog1"
                />

                <div className="imgContent">
                  <p className="heading-content cntheading">


                    The transition to Spark from python can be quite challenging at first as we are introduced to a whole new set of terminology such as clusters, pool, mounts, spark API, and so on.
                    In this post, we will look at how Microsoft Azure Databricks  may be used.
                  </p>




                  <h2 className="heading headings-headingss"> Databricks Clusters


                  </h2>



                  <p className="heading-content cntheading">
                    To process data, we are in need of a new cluster. We have two kinds of clusters available in Databricks. Interactive clusters and Job clusters. Interactive clusters are used if the emphasis is on data analytics whereas job clusters can be used to run fast automated workflows. We use the former when we are in the DEV phase and the latter in the Production phase. If our cluster is busy, it will take a long time to run our job, we can mitigate this issue by creating a pool. Clusters are attached to the pool, so, when we need a new cluster, we don’t need to get them from a cloud provider rather the same can be done with a pool of VM instances.                  </p>

                  <h2 className="heading headings-headingss"> Notebook in Databricks




                  </h2>

                  <p className="heading-content cntheading">
                    Now we can use the notebooks to start processing the data. It is a good practice to modularize the notebooks by effectively using markdown for headers – data load and configurations, data filters, joins, business logic. We can also log our data to audit by creating a logger for every run. Usage of a separate notebook to load all libraries and helper functions can be invoked by ‘%run notebook path’ command in the driver notebook. Also, to use shell commands, we can use ‘%sh’ command.</p>

                  <h2 className="heading headings-headingss"> Mount Point in Azure Databricks






                  </h2>
                  <p className="heading-content cntheading">
                    We need to mount our data to a mount point to access the data. After mounting the data, we can check if we have loaded and have access to our data by using the ‘dbutils’ command. We are ready to load our data into Spark dataframe. Giving meaningful names to our dataset can often save us time during debugging.
                  </p>
                  <h2 className="heading headings-headingss">Data Cleansing and Data Discovery








                  </h2>

                  <p className="heading-content cntheading">
                    Now we can perform some data cleansing using regex commands. Also, date time functions, cast datatypes can serve handy for data analysis and for doing deep data discovery. Knowing how to transform our data to json can be quite useful as it can be used for looking up values similar to a dictionary in python.
                  </p>
                  <h2 className="heading headings-headingss">Data Engineering with Databricks










                  </h2>
                  <p className="heading-content cntheading">
                    We are finally ready to do <a href="https://www.databricks.com/solutions/data-engineering" className="anchor">some data engineering </a> since we have lots of API. But in order to leverage the powerful APIs of spark, we need to first import them. Once imported, here are some useful functions to add to your data engineering toolset:

                  </p>

                  <h4 className="heading-four fontweights headingfour">  withColumn() –
                  </h4>

                  <p className="heading-content cntheading">
                    Creation of Derived column. Suppose we would like to do the transformation on an existing column, we can use withColumn. This step can be very handy in data cleansing.
                  </p>
                  <h4 className="heading-four fontweights headingfour"> regexp_replace() -</h4>

                  <p className="heading-content cntheading">
                    Sometimes our data might have some extra characters either prepended or appended to them and we need to remove them. This can be done by using ‘regex_replace’ function and passing the correct regular expression as parameters to the command.
                  </p>
                  <h4 className="heading-four fontweights headingfour">  when() & otherwise () -


                  </h4>

                  <p className="heading-content cntheading">
                    This can be useful when we are interested in case logic. When we deal with fields having only numerical values, we need to ensure they all belong to the same family of ‘units.’ In such scenarios, the ‘when-otherwise’ function can be useful.
                  </p>
                  <h4 className="heading-four fontweights headingfour"> filter() and isin() –</h4>

                  <p className="heading-content cntheading">
                    If we must filter a specified column by multiple conditions, we can just use the filter function on a list of values using isin(). Note, when we have a large filter set it is best to achieve the same using joins for better performance.
                  </p>
                  <h4 className="heading-four fontweights headingfour"> to_date() –


                  </h4>

                  <p className="heading-content cntheading">
                    Often when we wish to perform delta or incremental load, we just need the last few periods of data and if the data is stored in string format, we need to first convert it to standard datetime so that we can filter data using date.
                  </p>

                  <h4 className="heading-four fontweights headingfour"> rank() & partitionBy() -




                  </h4>
                  <p className="heading-content cntheading">
                    Sometimes updates may be generated for an existing record in the datalake but both the previous value and new value are retained. We are interested in fetching the most updated transaction depending on the logic for fetching the right transactional details. So, a combo of the window functions - rank over and partition would get the job done.                  </p>



                  <h2 className="heading headings-headingss">Finally, Pipelines to Load Data












                  </h2>
                  <p className="heading-content cntheading">
                    After all the processing is completed, it is a best practice to do reconciliation of data with the source using different metrics because as a techie we might often miss some business logic especially if we are dealing with calculated fields. Once we have our golden data (verified with source systems) we can start creating pipelines to load our data into the server database.                </p>



                  <p className="heading-content cntheading">
                    <a href="https://docs.databricks.com/aws/en/getting-started/concepts" className="anchor">Click here </a> for more understanding of Databricks’ fundamental concepts.


                  </p>
       <SocialShare />




                </div>
              </div>
            </div>
          </div>
        </div>










        <div>

        </div>

      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default DatabricksTipsForBeginners;
