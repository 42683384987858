import React, { useState } from "react";
import HeaderSection from "../../reuseLayout/HeaderSection";
import banner from "../../assets/img/DataEngBanner.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import ApplyJobs from "./ApplyJobs";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SchemaDataArchtect } from "../BlogSchema";
import { Helmet } from "react-helmet";
import Section1 from "../../pageLayout/AboutPageLayout/Section1";
import Section5 from "../../pageLayout/CareerPageLayout/Section5";
import Aaysllife from "./Aaysllife";
import HomePageSection5 from "../../pageLayout/HomePageLayout/HomePageSection5";
import CustomerSuccessSection from "../../pageLayout/AboutPageLayout/Section5";
import { useMediaQuery } from "@mui/material";
import Section2Mobile from "../../pageLayout/CustomerSuccessStoryLayout/Section2Mobile";
import AmitGrnle from "../../assets/img/AmitGrnle.png";
import Tanishka from "../../assets/img/Tanishka-Shah.webp";
import Surbhjain from "../../assets/img/Surbhjain.png";
import LeftArrow from "../../pageLayout/CustomerSuccessStoryLayout/LeftArrow";
import RightArrow from "../../pageLayout/CustomerSuccessStoryLayout/RightArrow";
import { CustomerSuccessStoriesData } from "../../constant/CustomerSuccessStoriesData";


const data23 = [
    {
        id: 1,
        heading: "Data Analyst Consultant Insights Career Growth Journey with Tanishka Shah ",
        
        //content: "Inventory transformation and optimization: How Aays helped a global CPG company enhance its inventory management processes",
        class: "content-box-section2",
        redirectURL: "https://blog.aaysanalytics.com/post/data-analyst-consultant-insights-tanishka-shah",
        image:Tanishka,
      },
      {
        id: 2,
        heading: "Data Analyst Career Insights: Interview with Saurabh Jain, Senior Data Analyst",
        //content: "The Next Era of Supply Chain Risk Management with AI Intelligence",
        class: "content-box-section2 inventrynew",
        redirectURL: "https://blog.aaysanalytics.com/post/data-analyst-career-insights-interview-with-saurabh-jain-senior-data-analyst",
        image: Surbhjain ,
      },
      {
        id: 3,
        heading: "Empowered to Learn and Grow: Amit Girnale's Data Analytics Career Growth at Aays ",
       // content: "Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge",
        class: "content-box-section2 inventrynew",
        redirectURL: "https://blog.aaysanalytics.com/post/amit-girnale-data-analytics-career-growth",
        image: AmitGrnle,
      },
    
  ];

const Data1 = [
  {
    id: 1,
   // heading: "About Aays",
    content:
      "Are you passionate about data and looking to grow in a dynamic consulting environment? Our Data Analyst job offers the opportunity to work directly with partners and senior stakeholders, designing and implementing data and BI strategies. In this role, you’ll be responsible for developing and maintaining data platforms, testing, and improving data warehouses, while collaborating with CTOs, Product Owners, and other stakeholders to identify new requirements.  ",
  },
//   {
//     id: 2,
//     heading: "Your team",
//     content:
//       "As a key member of the consulting team, you will help clients re-invent their corporate finance functions by leveraging advanced analytics. You’ll work closely with senior stakeholders to design and implement data strategies in the finance domain, including use cases like controllership, FP&A, and GPO. Your role will include delivering scalable technical solutions using cloud and big data technologies. Collaboration with Business Consultants and Product Owners will be critical to ensure effective solutions. Strong communication and organizational skills are essential for success. ",
//   },
];
const Data2 = [
    {
        id: 1,
        heading: "Key Responsibilities of a Data Analyst  ",
        heading3:"As a Data Analyst, you’ll be expected to: ",
        data: [
          {
            content:
              "Develop KPIs and reporting for C-suite executives (CEOs, CXOs) using visualization tools such as Power BI and Tableau.  "
          },
          {
            content:
              " Collaborate with Data Engineers to ensure the creation of stable and reliable data warehouses/data lakes. "
          },
          {
            content:
              "Design and develop data-focused products and solutions to meet client needs.  "
          },
          {
            content:
              "Work closely with CTOs, Product Owners, and Operations teams to plan and deliver engineering roadmaps.  "
          },
          {
            content:
              " Perform data integration, transformation, and modeling to ensure the accuracy and accessibility of business-critical data. "
          },
          {
            content:
              "Maintain documentation and knowledge bases for ongoing improvements.  "
          },
          {
            content:
              " Research and suggest new database products, services, and protocols to keep our data solutions innovative. "
          },
        ],
      },
     
      {
        id: 2,
        heading: "Essential Skills and Experience for Data Analyst Role  ",
        heading3:"To succeed in this Data Analyst job, you should possess the following skills:  ",
        data: [
          {
            content:
              "Expertise in Power BI, Excel, R/Python, SQL, ETL, DAX, and M language. ",
          },
          {
            content:
              "Experience with Microsoft BI Stack (SSIS, SSAS, SSRS).   ",
          },
          {
            content: "A good understanding of Azure-based data solutions like Azure Data Bricks, Data Factory, and Data Lake Store.  ",
          },
          {
            content: "Strong communication skills to work independently and with remote teams. ",
          },
        ],
      },
    
      {
        id: 3,
        heading: "Why Join Aays?  ",
        data: [
          {
            content:
              "We offer a competitive salary and the opportunity to grow in a challenging, rewarding environment. As a key player in our Data consulting team, you’ll make a significant impact on client solutions, with ample opportunities to develop your skills and advance your career. ",
          },
    
        ],
      },
    
      
];

const data = [
  //   {
  //     text: <ChevronLeftIcon />,
     
  //  },
  {
    text: 'View all Jobs',
    url: '/career'
  }
]

function DataAnalyst() {
  const [openModal, setOpenModal] = useState(false);
   const isMobile = useMediaQuery("(max-width: 767px)");
       const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
         defaultMatches: false, // Set defaultMatches to false
       });
    
    
    

  return (
    <div id="homePage" className="openPosition-box case-studies-pages-cls dataArchitectMobile about-main career-main" >
        
      <Helmet>
      <script type="application/ld+json">
        {JSON.stringify( SchemaDataArchtect)}
        </script>
         </Helmet>

      <Navbar />
      <GoogleAnalytics />

      <HeaderSection text1="Data Analyst Job – Join Us" picture={banner} button={true} />

      <div className="position-main">
        <div className="position-body">
          <div className="new-box-seventy">

            <div className="back-button">
              {data.map((ele, index) => (
                <div style={{ display: 'flex' }}>
                  <ChevronLeftIcon />
                  <a href={ele.url} className="content" key={index}>   
                    {ele.text} 
                  </a>
                   {index !== data.length - 1} 
                </div>
              ))}
            </div>

            <div className="top">
              <div className="heading">
                <h4>
                <span>
                  <b>Job description</b>
                </span>
                </h4>
              </div>
              <div className="position">
                <h4>
                <span>Position: Data Analyst </span>
                </h4>
              </div>
              <div className="locationss">
                
                <span>Desired experience : 3-7 years </span>
              
              </div>
            </div>
            <hr
              style={{
                height: "5px",
                backgroundColor: "#bb1ccc",
                border: "none",
                marginBottom:'1rem',
              }}
            />

            {Data1.map((item) => (
              <>
                <div className="heading1">
                  {/* <div className="heading">
                    <span>
                      <h3>
                      <b>{item.heading}</b></h3>
                    </span>
                  </div> */}
                  <div className="content" >{item.content}</div>
                </div>
              </>
            ))}

            <div className="heading2-list">
              {Data2.map((item) => (
                <>
                  <div className="heading">
                    <h3>
                    <span>{item.heading}</span>
                    </h3>
                  </div>
                  <div className="content contentheading">
                    <span>{item.heading3}</span>
                  </div>
                  <div className="list">
                    <ul>
                      {item.data.map((item1) => (
                        <li>{item1.content}</li>
                      ))}
                    </ul>
                  </div>
                </>
              ))}
            </div>
            <p style={{color:'white',margin:'3px 0'}}>If you're excited about shaping the future of data management and consulting, we want to hear from you!  </p>
            <div className="apply-now">
              <button onClick={() => setOpenModal(true)}>Apply Now</button>
            </div>          
    </div>
        </div>
      </div>
      <Section1 prop="About Aays "/>
      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <CustomerSuccessSection
            data={CustomerSuccessStoriesData}
            tag="abc"
            show="true"
            heading1="Why Aays is a" 
          heading2=" Great Place to Work?  "
         autoPlay={false} 
         showDots={false}
                   />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="abc"
            show="true"
            heading="Great Place to Work" 
            autoPlay={false} 
            swipeable={false}
 
          />
        )}
      </div>
     
     {isMobile ? (
        <div style={{ paddingTop: "2.5rem", backgroundColor: "black" }}>
          <Section2Mobile
            heading="Life at Aays "
            data={data23}
          />
        </div>
      ) : (
        <div className="section" id="caseStudySection2">
          < Aaysllife />
        </div>
      )} 
     {/* <div
        style={{
          backgroundColor: "black",
          //position: "relative",//
        }}
      >
        {!isMobile ? (
          <CustomerSuccessSection
            data={FileData}
            tag="home"
          />
        ) : (
          <HomePageSection5
            data={FileData}
            tag="home"
            // visibility="show"
          />
        )}
      </div> */}
      
      <div className="career-body">
      <Section5  explore="Explore More Job Openings " />
    </div>
      
      {/* <Section5 showFlag={true} techHeading = "Our AI Excellence" secheading="Trusted Tech Partners" />
     <AccoladesAndRecognitions className="home-main" />
      <Trendingsection /> */}
     


      <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position="Data Analyst" />

      <Footer />
    </div>
  );
}

export default DataAnalyst;
