import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/working-capita-indi-case-study.jpg";
import { SchemaWorkingCapital } from "../BlogSchema";

const storyData = {
  title:
    "Achieving financial success: How a global CPG company enhanced working capital efficiency with advanced analytics",
  content:
    "Working capital management issues were addressed for a major consumer goods company by utilizing advanced analytics to examine data related to accounts receivable, inventory, accounts payable, profits, and more across various business units.",
  content2:
    "The company obtained valuable insights into customer payment behaviours, overstocking/understocking situations, and supplier payment patterns, resulting in decreased days sales outstanding, optimized inventory levels, and an enhanced cash conversion cycle.",

  subTitle1: "10 days",
  subText1: "Uncovered opportunities to reduce day sales outstanding",
  subTitle2: "15%",
  subText2: "Uncovered opportunities to optimize inventory levels",
  subTitle3: "Optimized vendor payment term by  ",
  subText3: "15 Days",
  imageData: picture,
};

function Working_Capital_Analytics() {

  useEffect(() => {
    document.title = "Enhanced Working Capital Efficiency for Global CPG | Aays";
    document.getElementsByTagName("META")[3].content="Aays improved working capital efficiency for a global CPG. Explore insights on accounts receivable, inventory, and profits leading to enhanced financial success.";
  });

  return (
    <div className="backround-black">
       <Helmet>
        <meta
          name="keywords"
          content="Working capital analytics, accounts receivable, inventory management, accounts payable"
        />
        <script type="application/ld+json">
        {JSON.stringify(SchemaWorkingCapital)}
       </script>
         </Helmet>

      <div>
        <Indicusstomersuccessstories data = {storyData} />
      </div>

      {/* <Resources/> */}
      <Footer />

    </div>
  );
}

export default Working_Capital_Analytics;
