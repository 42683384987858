import React from 'react'
import  { useState } from "react";
import { useMediaQuery } from "@mui/material";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import "react-multi-carousel/lib/styles.css";
import supplyImg1 from "../../assets/img/Supply Chain.png";
import supplyImg2 from "../../assets/img/inventory-case-studies-page-new.png";
import supplyImg4 from "../../assets/img/Innovation Analytics.png";
 import RightArrow from "../../pageLayout/CustomerSuccessStoryLayout/RightArrow";
 import LeftArrow from "../../pageLayout/CustomerSuccessStoryLayout/LeftArrow";
 import Futureai from "../../assets/img/FutureAI-driven.png"
import Stratgeties from "../../assets/img/AI-First-Strategies.png";
import dwarika from "../../assets/img/Blogs-Dwarika.png"
;





const data = [
    {
        id: 1,
        title1: "AI-First Strategies ",
        //  title2: " Analytics",
        content: "Aays at CDO Vision NYC 2025: Key Takeaways on AI-First Strategies for Enterprises",
        class: "content-box-section2 inventrynew",
        url: "/blogs/ai-first-strategy-enterprise-transformation-cdo-vision-2025",
        image:  Stratgeties,
      },
    {
        id: 2,
        title1:"Decision Intelligence",
        content: "The Future of AI-Powered Decision Intelligence: Insights from Industry Leaders",
        class: "content-box-section2 inventrynew",
        url: "/blogs/ai-powered-decision-intelligence",
        image: Futureai ,
      },
    {
        id: 3,
        title1: "Generative AI ",
        // title2: "Management",
        content:"Generative AI in Finance – Dwarika Patro Discusses AaDi’s Impact on Analytics and Decision-Making",
        class: "content-box-section2",
        url: "https://blog.aaysanalytics.com/post/generative-ai-in-finance-analytics-dwarika-patro-and-aim-research",
        image: dwarika,
      },
      
    
  ];

const AvinashSection= () => {
    const [startIndex, setStartIndex] = useState(0);

    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });
  
    const showLength = isIpad ? 2 : 3;
    const visibleItems = isIpad ? data.slice(startIndex, startIndex + 2) : data.slice(startIndex, startIndex + 3);
  
    return (
      <div
        id="section2-customer-success-story"
        className="customer-success-section2 scroll-section"
        style={{position: 'relative',backgroundColor:'white'}}
      >
        <div className="customer-section2-body margin-box">
          <h2 className="success-story-page-section-2-Header-text" style={{color:'black'}}>
         Our Recent Posts 
          </h2>
         
          
          
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {data.length > showLength && <LeftArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
            <div className="success-story-page-section-2-grid">
              {visibleItems.map((item) => (
                <div>
                  <CustomerSectionBox
                    picture={item.image}
                    title1={item.title1}
                    title2={item.title2}
                    content={item?.content}
                    class={item.class}
                    redirectURL={item.url}
                  />
                </div>
              ))}
            </div>
            {data.length > showLength && <RightArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
          </div>
        </div>
      </div>
    );
  }
  

export default AvinashSection