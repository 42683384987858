import React from "react";
import Navbar from "../nav/NavBar";
import Headerimg from "../assets/img/privacy-policy-banner-right.png";
import Footer from "../nav/Footer";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import { useMediaQuery } from "@mui/material";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import { SchemaPrivacyPolicy } from "./BlogSchema";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  return (
    <div
      style={{ background: "black" }}
      id="homePage"
      className="privacyPolicy"
    >
        <Helmet>
          <script type="application/ld+json">
        {JSON.stringify(SchemaPrivacyPolicy)}
            </script>
</Helmet>
      <Navbar />
      <GoogleAnalytics/>

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Privacy"
          text2="Policy"
          picture={Headerimg}
        />
      ) : isIpad ? (
        <IpadHeaderSection text1="Privacy" text2="Policy" picture={Headerimg} />
      ) : (
        <NewHeaderSection text1="Privacy" text2="Policy" picture={Headerimg} />
      )}

      <MiddleBanner />

      <div style={{ color: "white" }}>
        <div style={{ margin: "auto", width: "80%", display: "block" }}>
          <div style={{ display: "grid" }}>
            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              className="article"
            >
              <h3
                style={{
                  paddingTop: "2rem",
                }}
                className="article__title"
              >
                Introduction
              </h3>

              <p className="article__desc">
                This privacy notice provides you with details of how we collect
                and process your personal data through your use of our site{" "}
                <strong>aaysanalytics.com</strong>, including any information
                you may provide through our site when you purchase a product or
                service, sign up for our newsletter, or take part in a prize
                draw or competition.
              </p>

              <p>
                By providing us with your data, you warrant to us that you are
                over 13 years of age.
              </p>

              <p>
                <strong>Aays Advisory</strong> is the data controller, and we
                are responsible for your personal data (referred to as “we,”
                “us,” or “our” in this privacy notice).
              </p>

              <p>
                <strong>Contact Details</strong>
              </p>

              <p>Our full details are:</p>

              <p>
                Full name of the legal entity:{" "}
                <strong>Aays Advisory Private Limited.</strong>
              </p>

              <p>
                Email address:{" "}
                <a
                  style={{ display: "inline" }}
                  href="mailto:admin@aaysanalytics.com"
                >
                  click here
                </a>
              </p>

              <p>
                Postal address:{" "}
                <strong>
                  D.No: 1-90/7/b/38 partrika nagar madhapur hyderabad Hyderabad
                  TG 500018 IN
                </strong>
              </p>

              <p>
                It is very important that the information we hold about you is
                accurate and up to date. Please let us know if at any time your
                personal information changes by emailing us at{" "}
                <span>
                  <a
                    style={{ display: "inline" }}
                    href="mailto:admin@aaysanalytics.com"
                  >
                    click here
                  </a>
                </span>
              </p>
            </article>

            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              className="article"
            >
              <h3 className="article__title">
                {" "}
                What Data do we collect about you
              </h3>
              <p className="article__desc">
                <p>
                  Personal data means any information capable of identifying an
                  individual. It does not include anonymized data.
                </p>
                <p>
                  We may process certain types of personal data about you as
                  follows:
                </p>

                <ul>
                  <li>
                    <strong>Identity Data</strong> may include your first name,
                    maiden name, last name, username, marital status, title,
                    date of birth and gender.
                  </li>
                  <li>
                    <strong>Contact Data</strong> may include your billing
                    address, delivery address, email address and telephone
                    numbers.
                  </li>
                  <li>
                    <strong>Financial Data</strong> may include your bank
                    account and payment card details.
                  </li>
                  <li>
                    <strong>Transaction Data</strong> may include details about
                    payments between us and other details of purchases made by
                    you.
                  </li>
                  <li>
                    <strong>Technical Data</strong> may include your login data,
                    internet protocol addresses, browser type and version,
                    browser plug-in types and versions, time zone setting and
                    location, operating system and platform and other technology
                    on the devices you use to access this site.
                  </li>
                  <li>
                    <strong>Profile Data </strong>may include your username and
                    password, purchases or orders, your interests, preferences,
                    feedback and survey responses.
                  </li>
                  <li>
                    <strong>Usage Data </strong>may include information about
                    how you use our website, products and services.
                  </li>
                  <li>
                    {" "}
                    <strong>Marketing and Communications Data </strong>may
                    include your preferences in receiving marketing
                    communications from us and our third parties and your
                    communication preferences.
                  </li>
                </ul>

                <p>
                  We may also process Aggregated Data from your personal data,
                  but this data does not reveal your identity and as such is not
                  personal data. An example of this is where we review your
                  Usage Data to work out the percentage of website users using a
                  specific feature of our site. If we link the Aggregated Data
                  with your personal data so that you can be identified from it,
                  then it is treated as personal data.
                </p>

                <p>
                  <strong>Sensitive Data</strong>
                </p>

                <p>
                  We do not collect any Sensitive Data about you. Sensitive data
                  refers to data that includes details about your race or
                  ethnicity, religious or philosophical beliefs, sex life,
                  sexual orientation, political opinions, trade union
                  membership, information about your health and genetic and
                  biometric data. We do not collect any information about
                  criminal convictions and offences.
                </p>
                <p>
                  Where we are required to collect personal data by law, or
                  under the terms of the contract between us and you do not
                  provide us with that data when requested, we may not be able
                  to perform the contract (for example, to deliver goods or
                  services to you). If you don’t provide us with the requested
                  data, we may have to cancel a product or service you have
                  ordered but if we do, we will notify you at the time.
                </p>
              </p>
            </article>

            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              class="article"
            >
              <h3 class="article__title">
                {" "}
                How We Collect your personal data{" "}
              </h3>
              <p class="article__desc">
                <p>
                  We collect data about you through a variety of different
                  methods including:
                </p>
                <ul>
                  <li>
                    <strong>Direct interactions:</strong> You may provide data
                    by filling in forms on our site (or otherwise) or by
                    communicating with us by post, phone, email or otherwise,
                    including when you:
                  </li>
                  <li>order our products or services;</li>
                  <li>create an account on our site;</li>
                  <li>subscribe to our service or publications;</li>
                  <li>request resources or marketing be sent to you;</li>
                  <li>
                    enter a competition, prize draw, promotion or survey; or
                  </li>
                  <li>give us feedback.</li>
                  <li>
                    <strong>Automated technologies or interactions: </strong>As
                    you use our site, we may automatically collect Technical
                    Data about your equipment, browsing actions and usage
                    patterns. We collect this data by using cookies, server logs
                    and similar technologies. We may also receive Technical Data
                    about you if you visit other websites that use our cookies.
                    Please see our Cookie Policy for further details.
                  </li>
                  <li>
                    <strong>
                      Third parties or publicly available sources:
                    </strong>{" "}
                    We may receive personal data about you from various third
                    parties and public sources as set out below:
                  </li>
                </ul>

                <ul>
                  <li>
                    analytics providers such as Google based outside the EU;
                  </li>
                  <li>
                    advertising networks based inside OR outside the EU and
                  </li>
                  <li>
                    search information providers based inside OR outside the EU.
                  </li>
                </ul>
                <ul>
                  <li>
                    Contact, Financial and Transaction Data from providers of
                    technical, payment and delivery services based inside OR
                    outside the EU.
                  </li>
                  <li>
                    Identity and Contact Data from data brokers or aggregators
                    based inside OR outside the EU.
                  </li>
                  <li>
                    Identity and Contact Data from publicly availably sources
                    such as Companies House and the Electoral Register based
                    inside the EU.
                  </li>
                </ul>
              </p>
            </article>

            <article
              style={{
                marginBottom: "50px",
                display: "grid",
                width: "100%",
                gridGap: "10px",
              }}
              class="article"
            >
              <h3 class="article__title"> How we use your Personal data </h3>

              <p class="article__desc">
                <p>
                  We will only use your personal data when legally permitted.
                  The most common uses of your personal data are:
                </p>
                <ul>
                  <li>Where we need to perform the contract between us.</li>
                  <li>
                    Where it is necessary for our legitimate interests (or those
                    of a third party) and your interests and fundamental rights
                    do not override those interests.
                  </li>
                  <li>
                    Where we need to comply with a legal or regulatory
                    obligation.
                  </li>
                </ul>
                <p>
                  Generally, we do not rely on consent as a legal ground for
                  processing your personal data, other than in relation to
                  sending marketing communications to you via email or text
                  message. You have the right to withdraw consent to marketing
                  at any time by emailing us at{" "}
                  <span>
                    <a href="mailto:admin@aaysanalytics.com">click here</a>
                  </span>
                </p>
                <p>
                  <strong>Purposes for processing your personal data</strong>
                </p>
                <p>
                  Set out below is a description of the ways we intend to use
                  your personal data and the legal grounds on which we will
                  process such data. We have also explained what our legitimate
                  interests are where relevant.
                </p>
                <p>
                  We may process your personal data for more than one lawful
                  ground, depending on the specific purpose for which we are
                  using your data. Please email us at{" "}
                  <a href="mailto:admin@aaysanalytics.com">click here</a> if you
                  need details about the specific legal ground we are relying on
                  to process your personal data where more than one ground has
                  been set out in the table below.
                </p>
                <table class="hideForMObiletable">
                  <tbody>
                    <tr>
                      <td width="227">
                        <strong>Purpose/Activity</strong>
                        <strong> </strong>
                      </td>

                      <td width="172">
                        <strong>Type of data</strong>
                        <strong> </strong>
                      </td>

                      <td width="275">
                        <strong>Lawful basis for processing </strong>
                        <strong> </strong>
                      </td>
                    </tr>
                    <tr>
                      <td width="227">To register you as a new customer</td>
                      <td width="172">(a) Identity(b) Contact</td>
                      <td width="275">Performance of a contract with you</td>
                    </tr>
                    <tr>
                      <td width="227">
                        To process and deliver your order including:
                        <p>(a) Manage payments, fees and charges</p>
                        <p>(b) Collect and recover money owed to us</p>
                      </td>
                      <td width="172">
                        (a) Identity
                        <p>(b) Contact</p>
                        <p>(c) Financial</p>
                        <p>(d) Transaction</p>
                        <p>(e) Marketing and Communications</p>
                      </td>
                      <td width="275">
                        (a) Performance of a contract with you
                        <p>
                          (b) Necessary for our legitimate interests to recover
                          debts owed to us
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="227">
                        To manage our relationship with you which will include:
                        <p>
                          (a) Notifying you about changes to our terms or
                          privacy policy
                        </p>
                        <p>(b) Asking you to leave a review or take a survey</p>
                      </td>
                      <td width="172">
                        (a) Identity
                        <p>(b) Contact</p>
                        <p>(c) Profile</p>
                        <p>(d) Marketing and Communications</p>
                      </td>
                      <td width="275">
                        (a) Performance of a contract with you
                        <p>
                          <p>(b) Necessary to comply with a legal obligation</p>
                          <p>
                            (c) Necessary for our legitimate interests to keep
                            our records updated and to study how customers use
                            our products/services
                          </p>
                        </p>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td width="227">
                        To enable you to partake in a prize draw, competition or
                        complete a survey<p></p>&nbsp;
                      </td>
                      <td width="172">
                        (a) Identity
                        <p>
                          <p>(b) Contact</p>
                          <p>(c) Profile</p>
                          <p>(d) Usage</p>
                          <p>(e) Marketing and Communications</p>
                        </p>
                        &nbsp;
                      </td>
                      <td width="275">
                        (a) Performance of a contract with you
                        <p>
                          <p>
                            (b) Necessary for our legitimate interests to study
                            how customers use our products/services, to develop
                            them and grow our business
                          </p>
                        </p>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td width="227">
                        To administer and protect our business and our site
                        (including troubleshooting, data analysis, testing,
                        system maintenance, support, reporting and hosting of
                        data)<p></p>&nbsp;
                      </td>
                      <td width="172">
                        (a) Identity
                        <p>
                          <p>(b) Contact</p>
                          <p>(c) Technical</p>
                        </p>
                        &nbsp;
                      </td>
                      <td width="275">
                        (a) Necessary for our legitimate interests for running
                        our business, provision of administration and IT
                        services, network security, to prevent fraud and in the
                        context of a business reorganisation or group
                        restructuring exercise
                        <p>
                          <p>(b) Necessary to comply with a legal obligation</p>
                        </p>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td width="227">
                        To deliver relevant content and advertisements to you
                        and measure and understand the effectiveness of our
                        advertising<p></p>&nbsp;
                      </td>
                      <td width="172">
                        (a) Identity
                        <p>
                          <p>(b) Contact</p>
                          <p>(c) Profile</p>
                          <p>(d) Usage</p>
                          <p>(e) Marketing and Communications</p>
                          <p>(f) Technical</p>
                        </p>
                        &nbsp;
                      </td>
                      <td width="275">
                        Necessary for our legitimate interests to study how
                        customers use our products/services, to develop them, to
                        grow our business and to inform our marketing strategy
                        <p></p>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td width="227">
                        To use data analytics to improve our website,
                        products/services, marketing, customer relationships and
                        experiences<p></p>&nbsp;
                      </td>
                      <td width="172">
                        (a) Technical
                        <p>
                          <p>(b) Usage</p>
                        </p>
                        &nbsp;
                      </td>
                      <td width="275">
                        Necessary for our legitimate interests to define types
                        of customers for our products and services, to keep our
                        site updated and relevant, to develop our business and
                        to inform our marketing strategy<p></p>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td width="227">
                        To make suggestions and recommendations to you about
                        goods or services that may be of interest to you<p></p>
                        &nbsp;
                      </td>
                      <td width="172">
                        (a) Identity
                        <p>
                          <p>(b) Contact</p>
                          <p>(c) Technical</p>
                          <p>(d) Usage</p>
                          <p>(e) Profile</p>
                        </p>
                        &nbsp;
                      </td>
                      <td width="275">
                        Necessary for our legitimate interests to develop our
                        products/services and grow our business<p></p>&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <strong>Marketing communications </strong>
                </p>
                <p>
                  You will receive marketing communications from us if you have:
                </p>
                <ul>
                  <li>
                    requested information from us or purchased goods or services
                    from us; or
                  </li>
                  <li>
                    if you provided us with your details when you entered a
                    competition or registered for a promotion or free resources;
                    and
                  </li>
                  <li>
                    in each case, you have not opted out of receiving that
                    marketing.
                  </li>
                </ul>
                <p>
                  We will get your express opt-in consent before we share your
                  personal data with any third party for marketing purposes.
                </p>
                <p>
                  You can ask us or third parties to stop sending you marketing
                  messages at any time by emailing us at{" "}
                  <span>
                    <a
                      style={{ display: "inline" }}
                      href="mailto:admin@aaysanalytics.com"
                    >
                      click here
                    </a>
                  </span>{" "}
                  at any time.
                </p>
                <p>
                  Where you opt out of receiving our marketing communications,
                  this will not apply to personal data provided to us as a
                  result of a product/service purchase, warranty registration,
                  product/service experience or other transactions.
                </p>
                <p>
                  <strong>Change of purpose</strong>
                </p>
                <p>
                  We will only use your personal data for the purposes for which
                  we collected it, unless we reasonably consider that we need to
                  use it for another reason and that reason is compatible with
                  the original purpose. If you wish to find out more about how
                  the processing for the new purpose is compatible with the
                  original purpose, please email us at{" "}
                  <span>
                    <a href="mailto:admin@aaysanalytics.com">click here</a>
                  </span>
                </p>
                <p>
                  If we need to use your personal data for a purpose unrelated
                  to the purpose for which we collected the data, we will notify
                  you and we will explain the legal ground of processing.
                </p>
                <p>
                  We may process your personal data without your knowledge or
                  consent where this is required or permitted by law.
                </p>
              </p>
            </article>

            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              class="article"
            >
              <h3 class="article__title"> Disclosure of your personal data</h3>

              <p class="article__desc">
                <p>
                  We may have to share your personal data with the parties set
                  out below for the purposes set out in the table in paragraph 4
                  above:
                </p>
                <ul>
                  <li>
                    Service providers who provide IT and system administration
                    services.
                  </li>
                  <li>
                    Professional advisers including lawyers, bankers, auditors
                    and insurers who provide consultancy, banking, legal,
                    insurance and accounting services.
                  </li>
                  <li>
                    HM Revenue &amp; Customs, regulators and other authorities
                    based in the United Kingdom and other relevant jurisdictions
                    who require reporting of processing activities in certain
                    circumstances.
                  </li>
                  <li>
                    Third parties to whom we sell, transfer, or merge parts of
                    our business or our assets.
                  </li>
                </ul>
                <p>
                  We require all third parties to whom we transfer your data to
                  respect the security of your personal data and to treat it in
                  accordance with the law. We only allow such third parties to
                  process your personal data for specified purposes and in
                  accordance with our instructions.
                </p>
              </p>
            </article>

            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              class="article"
            >
              <h3 class="article__title">International transfers</h3>
              <p class="article__desc">
                <p>
                  We do not transfer your personal data outside the European
                  Economic Area (EEA).
                </p>
                <p>
                  Countries outside of the European Economic Area (EEA) do not
                  always offer the same levels of protection to your personal
                  data, so European law has prohibited transfers of personal
                  data outside of the EEA unless the transfer meets certain
                  criteria.
                </p>
                <p>
                  Many of our third parties service providers are based outside
                  the European Economic Area (EEA) so their processing of your
                  personal data will involve a transfer of data outside the EEA.
                </p>
                <p>
                  Whenever we transfer your personal data out of the EEA, we do
                  our best to ensure a similar degree of security of data by
                  ensuring at least one of the following safeguards is
                  implemented:
                </p>
                <ul>
                  <li>
                    We will only transfer your personal data to countries that
                    have been deemed to provide an adequate level of protection
                    for personal data by the European Commission; or
                  </li>
                  <li>
                    Where we use certain service providers, we may use specific
                    contracts or codes of conduct or certification mechanisms
                    approved by the European Commission which give personal data
                    the same protection it has in Europe; or
                  </li>
                  <li>
                    Where we use providers based in the United States, we may
                    transfer data to them if they are part of the EU-US Privacy
                    Shield which requires them to provide similar protection to
                    personal data shared between the Europe and the US.
                  </li>
                </ul>
                <p>
                  If none of the above safeguards is available, we may request
                  your explicit consent to the specific transfer. You will have
                  the right to withdraw this consent at any time.
                </p>
                <p>
                  Please email us at{" "}
                  <span>
                    <a
                      style={{ display: "inline" }}
                      href="mailto:admin@aaysanalytics.com"
                    >
                      click here
                    </a>
                  </span>{" "}
                  if you want further information on the specific mechanism used
                  by us when transferring your personal data out of the EEA.
                </p>
              </p>
            </article>

            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              class="article"
            >
              <h3 class="article__title">Data security</h3>

              <p class="article__desc">
                <p>
                  We have put in place appropriate security measures to prevent
                  your personal data from being accidentally lost, used or
                  accessed in an unauthorised way, altered or disclosed. In
                  addition, we limit access to your personal data to those
                  employees, agents, contractors and other third parties who
                  have a business need to know such data. They will only process
                  your personal data on our instructions and they are subject to
                  a duty of confidentiality.
                </p>
                <p>
                  We have put in place procedures to deal with any suspected
                  personal data breach and will notify you and any applicable
                  regulator of a breach where we are legally required to do so.
                </p>
              </p>
            </article>

            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              class="article"
            >
              <h3 class="article__title">Data retention</h3>
              <p class="article__desc">
                <p>
                  We will only retain your personal data for as long as
                  necessary to fulfil the purposes we collected it for,
                  including for the purposes of satisfying any legal,
                  accounting, or reporting requirements.
                </p>
                <p>
                  To determine the appropriate retention period for personal
                  data, we consider the amount, nature, and sensitivity of the
                  personal data, the potential risk of harm from unauthorised
                  use or disclosure of your personal data, the purposes for
                  which we process your personal data and whether we can achieve
                  those purposes through other means, and the applicable legal
                  requirements.
                </p>
                <p>
                  By law we have to keep basic information about our customers
                  (including Contact, Identity, Financial and Transaction Data)
                  for six years after they cease being customers for tax
                  purposes.
                </p>
                <p>
                  In some circumstances you can ask us to delete your data: see
                  below for further information.
                </p>
                <p>
                  In some circumstances we may anonymise your personal data (so
                  that it can no longer be associated with you) for research or
                  statistical purposes in which case we may use this information
                  indefinitely without further notice to you.
                </p>
              </p>
            </article>

            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              class="article"
            >
              <h3 class="article__title">Your legal right</h3>

              <p class="article__desc">
                <p>
                  Under certain circumstances, you have rights under data
                  protection laws in relation to your personal data. These
                  include the right to:
                </p>
                <ul>
                  <li>Request access to your personal data.</li>
                  <li>Request correction of your personal data.</li>
                  <li>Request erasure of your personal data.</li>
                  <li>Object to processing of your personal data.</li>
                  <li>Request restriction of processing your personal data.</li>
                  <li>Request transfer of your personal data.</li>
                  <li>Right to withdraw consent.</li>
                </ul>

                <p>
                  If you wish to exercise any of the rights set out above,
                  please email us at{" "}
                  <span>
                    <a href="mailto:admin@aaysanalytics.com">click here</a>
                  </span>
                </p>
                <p>
                  You will not have to pay a fee to access your personal data
                  (or to exercise any of the other rights). However, we may
                  charge a reasonable fee if your request is clearly unfounded,
                  repetitive or excessive. Alternatively, we may refuse to
                  comply with your request in these circumstances.
                </p>
                <p>
                  We may need to request specific information from you to help
                  us confirm your identity and ensure your right to access your
                  personal data (or to exercise any of your other rights). This
                  is a security measure to ensure that personal data is not
                  disclosed to any person who has no right to receive it. We may
                  also contact you to ask you for further information in
                  relation to your request to speed up our response.
                </p>
                <p>
                  We try to respond to all legitimate requests within one month.
                  Occasionally it may take us longer than a month if your
                  request is particularly complex or you have made a number of
                  requests. In this case, we will notify you and keep you
                  updated.
                </p>
              </p>
            </article>

            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              class="article"
            >
              <h3 class="article__title">Third-party Links</h3>
              <p class="article__desc">
                <p>
                  This website may include links to third-party websites,
                  plug-ins and applications. Clicking on those links or enabling
                  those connections may allow third parties to collect or share
                  data about you. We do not control these third-party websites
                  and are not responsible for their privacy statements. When you
                  leave our website, we encourage you to read the privacy notice
                  of every website you visit.
                </p>
              </p>
            </article>

            <article
              style={{ marginBottom: "50px", display: "grid", gridGap: "10px" }}
              class="article"
            >
              <h3 class="article__title">Cookies</h3>
              <p class="article__desc">
                <p>
                  You can set your browser to refuse all or some browser
                  cookies, or to alert you when websites set or access cookies.
                  If you disable or refuse cookies, please note that some parts
                  of this website may become inaccessible or not function
                  properly. For more information about the cookies we use,
                  please see our website <strong>aaysanalytics.com</strong>
                </p>
              </p>
            </article>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
