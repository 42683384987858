import React, { useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import "react-multi-carousel/lib/styles.css";
import company4 from "../../assets/img/Seasoned-vendor.webp";
import dataEngineering from "../../assets/img/Pema-Quadrant.png";
import { useMediaQuery, useTheme } from "@mui/material/";
import Singapore from "../../assets/img/We-are-in-singapore.webp";
import Microsoftsolution from "../../assets/img/Microsoft-solutions.webp"
import BlogsFinancialtimess from "../../assets/img/BlogsFinancialtimess.webp";
import blog from "../../assets/img/Best-firms-for-data-scientists.webp"
import Stratgeties from "../../assets/img/AI-First-Strategies.png";
import RightArrow from "../CustomerSuccessStoryLayout/RightArrow";
import LeftArrow from "../CustomerSuccessStoryLayout/LeftArrow";

const items = [
  {
    id: 1,
    content:
      "Aays at CDO Vision NYC 2025: Key Takeaways on AI-First Strategies for Enterprises",
    class: "content-box-lifeaa",
    image: Stratgeties,
    date: "Feb 14, 2025",
    read :'2 min read',
    url: "/blogs/ai-first-strategy-enterprise-transformation-cdo-vision-2025",
  },
  {
    id: 2,
    content:
    "Aays Listed in 500 High Growth Companies Asia Pacific 2024 by Financial Times and Statista",
  class: "content-box-lifeaa",
  image: BlogsFinancialtimess,
  date: "July 4, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/aays-high-growth-companies-asia-pacific-2024",
  },
  {
    id: 3,
    content:
    "Aays Recognized as one of the Leaders in Data Engineering PeMa Quadrant",
  class: "content-box-lifeaa",
  image: dataEngineering,
  date: "May 27, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/aim-ranks-aays-a-leader-in-data-engineering-service-providers",
  },
  {
    id: 4,
    content:
    "Recognised as a Workspace Leaders in the List of 50 Best Firms for Data Scientists to Work for",
  class: "content-box-lifeaa",
  image: blog,
  date: "May 26, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/50-best-firms-for-data-scientists-2024",
  },
  {
    id: 5,
    content:
    "Aays Recognized as 'Seasoned Vendor' in Gen AI Report by AIM Research",
  class: "content-box-lifeaa",
  image:company4,
  date: "Aug 7, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/top-gen-ai-service-providers-aim-report-2023",
  },
  {
    id: 6,
    content:
    "Renewing our partnership with Microsoft: From Gold to Azure Solutions Partner",
  class: "content-box-lifeaa",
  image:Microsoftsolution,
  date: "Aug 6, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/microsoft-solutions-partner-azure"
  },
  {
    id: 7,
    content:
    "Aays Analytics forays into APAC region; kick-starts operations in Singapore",
  class: "content-box-lifeaa",
  image:Singapore,
  date: "Sep 19, 2022",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/aays-in-singapore",
  },
 
]


function Section5() {
 
  const [startIndex, setStartIndex] = useState(0);

  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const showLength = isIpad ? 2 : 3;
  const visibleItems = isIpad
    ? items.slice(startIndex, startIndex + 2)
    : items.slice(startIndex, startIndex + 3);
  

  return (
    <div  id="section5-blog-success-story" className="customer-success-section3 content-box-lifeaays section scroll-section">      {/* customer-success-section3 */}
      <div className="customer-section3-body">
        <div className="success-story-page-section-3-Header-text">
        {/* id="caseStudySection3"  */}
         Company Updates
        </div>
       
        <div className="success-story-page-section-3-grid">
          {items.length > showLength && (
            <LeftArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
          {visibleItems.map((item) => (
            <div key={item.id}>
              <CustomerSectionBox
                picture={item.image}
                date={item.date}
                read={item.read}
                title1={item.title1}
                title2={item.title2}
                content={item?.content}
                class={item.class}
                redirectURL={item.url}
              />
            </div>
          ))}
          {items.length > showLength && (
            <RightArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Section5;
