import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import tools4 from "../../assets/img/Best-firms-for-data-scientists.webp";
import AmitGrnle from "../../assets/img/AmitGrnle.png";
import DepkPrjapti from "../../assets/img/DepkPrjapti.png";
import DvshPal from "../../assets/img/DvshPal.png";
import DpRoy from "../../assets/img/DpRoy.webp";
import RhulDesai from "../../assets/img/RhulDesai.webp";
import Surbhjain from "../../assets/img/Surbhjain.png";
import GrtPlcetoWork from "../../assets/img/GrtPlcetoWork.webp";  
import Lavanya from "../../assets/img/Lavanya-data.png";
import Tanishka from "../../assets/img/Tanishka-Shah.webp";
import Sarthak from "../../assets/img/Sarthak.png"
import  Bhmit from "../../assets/img/BumtSh.png"
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import RightArrow from "../CustomerSuccessStoryLayout/RightArrow";
import LeftArrow from "../CustomerSuccessStoryLayout/LeftArrow";
import Sumit from "../../assets/img/SpotlightSumit.png";
import Avinash from "../../assets/img/Spotlight-Avinash.webp"



const items = [
  {
    id: 1,
    // title1: "Inside Aays' Culture: A Senior Data Engineer’s Take on Innovation and Growth",
    content:
      "Building Data Science Teams: A Leadership Perspective with Avinash Sidha",
    class: "content-box-lifeaa",
    image: Avinash ,
    date: "26 Mar 2025",
    read :'2 min read',
    url: "/blogs/building-data-science-teams-avinash-sidha-interview",
  },
  {
    id: 2,
    // title1: "Inside Aays' Culture: A Senior Data Engineer’s Take on Innovation and Growth",
    content:
      "Analytics Consulting and Leadership: Sumit Shekhar on AI, Strategy & Innovation",
    class: "content-box-lifeaa",
    image: Sumit,
    date: "17 Mar 2025",
    read :'2 min read',
    url: "/blogs/analytics-consulting-leadership-ai-sumit-shekhar",
  },
  {
    id: 3,
    // title1: "Inside Aays' Culture: A Senior Data Engineer’s Take on Innovation and Growth",
    content:
      "Inside Aays' Culture: A Senior Data Engineer’s Take on Innovation and Growth",
    class: "content-box-lifeaa",
    image: Sarthak,
    date: "5 Feb 2025",
    read :'2 min read',
    url: "https://blog.aaysanalytics.com/post/aays-culture-innovation-career-growth",
  },
  {
    id: 4,
    content:
    "Data Engineer Skills: Insights from Lavanya’s Journey at Aays",
  class: "content-box-lifeaa",
  image:Lavanya,
  date: "22-Jan-25",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/career-insights-data-engineer-skills",
  },
  {
    id: 5,
    content:
    "Data Analyst Consultant Insights: Career Growth Journey with Tanishka Shah",
  class: "content-box-lifeaa",
  image: Tanishka,
  date: "6-Jan-25",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/data-analyst-consultant-insights-tanishka-shah",
  },
  {
    id: 6,
    content:
    "Building a Thriving Analytics Career: Insights from Bhumit Shah, AVP - Analytics",
  class: "content-box-lifeaa",
  image: Bhmit,
  date: "24-Dec-24",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/analytics-career-bhumit-shah-interview",
  },
  {
    id: 7,
    content:
    "Dip Roy on Building Successful Data Science Careers: Insights, Skills, and Emerging Trends",
  class: "content-box-lifeaa",
  image: DpRoy,
  date: "Dec 18, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/data-science-careers-dip-roy-interview",
  },
  {
    id: 8,
    content:
    "Data Engineering Career Journey: Rahul Desai on Growth, Challenges, and Teamwork",
  class: "content-box-lifeaa",
  image:RhulDesai,
  date: "Nov 25, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/rahul-desai-data-engineering-career-journey",
  },
  {
    id: 9,
    content:
    "Deepak's Data Engineering Career: Insights, Challenges, and Advice for Aspiring Professionals",
  class: "content-box-lifeaa",
  image:DepkPrjapti,
  date: "Nov 21, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/deepak-data-engineering-career-insights",
  },
  {
    id: 10,
    content:
    "Data Analyst Career Insights: Interview with Saurabh Jain, Senior Data Analyst",
  class: "content-box-lifeaa",
  image:Surbhjain,
  date: "Nov 6, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/data-analyst-career-insights-interview-with-saurabh-jain-senior-data-analyst",
  },
  {
    id: 11,
    content:
    "Empowered to Learn and Grow: Amit Girnale's Data Analytics Career Growth at Aays",
  class: "content-box-lifeaa",
  image:AmitGrnle,
  date: "Oct 24, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/amit-girnale-data-analytics-career-growth",
  },
  {
    id: 12,
    content:
    "Devesh Pal's Journey in Data Engineering at Aays",
  class: "content-box-lifeaa",
  image:DvshPal,
  date: "Oct 16, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/data-engineering-at-aays-devesh-pal-interview",
  },
  {
    id: 13,
    content:
    "Aays Recognized as One of the 50 Best Firms for Data Scientists to Work for in 2024",
  class: "content-box-lifeaa",
  image:tools4,
  date: "May 26, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/50-best-firms-for-data-scientists-2024",
  },
  {
    id: 14,
    content:
    "Aays is now Great Place to Work Certified",
  class: "content-box-lifeaa",
  image:GrtPlcetoWork,
  date: "Aug 30, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/aays-great-place-to-work-certified",
  },

];

const LifeAays = () => {
  const [startIndex, setStartIndex] = useState(0);

  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const showLength = isIpad ? 2 : 3;
  const visibleItems = isIpad
    ? items.slice(startIndex, startIndex + 2)
    : items.slice(startIndex, startIndex + 3);
  

  return (
    <div  id="lifeaays" className="customer-success-section3 content-box-lifeaays section scroll-section">      {/* customer-success-section3 */}
      <div className="customer-section3-body">
        <div className="success-story-page-section-3-Header-text">
        {/* id="caseStudySection3"  */}
         Life at Aays
        </div>
       
        <div className="success-story-page-section-3-grid">
          {items.length > showLength && (
            <LeftArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
          {visibleItems.map((item) => (
            <div key={item.id}>
              <CustomerSectionBox
                picture={item.image}
                date={item.date}
                read={item.read}
                title1={item.title1}
                title2={item.title2}
                content={item?.content}
                class={item.class}
                redirectURL={item.url}
              />
            </div>
          ))}
          {items.length > showLength && (
            <RightArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default LifeAays
