import React, { useState, useEffect } from "react";

const Section1 = () => {
  const [activeButton, setActiveButton] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".scroll-section");
      sections.forEach((section) => {
        const sectionId = section.id;
        const button = document.querySelector(`[href="#${sectionId}"]`);
        const sectionTop = section.offsetTop - window.innerHeight * 0.3; // Adjusted to include 80vh
        const sectionHeight = section.clientHeight;
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;

        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveButton(sectionId);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  {/*const handleClick = (e) => {
    e.preventDefault();
    const targetElement= e.currentTarget;
    const targetId = targetElement.getAttibute("href").slice(1);
    const targetSection = document.getElementById(targetId);
    targetSection.scrollIntoView({ behavior: "smooth" });
  }; */}

  const handleClick = (e) => {
    e.preventDefault();
    console.log('Event target:', e.currentTarget);
    const targetElement = e.currentTarget;
    if (targetElement && targetElement.getAttribute) {
      const href = targetElement.getAttribute("href");
      console.log('Href attribute:', href);
      const targetId = href.slice(1);
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      } else {
        console.warn('Target section not found');
      }
    } else {
      console.warn('Target element or getAttribute method is not available');
    }
  };

  return (
    <div className="sticky-buttons">
      <div className="button-box">
        <div className="container">
          <a
            href="#section2-customer-success-story"
            className={
              activeButton === "section2-customer-success-story" ? "active" : ""
            }
            onClick={handleClick}
          >
            <button
              className={
                activeButton === "section2-customer-success-story"
                  ? "first-btn active"
                  : "first-btn"
              }
            >
             Supply Chain
            </button>
          </a>
          <a
            href="#caseStudySection3"
            className={activeButton === "caseStudySection3" ? "active" : ""}
            onClick={handleClick}
          >
            <button
              className={activeButton === "caseStudySection3" ? "active" : ""}
            >
             Commercial
            </button>
          </a>
          <a
            href="#caseStudySection4"
            className={
              activeButton === "caseStudySection4" ? "active" : ""
            }
            onClick={handleClick}
          >
            <button
              className={
                activeButton === "caseStudySection4" ? "active" : ""
              }
            >
             Operations
            </button>
          </a>
       
          <a
            href="#section6a-customer-success-story"
            className={
              activeButton === "section6a-customer-success-story" ? "active" : ""
            }
            onClick={handleClick}
          >
            <button
              className={
                activeButton === "section6a-customer-success-story" ? "active" : ""
              }
            >
              Marketing
            </button>
          </a>
        
        </div>
      </div>
    </div>
  );
};

export default Section1;

