import React, { useEffect, useState } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import linkedin from "../../assets/img/Billionimages/linkedin.png"

import GenAiBanner from "../../assets/img/Aays-Sumit.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import Blgfvcn from "../../assets/img/Blgfvcn.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Acoordion from "./newBLog/Acoordion"
import { freq23 } from "./newBLog/Acdiondatas";
import Newsectionmobile from "../../pageLayout/CustomerSuccessStoryLayout/Newsectionmobile";
import Consulting from "./Consulting";
import Futureai from "../../assets/img/FutureAI-driven.png"
import Stratgeties from "../../assets/img/AI-First-Strategies.png";
import { useMediaQuery } from "@mui/material";
import dwarika from "../../assets/img/Blogs-Dwarika.png"

import SocialShare from "./newBLog/SocialShare";




const SupplyChainDat = [
  {
    id: 1,
    heading:"Decision Intelligence",
    content:
      "The Future of AI-Powered Decision Intelligence: Insights from Industry Leaders",
    redirectURL:
      "/blogs/ai-powered-decision-intelligence",
    image:  Futureai,
  },
  {
    id: 2,
   // heading: "Inventory Analytics",
    content:
      "Generative AI in Finance – Dwarika Patro Discusses AaDi’s Impact on Analytics and Decision-Making",
    redirectURL: "https://blog.aaysanalytics.com/post/generative-ai-in-finance-analytics-dwarika-patro-and-aim-research",
    image: dwarika,
  },
  {
    id: 3,
    //heading: "Innovation Analytics ",
    content:
      "Aays at CDO Vision NYC 2025: Key Takeaways on AI-First Strategies for Enterprises",
    redirectURL: "/blogs/ai-first-strategy-enterprise-transformation-cdo-vision-2025",
    image:Stratgeties,
  },
]

function SumitConsulting({ linkedn, twitter, facebook }) {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });
    const hardcodedUrl = "https://www.aaysanalytics.com/blogs/analytics-consulting-leadership-ai-sumit-shekhar";
const encodedUrl = encodeURIComponent(hardcodedUrl);
  
  
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };
  

  
  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];

  return (
    <div id="homePage" >
      <Helmet>
        <meta
          name="keywords"
          content="Power BI Beginner Power BI Best Practices"
        />
      </Helmet>

      <Navbar bg="black" />
      <GoogleAnalytics />

      <div className="GenAi-body newclas">
        <div className="margin-blogMore">
          <div className="GenAi-main">
            <div className="border-genai">
              <div className="new-box-seventy">
                <div className="back-button" style={{ paddingTop: "2rem" }}>
                  {data.map((ele, index) => (
                    <div className="back" key={index}>
                      <a href={ele.url} className="content" key={index} style={{ color: 'black' }}>
                        {ele.text}
                      </a>
                      {index !== data.length - 1 && (
                        <div style={{ color: "black" }}>/</div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flexteamaays" style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                  <div>
                    <img src={Blgfvcn} alt="aays" className="imagi" />
                  </div>
                  <div style={{ fontWeight: '500' }}>
                    <span className="cntheading" style={{ marginRight: '6px' }}>Team Aays</span>
                    <span className="cntheading sixjan" style={{ marginRight: '6px' }} >.</span>
                    <span className="cntheading sixjan" >March 17</span>

                  </div>
                </div>
                <h1 className="main-heading fontweight mainheaders">
                  {" "}
                  Analytics Consulting and Leadership: Sumit Shekhar on AI, Strategy & Innovation                 </h1>

                <img
                  className="blogImg"
                  // style={{ filter: "grayscale(1)" }}
                  src={GenAiBanner}
                  alt="blog1"
                />

                <div className="imgContent">
                    {/* <p className="heading-content cntheading" style={{fontStyle:'italic'}}>
                         

"Understand that analytics is not just about the tech—it is about business impact. AI, data science, and engineering are must-have skills, but true leadership comes from being able to bridge the gap between data and business value." – Sumit Shekhar 
                    </p> */}
                    <div style={{borderLeft:'4px solid rgb(30,179,200)'}}>
                      <p  className="heading-italic">
                          

                         Understand that analytics is not just about the tech—it is about business impact. AI, data science, and engineering are must-have skills, but true leadership comes from being able to bridge the gap between data and business value – Sumit Shekhar 
                                             </p></div>
                     
                  <p className="italic-para" style={{marginTop:'2rem'}}>

                  The field of analytics consulting is evolving rapidly, with AI playing a crucial role in business strategy and <a href="https://www.aaysanalytics.com/blogs/ai-powered-decision-intelligence"   target="_blank" 
  rel="noopener noreferrer" className="anchor">decision intelligence</a>. Leaders in this space must not only understand data but also translate insights into business impact. 
                  </p>

                  <p className="italic-para">

                  Sumit Shekhar, AVP - Analytics at Aays, shares his journey, leadership philosophy, and thoughts on how AI is reshaping analytics consulting. From mentoring teams to implementing Agentic AI, Sumit dives deep into what it takes to lead in AI and consulting.                   </p>




                  <h2 className="heading headings-headingss padingtop" > Journey into Analytics Consulting 
                  </h2>


                  <h4 className="heading-four fontweights headingfour">Can you share your journey in analytics and what led you to Aays? </h4>

                  <p className="heading-content cntheading">
                  Sumit: I started my career in business, where I was responsible for making key decisions. Over time, I realized how much more impact I could create by leveraging data-driven decision-making rather than relying solely on intuition or my experience. This realization led me to transition into analytics consulting—where I could learn how data, analytics, and AI can drive real business value. This passion for data-led decision-making eventually brought me to Aays.                   </p>
                  <h2 className="heading headings-headingss  padingtop"> Balancing Strategy, Execution & Consulting Leadership 
                  </h2>

                  <h4 className="heading-four fontweights headingfour"> What does your typical day look like? How do you balance strategy, execution, and leadership? 
                  </h4>

                  <p className="heading-content cntheading">
                  Sumit: I strongly believe in empowering people by giving them ownership and responsibility. My day is a mix of shaping strategy, driving execution, and ensuring our analytics consulting initiatives align with business objectives. I follow a democratic leadership style, where I guide and support my team while allowing them the autonomy to deliver.  The goal is always to create an environment where people take charge, innovate, and deliver impact. 
                  </p>
                  <h2 className="heading headings-headingss  padingtop"> Solving Complex Data Challenges in AI & Analytics Consulting 
                  </h2>
                                    <h4 className="heading-four fontweights headingfour"> How do you mentor and guide your teams to solve complex data challenges? </h4>

                  <p className="heading-content cntheading">
 

                  Sumit: I always start with the business problem—because if you don’t understand that, the best technical solution won’t matter. I take a top-down approach: first, define the problem clearly, then set up the right processes, identify the right data, and ensure we track it at the most granular level. That way, the solutions we build are not just technically sound but also actually move the needle for the business. 
                  </p>
                  <h2 className="heading headings-headingss  padingtop"> AI’s Role in Analytics Consulting & Decision Intelligence 
                  </h2>
                                    <h4 className="heading-four fontweights headingfour"> How is AI transforming analytics, and how do you see its role evolving in decision intelligence? 
                  </h4>

                  <p className="heading-content cntheading">
                  Sumit: AI is becoming an integral part of<a href="https://blog.aaysanalytics.com/post/leveraging-ai-and-ml-for-business-decision-making"   target="_blank" 
  rel="noopener noreferrer"  className="anchor"> business decision-making </a>, working hand-in-hand with leaders to enhance decision intelligence. It empowers individuals to take on more complex tasks and accelerates overall company growth. What excites me the most is Agentic AI – it is going to change the way we work. Very soon, a lot of repetitive tasks will be fully automated, allowing businesses to focus on high-value strategic decisions with AI providing intelligent recommendations at every step. 
                  </p>
                  <h2 className="heading headings-headingss  padingtop"> Fostering Innovation in AI & Analytics Consulting 
                  </h2>
                  
                                    <h4 className="heading-four fontweights headingfour">How do you think the culture at Aays support innovation?  </h4>

                  <p className="heading-content cntheading">
                  Sumit: Aays creates an environment where people are trusted to take ownership, experiment, and challenge the status quo. The company encourages risk-taking and innovation and provides freedom to experiment with AI & analytics. We have some of the best minds in the industry, and the company gives them the space to take calculated risks and drive real change. When you work in an environment where ideas are valued and experimentation is encouraged, innovation happens naturally. 
                  </p>
                  <h2 className="heading headings-headingss  padingtop"> Staying Ahead in Leadership & AI Consulting 
                  </h2>


                  <h4 className="heading-four fontweights headingfour">With the constant evolution of AI and analytics, how do you stay updated and ensure your teams remain ahead of the curve? 
                  </h4>

                  <p className="heading-content cntheading">
                  Sumit: There are three things I focus on. First, I stay closely connected with industry trends in analytics consulting and market developments - industry is moving fast, and keeping up is key. Second, I work closely with clients to implement cutting-edge solutions that drive tangible business value. Lastly, I invest in continuous learning-whether it is through research, knowledge-sharing sessions, or hands-on experimentation-the goal is to stay ahead.  
                  </p>
                  <h2 className="heading headings-headingss  padingtop"> Advice for Aspiring Leaders in AI & Analytics Consulting 
                  </h2>

                                    <h4 className="heading-four fontweights headingfour"> What advice would you give to analytics professionals who aspire to take on leadership roles? </h4>

                  <p className="heading-content cntheading">
                  Sumit: Understand that analytics is not just about the tech—it is about business impact. AI, data science, and engineering are the must skills to have but to proceed to the leadership role, one needs the ability to bridge the gap between data and business value. Learn to tell a story with data, develop strong problem-solving skills, and build relationships with stakeholders. These skills are what differentiate successful consulting leaders. </p>
                  
                  <h2 className="heading headings-headingss  padingtop"> The Future of Analytics Consulting at Aays  
                  </h2>

                  <h4 className="heading-four fontweights headingfour"> What excites you most about the future of analytics at Aays, and what are your key focus areas for the next few years? 
                  </h4>

                  <p className="heading-content cntheading">
                  Sumit: We have built a strong foundation, and now it is about scaling the impact with  latest AI technologies and deliver even greater business value to our clients. Agentic AI workflows will be instrumental in enhancing business profitability, bringing transformative potential to various operations. In the coming years, we are focussing on advancing Generative AI and Agentic AI capabilities specifically for CPG clients in Supply Chain and<a    target="_blank" 
  rel="noopener noreferrer" href="https://blog.aaysanalytics.com/post/generative-ai-in-finance-analytics-dwarika-patro-and-aim-research" className="anchor"> Finance </a> F , integrating AI-driven insights to enhance decision intelligence, and automating repetitive tasks. The potential is huge, and we are just getting started.
                  </p>
                  <div style={{borderTop:'1px solid rgb(221, 221, 221)',borderBottom:'1px solid rgb(221, 221, 221)',marginTop:'2rem',marginBottom:'2rem'}}>
                  <h4 className="heading-four fontweights headingfour" style={{marginTop:'1rem',marginBottom:'1rem'}}>About Sumit Shekhar: </h4>

                  <p className="heading-content cntheading"  style={{marginBottom:'1rem'}}>
                 <a    target="_blank" 
  rel="noopener noreferrer" href="https://www.linkedin.com/in/sumitshekhar2020/?original_referer=https%3A%2F%2Finc-word-edit.officeapps.live.com%2F" className="anchor"> Sumit Shekhar </a>is the AVP - Analytics at Aays, driving digital transformation and AI-driven consulting for CPG clients. With expertise in Generative AI, analytics, and supply chain strategy, he leads innovation in decision intelligence and automation.                   
                  </p>
                  </div>
           

                  <div  style={{paddingTop:'2rem',fontSize:'22px'}}> Frequently Asked Questions</div>
                    <div className="flex-containersi">
          <div className="leftscontainers">
            <div className="accordian-filessi">
              <Acoordion datas={freq23} />
            </div>
          </div>
        </div>
       < SocialShare />
        

      

       



                </div>
              </div>
              
            </div>
          </div>
        </div>

        {isMobile ? (
        
        <div  className="case-studies-pages-cls" style={{ paddingTop: "2.5rem", backgroundColor: "white" }}>
          <Newsectionmobile
            heading=" Our Recent Posts"
            data={SupplyChainDat}
          />
        </div>
      ) : (
        <div className="section" id="caseStudySection2">
          <Consulting />
        </div>
      )} 








        <div>

        </div>

      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default SumitConsulting;
