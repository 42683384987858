import React, { useState } from "react";
import Navbar from "../../nav/NavBar";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../CustomerSuccessStoryMoreLayout/CustomerSuccessStoryMore.scss";
import Alert from "@mui/material/Alert";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import AaysResearh from "../../Pdfs/Aays-Reseach-Assessig.pdf";

const Customerindian = (props) => {
  const [isloading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);

  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };

  const rejectList = ["effem.com"];
  const getURl = new URL(window.location.href);
  const pathValue = getURl.pathname;

  console.log(pathValue, 'pathValue');

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    var URL =
      "https://kixepycste.execute-api.us-east-1.amazonaws.com/prod/contactus";

    var name = $("#name-input_new").val();
    var phone = "No phone number";
    var email = $("#email-input_new").val();
    var desg = $("#designation-input_new").val();
    var message = "";
    var company = $("#company-input_new").val();
    var splitArray = email.split("@");
    if (rejectList.indexOf(splitArray[1]) >= 0) {
      if (
        pathValue ===
        "/blogs/ai-powered-decision-intelligence"
      ) {
        message = "Aays Reseach case study restricted";
      } 
    } else {
      // Remove ".html" extension

      if (
        pathValue ===
        "/blogs/ai-powered-decision-intelligence"
      ) {
        message = "View Aays-AIM Decision Intelligence Research Report 2025";
      } 
    }

    var contactUsData = {
      name: name,
      phone: phone,
      email: email,
      designation: desg,
      message: message,
      company: company,
    };

    $.ajax({
      type: "POST",
      url: URL,
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(contactUsData),

      success: function () {
        // clear form and show a success message
        document.getElementById("landing_formid").reset();
        setisLoading(false);

        if (rejectList.indexOf(splitArray[1]) >= 0) {
          setisError(true);
          setTimeout(() => {
            setisError(false);
          }, 5000);
          return false;
        } else {
          if (
            pathValue ===
            "/blogs/ai-powered-decision-intelligence"
          ) {
            const filename = "Aays_AIM_ResearchReport_Assessing_GenAI_Powered_Decision_Intelligence_Platforms";
            downloadFinanceController(AaysResearh, filename);
          } 
        }
      },
      error: function () {
        setisError(true);
        setisLoading(false);

        setTimeout(() => {
          setisError(false);
        }, 5000);
      },
    });
  };

  const downloadFinanceController = async (file, filename) => {
    console.log(file, filename);
    try {
        const response = await fetch(file);

        console.log(response, 'res');

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        console.log(url, 'url');
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `${filename}_${new Date().getTime()}.pdf`);

        document.body.appendChild(link);
        console.log(link, 'link');
        link.click();
    } catch (error) {
        console.error("Error downloading finance controller:", error);
    }
  };


  

  return (
    <div id="homePage">
      
      <div className="page-width">
        <div className="indi-case-studies" >
          <section style={{ height: "auto" }}>
            <div className="indi-case-studies-section1" style={{paddingTop:'1rem'}}>

              

              <div className="main">
               
              </div>
            </div>
          </section>

          <section className="indi-case-study-section2">
            <div className="section2-main" >
              <div className="section2-body" style={{background:"white"}}>
                <div className="section2-content-main" style={{background:'white',width:'100%'}}>

                 

                  <div className="form-heading" style={{color:'black'}}>
                    View the Complete Research Report
                  </div>

                  <div className="form-data-main">
                    <div
                     className="main"
                    >
                      <div>
                        <form
                          id="landing_formid"
                          className="form-data"
                          onSubmit={handleSubmit}
                        >
                          <div
                            className="form-field"
                          >
                            <div className="form-input-boxes">
                              <div className="input-container-landing">
                                <input
                                  id="name-input_new"
                                  className="input-field-landing"
                                  type="text"
                                  placeholder="Name"
                                  name="name"
                                  autoComplete="off"
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-input-boxes">
                              <div className="input-container-landing">
                                <input
                                  id="email-input_new"
                                  autoComplete="off"
                                  className="input-field-landing"
                                  type="email"
                                  placeholder="Email Address"
                                  name="email"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div
                          className="form-field"
                          >
                            <div className="form-input-boxes">
                              <div className="input-container-landing">
                                <input
                                  id="company-input_new"
                                  autoComplete="off"
                                  className="input-field-landing"
                                  type="text"
                                  placeholder="Company"
                                  name="company"
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-input-boxes">
                              <div className="input-container-landing">
                                <input
                                  id="designation-input_new"
                                  autoComplete="off"
                                  className="input-field-landing"
                                  type="text"
                                  placeholder="Designation"
                                  name="designation"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="btn-read-more"
                          >
                            <button
                              style={{ cursor: "pointer" }}
                              disabled={isloading}
                              type="submit"
                            >
                              {isloading ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span style={{ paddingLeft: "10px" }}>
                                    Downloading...
                                  </span>
                                </>
                              ) : (
                                "Download now"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

               
              </div>
            </div>
          </section>

          {/* <div onClick={nextstep} className="scroll-btn">
            <button className="arrowBtn">
              <KeyboardArrowDownIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </button>
          </div> */}

          {/* <div className="scroll-btn-down" onClick={nextstepDown}>
            <button className="arrowBtn">
              <KeyboardArrowUpIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </button>
          </div> */}

          {isError && (
            <div
              style={{
                position: "fixed",
                width: "auto",
                top: "12%",
                right: "10px",
              }}
            >
              <Alert severity="error">
                Sometime went wrong, Our team will connect you soon
              </Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Customerindian;
