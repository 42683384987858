import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/aiToolForControllers-caseStudy.png";
import { SchemaAitoolController } from "../BlogSchema";


const storyData = {
  title:
    "Equipping finance controllers with an AI-powered tool for faster closure of books and outlier detection",
  content:
    "By incorporating statistical confidence intervals and advanced anomaly detection techniques, the finance controllers of a global CPG company were able to address irregularities, outliers swiftly, resulting in much faster closure of books. The intelligent variance analysis solution helped in overcoming key challenges like time-consuming account closure and the lack of a red flag system at P&L and GL level. This resulted in improved financial decisions, enhanced operational efficiency, and proactive risk management for the organization.",
  subTitle1: "Faster Closure",
  subText1: "Of Books",
  subTitle2: "  AI-Powered",
  subText2: "Outlier Detection",
  imageData: picture
};

function AI_Tool_For_Controllers() {

  useEffect(() => {
    document.title = "AI-Powered Finance Controllership | Aays";
    document.getElementsByTagName("META")[3].content="Explore how Aays' AI-powered tools revolutionized finance controllership, enabling faster book closure, precise anomaly detection and risk management.";
  });

  return (
    <div className="backround-black">
      {/* <Helmet>
        <meta
          name="keywords"
          content="AI-powered finance controllers, outlier detection, anomaly detection, variance analysis"
        />
      </Helmet> */}
      <Helmet>
          <script type="application/ld+json">
        {JSON.stringify(SchemaAitoolController)}
          </script>
            </Helmet>

      <div>
        <Indicusstomersuccessstories data = {storyData} />

      </div>

      {/* <Resources/> */}
      <Footer />

    </div>
  );
}

export default AI_Tool_For_Controllers;
