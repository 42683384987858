import React, { useState, useCallback, useMemo, useRef } from "react";
import "./BlogAnimation.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ParticleBackground from "../../pages/NewHeaderSection/Network";
import robowith from "../../assets/img/AaysparticipationinVision.webp";
import United from "../../assets/img/UnititedDesign.webp";
import plant from "../../assets/img/Geninfinance.webp";
import bottomImage from "../../assets/img/test-animation-grid.webp";

const BlogAnimation = () => {
  const items = useMemo(() => [
    {
      image: robowith,
      text: <>CDO Vision NYC 2025 –<br /> AI-First Strategies & Enterprise Innovation</>,
      buttonLink: "/blogs/ai-first-strategy-enterprise-transformation-cdo-vision-2025",
    },
    {
      image: United,
      text: "Future of AI-Powered Decision Intelligence – Insights from Industry Leaders",
      buttonLink: "/blogs/ai-powered-decision-intelligence",
    },
    {
      image: plant,
      text: "Generative AI in Finance – AaDi’s Impact on Analytics and Decision-Making",
      buttonLink: "https://blog.aaysanalytics.com/post/generative-ai-in-finance-analytics-dwarika-patro-and-aim-research",
    },
  ], []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragStartX, setDragStartX] = useState(null);
  const dragThreshold = 50; // Minimum drag distance to trigger slide change
  const isDragging = useRef(false);

  const prevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev - 1 + items.length) % items.length);
  }, [items.length]);

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev + 1) % items.length);
  }, [items.length]);

  // Handle drag start
  const handleMouseDown = (e) => {
    setDragStartX(e.clientX || e.touches[0].clientX);
    isDragging.current = true;
  };

  // Handle drag move
  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    const currentX = e.clientX || e.touches[0].clientX;
    const diff = currentX - dragStartX;

    if (diff > dragThreshold) {
      prevSlide();
      isDragging.current = false; // Prevent multiple triggers
    } else if (diff < -dragThreshold) {
      nextSlide();
      isDragging.current = false;
    }
  };

  // Handle drag end
  const handleMouseUp = () => {
    isDragging.current = false;
  };

  return (
    <>
      <ParticleBackground />
      <div 
        className="blog-animation-container"
        onMouseDown={handleMouseDown} 
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
      >
        <div className="carousel-wrapper-container">
          {items.map((item, index) => (
            <div key={index} className={`carousel-itemsss ${index === currentSlide ? "active" : ""}`}>
              {/* Left Side - Text */}
              <div className="text-sectiona">
                <h2 className="itemText">{item.text}</h2>
                <button
                  className="read-morebutton"
                  style={{ position: 'relative' }}
                  onClick={() => window.open(item.buttonLink, "_blank")}
                >
                  Read More
                </button>

                {/* Dots BELOW the Button */}
                <div className="custom-dots" style={{ cursor: 'pointer' }}>
                  {items.map((_, dotIndex) => (
                    <span
                      key={dotIndex}
                      className={`dot ${dotIndex === currentSlide ? "active-dot" : ""}`}
                      onClick={() => setCurrentSlide(dotIndex)}
                    />
                  ))}
                </div>
              </div>

              {/* Right Side - Image */}
              <div className="image-sectionone">
                <img src={item.image} alt="Blog" loading="lazy" decoding="async" />
              </div>
            </div>
          ))}

          {/* Navigation Arrows */}
          {/* <ArrowBackIcon 
            sx={{ fontSize: 30 }}
            className="lefticonarrow"
            onClick={prevSlide}
            style={{ cursor: 'pointer' }}
          />
          <ArrowForwardIcon
            sx={{ fontSize: 30 }}
            className="righticonarrow"
            onClick={nextSlide}
            style={{ cursor: 'pointer' }}
          /> */}
        </div>

        {/* Bottom Image */}
        <div className="bottom btmbnner">
          <img src={bottomImage} alt="bottom-image" loading="lazy" decoding="async" />
        </div>
      </div>
    </>
  );
};

export default BlogAnimation;



// import React, { useState, useEffect, lazy, Suspense, memo } from "react";
// import "./BlogAnimation.css";
// import robowith from "../../assets/img/AaysparticipationinVision.webp";
// import United from "../../assets/img/UnititedDesign.png";
// import plant from "../../assets/img/Geninfinance.webp";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import bottomImage from "../../assets/img/test-animation-grid.webp";

// const ParticleBackground = lazy(() => import("../../pages/NewHeaderSection/Network"));

// const BlogAnimation = memo(() => {
//   const items = [
//     {
//       image: robowith,
//       text: (
//         <>
//           CDO Vision NYC 2025 –<br /> AI-First Strategies & Enterprise Innovation
//         </>
//       ),
//       buttonLink: "/blogs/ai-first-strategy-enterprise-transformation-cdo-vision-2025",
//     },
//     {
//       image: United,
//       text: "Future of AI-Powered Decision Intelligence – Insights from Industry Leaders",
//       buttonLink: "/blogs/ai-powered-decision-intelligence",
//     },
//     {
//       image: plant,
//       text: "Generative AI in Finance – AaDi’s Impact on Analytics and Decision-Making",
//       buttonLink: "https://blog.aaysanalytics.com/post/generative-ai-in-finance-analytics-dwarika-patro-and-aim-research",
//     },
//   ];

//   const [currentSlide, setCurrentSlide] = useState(0);

//   const prevSlide = () => {
//     setCurrentSlide((prev) => (prev - 1 + items.length) % items.length); 
//   };

//   const nextSlide = () => {
//     setCurrentSlide((prev) => (prev + 1) % items.length); 
//   };

//   return (
//     <>
//       <Suspense fallback={<div>Loading Animation...</div>}>
//         <ParticleBackground />
//       </Suspense>
//       <div className="blog-animation-container">
//         <div className="carousel-wrapper-container">
//           {items.map((item, index) => (
//             <div key={index} className={`carousel-itemsss ${index === currentSlide ? "active" : ""}`}>
//               <div className="text-sectiona">
//                 <h2 className="itemText">{item.text}</h2>
//                 <button className="read-morebutton" style={{ position: 'relative' }}
//                   onClick={() => window.open(item.buttonLink, "_blank")}>
//                   Read More
//                 </button>
//                 <div className="custom-dots" style={{ cursor: 'pointer' }}>
//                   {items.map((_, dotIndex) => (
//                     <span key={dotIndex} className={`dot ${dotIndex === currentSlide ? "active-dot" : ""}`}
//                       onClick={() => setCurrentSlide(dotIndex)} />
//                   ))}
//                 </div>
//               </div>
//               <div className="image-sectionone">
//                 <img src={item.image} alt="Blog" loading="lazy" />
//               </div>
//             </div>
//           ))}
//           <ArrowBackIcon sx={{ fontSize: 30 }} className="lefticonarrow" onClick={prevSlide} style={{ cursor: 'pointer' }} />
//           <ArrowForwardIcon sx={{ fontSize: 30 }} className="righticonarrow" onClick={nextSlide} style={{ cursor: 'pointer' }} />
//         </div>
//         <div className="bottom btmbnner">
//           <img src={bottomImage} alt="right-imgae" loading="lazy" />
//         </div>
//       </div>
//     </>
//   );
// });

// export default BlogAnimation;

