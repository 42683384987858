import React, { useEffect } from "react";
import ParticleBackground from "./Network";
import gridImage from "../../assets/img/test-animation-grid.webp";
import background from "../../assets/img/test-animation-background.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import "./NewHeader.scss";

function IpadHeaderSection(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div
        className="test-animation-mobile"
        style={{
          height: "calc(100vh - 52px)",
          backgroundImage: `url(${background})`,
          position: "relative",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="main-container-mobile">
          <div className="grid-container-mobile">
            <div className="top-mobile">
              <img src={props.picture} alt="cpg" />
            </div>
            <div className="bottom-mobile">
              <div className="main-bottom">
                <p className="heading-mobile">
                  {props.text1 && (
                    <>
                      {props.text1} <br />
                    </>
                  )}
                  {props.text2 && (
                    <>
                      {props.text2} <br />
                    </>
                  )}
                  {props.text3 && (
                    <>
                      {props.text3} <br />{" "}
                    </>
                  )}
                  {props.text4 && <>{props.text4}</>}
                </p>

                <p className="subHeading-mobile">
                  {props.subtext1} <br /> {props.subtext2}{" "}
                </p>
              </div>

              <div className="grid-image">
                <img src={gridImage} alt="bottom-grid" />
              </div>
            </div>
          </div>
        </div>
        <ParticleBackground />
      </div>
    </>
  );
}

export default IpadHeaderSection;
