
import React, { useState } from "react";
import { ReactComponent as ReadMoreIcon } from "../../assets/img/read-more-arrow.svg";

function Mobile2Section(props) {
  const [firstItem, ...restItems] = props?.data;

  // State to handle the visibility of additional blogs
  const [showAll, setShowAll] = useState(false);

  // Toggle function to show/hide the rest of the items
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="customer-success-section2-mobile">
      <div className="Header-text">{props?.heading}</div>

      {/* First Box */}
      <div className="first-box">
        <div className="image-div">
          <img src={firstItem.image} alt={firstItem.heading} width="100%" />
        </div>
        <div className="box-heading">{firstItem.heading}</div>
        <div className="box-content">{firstItem.content}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <a
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            className="read-more-icon"
            href={`${firstItem.url}`}
          >
            <ReadMoreIcon width="40px" height="40px" />
          </a>
        </div>
      </div>

      {/* Show the rest of the blogs based on the 'showAll' state */}
      {showAll
        ? restItems.map((item) => (
            <a href={item.url} key={item.id} className="second-box">
              <img src={item.image} alt={item.heading} width="100%" />
              <div className="box-detail">
                <div className="box-heading">{item.heading}</div>
                <div className="box-content">{item.content}</div>
              </div>
            </a>
          ))
        : restItems.slice(0, 2).map((item) => (
            <a href={item.url} key={item.id} className="second-box">
              <img src={item.image} alt={item.heading} width="100%" />
              <div className="box-detail">
                <div className="box-heading">{item.heading}</div>
                <div className="box-content">{item.content}</div>
              </div>
            </a>
          ))}

      {/* 'See All' or 'Read More' Button */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={toggleShowAll}
          style={{
            backgroundColor: "transparent",
            border: "none",
            position:"relative",
            color: "#FFFFFF",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          {showAll ? "See Less" : "See All"}{" "}
        </button>
      </div>
    </div>
  );
}

export default Mobile2Section;
