import React, { useState } from "react";
import HeaderSection from "../../reuseLayout/HeaderSection";
import banner from "../../assets/img/DataEngBanner.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import ApplyJobs from "./ApplyJobs";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SchemaDataArchtect } from "../BlogSchema";
import { Helmet } from "react-helmet";
import Section6a from "../../pageLayout/CustomerSuccessStoryLayout/Section6a";
import Section5 from "../../pageLayout/CareerPageLayout/Section5";
import Marketrecent from "./Marketrecent";
import Section2Mobile from "../../pageLayout/CustomerSuccessStoryLayout/Section2Mobile";
import { useMediaQuery } from "@mui/material";
import  Bhmit from "../../assets/img/BumtSh.png"
import ai2as from "../../assets/img/AI-ML-for-decision.webp";
import AipwrdmdrnMarket from "../../assets/img/AipwrdmdrnMarket.png";





const dataMarket = [
    {
        id: 1,
        content:
        "The Role of AI-Driven Personalization in Modern Marketing",
      class: "content-box-lifeaa",
      image: AipwrdmdrnMarket,
      date: "September 24 ,2024",
      read :'2 min read',
      redirectURL: "https://blog.aaysanalytics.com/post/ai-driven-personalization-in-marketing",
      },
      {
        id: 2,
        content:
        "Building a Thriving Analytics Career: Insights from Bhumit Shah, AVP - Analytics",
      class: "content-box-lifeaa",
      image: Bhmit,
      date: "24-Dec-24",
      read :'2 min read',
      redirectURL: "https://blog.aaysanalytics.com/post/analytics-career-bhumit-shah-interview",
      },
      {
        id: 3,
        content:
        "Leveraging AI and ML for Business Decision-Making?",
      class: "content-box-lifeaa",
      image:ai2as,
      date: "September 4, 2024",
      read :'2 min read',
      redirectURL: "https://blog.aaysanalytics.com/post/leveraging-ai-and-ml-for-business-decision-making",
      },
    
  ];

const Data1 = [
  {
    id: 1,
    heading: "About the Role ",
    content:
      "This is an opportunity to be part of a growing team solving complex marketing challenges for global CPG brands using AI and analytics. As a Senior Manager / Director – Marketing Analytics, you will work closely with leadership to drive data-driven decision-making, enhance marketing effectiveness, and support business growth. Your expertise in marketing analytics, market mix modeling, and customer data platforms will help shape strategic solutions for our clients. This role offers the chance to collaborate with senior stakeholders, deliver impactful insights, and contribute to Aays' expansion in the U.S.  ",
  },
//   {
//     id: 2,
//     heading: "Your team",
//     content:
//       "As a key member of the consulting team, you will help clients re-invent their corporate finance functions by leveraging advanced analytics. You’ll work closely with senior stakeholders to design and implement data strategies in the finance domain, including use cases like controllership, FP&A, and GPO. Your role will include delivering scalable technical solutions using cloud and big data technologies. Collaboration with Business Consultants and Product Owners will be critical to ensure effective solutions. Strong communication and organizational skills are essential for success. ",
//   },
];
const Data2 = [
  {
    id: 1,
    heading: "Your Expertise  ",
    data: [
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Education:</span> Bachelor's or master’s degree in Marketing, Business, Statistics, or a related field 
 
     </>
        )
      },


      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Experience:</span> 10+ years in marketing analytics, preferably within a global CPG/FMCG organization, agency, or consulting firm
     </>
        )
      },
    //   {
    //     content: (
    //       <>
    //      <span style={{ fontWeight: 700 }}>Industry Standards:</span> Ensure alignment with industry-accepted data architecture principles, standards, and guidelines. 
    //  </>
    //     )
    //   },
    //   {
    //     content: (
    //       <>
    //      <span style={{ fontWeight: 700 }}>Cloud Expertise:</span>  Lead the design, sizing, and setup of Azure environments and related services. 
    //      </>
    //     )
    //   },
    //   {
    //     content: (
    //       <>
    //      <span style={{ fontWeight: 700 }}>Mentorship:</span> Provide mentoring on data architecture design and requirements to development and business teams.
    //  </>
    //     )
    //   },
    //   {
    //     content: (
    //       <>
    //      <span style={{ fontWeight: 700 }}>Solution Reviews:</span> Review solution requirements and architectures to ensure optimal resource use and system integration.
    //  </>
    //     ),
    //   },
    //   {
    //     content: (
    //       <>
    //      <span style={{ fontWeight: 700 }}>Technology Trends:</span> Advise on new technology trends and adoption strategies for maintaining competitive advantage.
    //  </>
    //     )
    //   },
    //   {
    //     content: (
    //       <>
    //      <span style={{ fontWeight: 700 }}>Thought Leadership:</span>  Participate in pre-sales activities and contribute to publishing thought leadership content.
    //  </>
    //     )
    //   },
    //   {
    //     content: (
    //       <>
    //      <span style={{ fontWeight: 700 }}>Technology Strategy: </span>  Collaborate with founders to drive the organization’s technology strategy. 
    //  </>
    //     )
    //   },
    //   {
    //     content: (
    //       <>
    //      <span style={{ fontWeight: 700 }}>Recruitment: </span> Lead recruitment efforts for technology roles in data analytics. 
    //  </>
    //     )
    //   },
    ],
  },
  {
    id: 2,
    heading: "Key Skills:  ",
    data: [
      {
        content: "Expertise in Market Mix Modeling (MMM) and attribution modeling at an enterprise scale  ",
      },
      {
        content:
          "Deep understanding of marketing data sources like CRM systems, web analytics, and marketing automation tools  ",
      },
      {
        content:
          "Hands-on experience in data manipulation and analysis using Excel, Power BI, and Python ",
      },
      {
        content:
          "Strong knowledge of CDP (Customer Data Platform) solutions  ",
      },
      {
        content:
          "Ability to present complex data insights in a clear, business-friendly manner  ",
      },
      {
        content:
          "Experience with data visualization and storytelling to communicate marketing performance  ",
      },
    ],
  },
  {
    id: 3,
    heading: "Why Join Aays?  ",
    data: [
      {
        content:
          "Work at the intersection of AI, big data, and marketing analytics ",
      },
      {
        content:
          "Collaborate with a global team solving real-world CPG challenges   ",
      }, {
        content:
          "Fast-track your career with leadership opportunities  ",
      },
      {
        content:
          "Be a part of a fast-growing firm shaping the future of data-driven marketing   ",
      },
    ]
  }
];

const data = [
  //   {
  //     text: <ChevronLeftIcon />,
     
  //  },
  {
    text: 'View all Jobs',
    url: '/career'
  }
]

function MarketingJob() {
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMediaQuery("(max-width: 767px)");
      const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
        defaultMatches: false, // Set defaultMatches to false
      });

  return (
    <div id="homePage" className="openPosition-box dataEngMobile career-main case-studies-pages-cls" >
      <Helmet>
      <script type="application/ld+json">
        {JSON.stringify( SchemaDataArchtect)}
        </script>
         </Helmet>

      <Navbar />
      <GoogleAnalytics />

      <HeaderSection text1=" Marketing Analytics Jobs " picture={banner} button={true} />

      <div className="position-main">
        <div className="position-body">
          <div className="new-box-seventy">

            <div className="back-button">
              {data.map((ele, index) => (
                <div style={{ display: 'flex' }}>
                  <ChevronLeftIcon />
                  <a href={ele.url} className="content" key={index}>   
                    {ele.text} 
                  </a>
                   {index !== data.length - 1} 
                </div>
              ))}
            </div>

            <div className="top">
              <div className="heading">
                <h4>
                <span>
                  <b>Job description</b>
                </span>
                </h4>
              </div>
              <div  className="position">
                <h3>
                <span>Position: Senior Manager / Director – Marketing Analytics (CPG) </span>
                </h3>
              </div>
              <div className="locationss">
                
                <span>Desired experience: 10-15 years </span>
              
              </div>
              <div className="locationss">
              Location: New York/New Jersey 
              </div>
              <div className="locationss">
              Level: Senior Management 
              </div>
              <div className="locationss">
              Salary: Best in Market 
              </div>
            </div>
            <hr
              style={{
                height: "5px",
                backgroundColor: "#bb1ccc",
                border: "none",
              }}
            />

            {Data1.map((item) => (
              <>
                <div className="heading1">
                  <div className="heading">
                    <span>
                      <h3>
                      <b>{item.heading}</b></h3>
                    </span>
                  </div>
                  <div className="content" >{item.content}</div>
                </div>
              </>
            ))}

            <div className="heading2-list">
              {Data2.map((item) => (
                <>
                  <div className="heading">
                    <h3>
                    <span>{item.heading}</span>
                    </h3>
                  </div>
                  <div className="list">
                    <ul>
                      {item.data.map((item1) => (
                        <li>{item1.content}</li>
                      ))}
                    </ul>
                  </div>
                </>
              ))}
            </div>
            <p style={{color:'white',margin:'3px 0'}} className="para-global">We help global CPG brands navigate complex marketing challenges using data and AI. In this role, you will drive strategic decision-making by applying advanced analytics, optimizing marketing performance, and shaping data-driven growth strategies </p>
            <p className="para-global">If you are looking to make an impact at a strategic level in marketing analytics, join us.  </p>
            <div className="apply-now">
              <button onClick={() => setOpenModal(true)}>Apply Now</button>
            </div>
           
    </div>
        

        </div>
       

      </div>

      <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position="Marketing Analytics" />
      <Section6a market="Our Work in Marketing Analytics "/>     
    



      <div className="career-body career-main">
     <Section5  explore="Explore Other Openings" />
     </div>
     {isMobile ? (
        <div style={{ paddingTop: "2.5rem", backgroundColor: "black" }}>
          <Section2Mobile
            heading="Recent Posts"
            data={dataMarket}
          />
        </div>
      ) : (
        <div className="section" id="caseStudySection2">
          < Marketrecent />
        </div>
      )} 
    


      <Footer />
    </div>
  );
}

export default MarketingJob;
