import React, { useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import tools1 from "../../assets/img/Architectural-Patterns.png";
import tools2 from "../../assets/img/Blogs-databricks.png";
import tools3 from "../../assets/img/Performance-Optimization-Spark.png";
import tools4 from "../../assets/img/Powerbi-beginer.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RightArrow from "../CustomerSuccessStoryLayout/RightArrow";
import LeftArrow from "../CustomerSuccessStoryLayout/LeftArrow";
import { useMediaQuery, useTheme } from "@mui/material/";



const items = [
  {
    id: 1,
    content:
      "New to Databricks? Here Are Some Useful Tips",
    class: "content-box-lifeaa",
    image: tools2,
    date: "Dec 06, 2021",
    read :'2 min read',
    url: "/blogs/databricks-tips-for-beginners",
  },
  {
    id: 2,
    content:
    "Common mistakes made by a Powerbi beginner & their best practice solutions",
  class: "content-box-lifeaa",
  image: tools4,
  date: "Oct 05, 2022",
  read :'2 min read',
  url: "/blogs/common-mistakes-made-by-a-Powerbi-beginner",
  },
  {
    id: 3,
    content:
    "Architectural Patterns for Analytics Journey Leveraging Azure Cloud Tech-Stack",
  class: "content-box-lifeaa",
  image: tools1,
  date: "Feb 14, 2022",
  read :'2 min read',
  url: "/blogs/business-analytics-architecture",
  },
  {
    id: 4,
    content:
    "Performance Optimization for Apache Spark",
  class: "content-box-lifeaa",
  image: tools3,
  date: "Nov 30, 2021",
  read :'2 min read',
  url: "/blogs/apache-spark-performance-optimization",
  },
]

function Section4()  {
const [startIndex, setStartIndex] = useState(0);

  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const showLength = isIpad ? 2 : 3;
  const visibleItems = isIpad
    ? items.slice(startIndex, startIndex + 2)
    : items.slice(startIndex, startIndex + 3);
  

  return (
    <div  id="section4-blog-success-story" className="customer-success-section3 content-box-lifeaays section scroll-section">      {/* customer-success-section3 */}
      <div className="customer-section3-body">
        <div className="success-story-page-section-3-Header-text">
        {/* id="caseStudySection3"  */}
         Technology
        </div>
       
        <div className="success-story-page-section-3-grid">
          {items.length > showLength && (
            <LeftArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
          {visibleItems.map((item) => (
            <div key={item.id}>
              <CustomerSectionBox
                picture={item.image}
                date={item.date}
                read={item.read}
                title1={item.title1}
                title2={item.title2}
                content={item?.content}
                class={item.class}
                redirectURL={item.url}
              />
            </div>
          ))}
          {items.length > showLength && (
            <RightArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Section4;
