import React from "react";
import RobotImage from "../../assets/img/careerPage-mainImage.webp";

function Section1({prop}) {
  return (
    <div className="about-section1 padding-both">
      <div>
        <div className="header-text">{prop}</div>
        <p className="content">
          Aays is the powerhouse behind transformative data and AI solutions,
          specializing in enterprise analytics within the manufacturing, CPG,
          retail and automotive industry. Our track record speaks volumes—having
          delivered over a billion-dollar impact for industry giants through
          cutting-edge AI-led analytics solutions.
        </p>
      </div>

      <div className="image-div">
        <img
          loading="lazy"
          style={{ width: "100%", padding: "0.5rem" }}
          src={RobotImage}
          alt="robot"
        />
      </div>
    </div>
  );
}

export default Section1;
