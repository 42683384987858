export const freq1 = [
    {
        id: 1,
        title: " How can organizations leverage artificial intelligence (AI) to improve decision-making processes?",
        description:
          "Organizations can leverage AI to enhance decision-making by using data-driven insights, automating routine tasks, and predicting future trends. AI-driven systems analyze voluminous amounts of data to decode patterns and trends that may not be immediately apparent, enabling more accurate and timely decisions.",
      },
      {
        id: 2,
        title: "How do ML and AI help businesses use their enterprise data effectively? ",
        description:
          "ML and AI help businesses use their enterprise data effectively by identifying actionable insights, optimizing processes, and predicting outcomes. These technologies allow enterprises to make data-driven decisions, improve efficiency, and gain a competitive edge. ",
      },
      {
        id: 5,
        title: "How is AI used in business decision-making?",
        description:
          "AI is used in business decision-making to analyze data, predict outcomes, and automate decisions. It helps firms make more informed decisions by providing real-time insights, optimizing operations, and reducing human biases.",
      },
      {
        id: 4,
        title: "What examples are there of AI being used to support decision-making?",
        description:
          "Examples of AI being used as decision-making support include predictive analytics for market trends, AI-driven risk assessments, and forecasting etc. AI is also used in customer relationship management (CRM) to provide personalized recommendations and improve customer satisfaction.",
      },
];
export const freq2 = [

  {
    id: 3,
    title: "What is the role of big data and AI artificial intelligence in operations decision-making?",
    description:
      "Big data and AI play a crucial role in operations decision-making by providing deep analytical insights, automating processes, and optimizing supply chains. These technologies empower businesses to make informed decisions that improve efficiency and reduce costs. ",
  },
      {
        id: 6,
        title: "What are the applications of artificial intelligence in decision-making?",
        description:
          "AI applications in decision-making include risk assessment, predictive analytics, customer segmentation, and supply chain optimization. AI can also automate routine tasks, freeing human resources for more strategic activities.",
      },
      {
        id: 7,
        title: "What are the benefits of AI decision-making?",
        description:
          "The benefits of AI decision-making include improved accuracy, efficiency, and speed. AI enables businesses to make data-driven decisions, cut op-ex, and respond quickly to market changes.",
      },

];
export const freq18 = [
  {
    id: 12,
    title: "What is the power of artificial intelligence in personalized marketing?",
    description: "The strength of artificial intelligence in personalized marketing stems from its capacity to swiftly and accurately analyze large volumes of data. AI can uncover patterns and trends in customer behavior that would be challenging for humans to detect, allowing businesses to deliver highly targeted and relevant marketing messages. This results in more meaningful customer interactions, higher engagement, and improved conversion rates, ultimately driving business growth.",
  },
  {
   id: 13,
   title: " How does AI help with personalization?",
   description: "AI personalizes experiences by analyzing customer data to grasp their preferences, behaviors, and needs. Utilizing machine learning algorithms, AI can predict the products and services a buyer is more likely to be interested in and offer real-time tailored recommendations. AI also enables businesses to automate and scale their personalization efforts, ensuring that every customer receives a tailored experience, regardless of the size of the customer base."
  },
  {
    id: 15,
    title: "What is the importance of personalization in modern marketing?",
    description: "Personalization is crucial in modern marketing because it allows business firms to connect with their customers more deeply. In a world where consumers are bombarded with generic marketing messages, personalized experiences stand out and create a lasting impression. Personalization increases customer satisfaction, loyalty, and engagement, leading to higher conversion rates and improved ROI. In today's competitive market, businesses that fail to deliver personalized experiences risk losing customers to competitors who do.",
  },


]
export const freq19 =[
   {
   id: 16,
   title: "What is the impact of personalization in marketing?",
   description: "The impact of personalization in marketing is significant, as it directly influences customer behavior and business outcomes. Personalized marketing messages are more likely to connect with customers, resulting in higher engagement, increased sales, and better customer retention. Personalization enables businesses to enhance their marketing strategies by targeting the right messages to the right customers at the optimal time, which results in more efficient marketing resource use and a better return on investment (ROI)."
   },
   {
    id: 17,
    title: "What is the most significant advantage of personalization?",
    description: "The primary advantage of personalization is its ability to craft impactful and relevant customer experiences. By customizing content and offers to align with individual preferences and needs, business firms can forge stronger connections with their customers. This approach fosters greater customer satisfaction, loyalty, and advocacy—essential for sustained success. Furthermore, personalization enhances marketing campaign effectiveness, resulting in improved conversion rates and increased revenue.",
   }

]

export const freq23 =[
  {
   id: 1,
   title: "What is analytics consulting, and why is it important? ",
   description:"Analytics consulting helps businesses leverage data, AI, and analytics to improve decision-making, optimize operations, and drive business growth. It bridges the gap between raw data and strategic business insights, enabling organizations to make informed, data-driven decisions. "
  },
  {
    id: 2,
    title: " How is AI transforming analytics consulting? ",
    description:"AI is automating data analysis, improving predictive modeling, and enhancing decision intelligence. Technologies like Generative AI and Agentic AI enable businesses to move beyond traditional analytics, offering impactful insights and strategic recommendations. "
  },
  {
    id: 3,
    title: "What are the key skills needed for leadership in AI consulting? ",
    description:"Successful AI consulting leaders need expertise in AI and data analytics, strong business strategy and problem-solving skills, and the ability to translate insights into actionable decisions. Effective stakeholder management and consulting leadership are also key to driving impact and collaboration. "
  },
  {
    id: 4,
    title: "How does Aays approach analytics consulting differently? ",
    description:"Aays combines AI-driven decision intelligence, Generative AI solutions, and deep industry expertise to create custom analytics consulting strategies. We foster a culture of innovation, ownership, and experimentation, enabling data-driven transformation for clients. "
  },
]


export const freq29 =[
  {
   id: 1,
   title: "What are the biggest challenges in building data science teams?  ",
   description:"Hiring the right mix of technical and business-oriented talent, ensuring continuous learning, and fostering collaboration across functions are some common challenges.  "
  },
  {
    id: 2,
    title: "  How do mentorship programs impact a data science team’s growth?  ",
    description:"Mentorship accelerates learning, improves retention, and helps new hires integrate faster into the team.  "
  },
  {
    id: 3,
    title: "Why are employee-centric policies important in data science roles?  ",
    description:"High-performing data scientists need flexibility, continuous learning, and a supportive work culture to stay motivated and innovative.  "
  },
  {
    id: 4,
    title: " How does Aays stay ahead in AI and data science?  ",
    description:"Aays continuously evolves by investing in upskilling, encouraging research-driven AI and gen ai projects, and adopting the latest AI advancements.  "
  },
]