import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import StickyButtons from "../pageLayout/BlogLayout/StickyButtons";
import Section2 from "../pageLayout/BlogLayout/Section2";
import Section3 from "../pageLayout/BlogLayout/Section3";
import Section4 from "../pageLayout/BlogLayout/Section4";
import Section5 from "../pageLayout/BlogLayout/Section5";
import CustomerSuccessSection from "../pageLayout/AboutPageLayout/Section5";
import Footer from "../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../pageLayout/BlogLayout/Blog.scss";
import Navbar from "../nav/NavBar";
import { CustomerSuccessStoriesData } from "../constant/CustomerSuccessStoriesData";
import HomePageSection5 from "../pageLayout/HomePageLayout/HomePageSection5";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import mobileBannerImage from "../assets/img/blog-banner-right.png";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import AiAndGenerativeAI from "../pageLayout/BlogLayout/AiAndGenerativeAI";
import BottomBanner from "../reuseLayout/BottomBanner";
import LifeAays from "../pageLayout/BlogLayout/LifeAays";
import { BlogSchema } from "./BlogSchema";
import { Helmet } from "react-helmet";
import BlogAnimation from "../pageLayout/BlogLayout/BlogAnimation";
import Section2Mobile from "../pageLayout/CustomerSuccessStoryLayout/Section2Mobile";
import tools5 from "../assets/img/Best-firms-for-data-scientists.webp";
import AmitGrnle from "../assets/img/AmitGrnle.png";
import DepkPrjapti from "../assets/img/DepkPrjapti.png";
import DvshPal from "../assets/img/DvshPal.png";
import DpRoy from "../assets/img/DpRoy.webp";
import RhulDesai from "../assets/img/RhulDesai.webp";
import Surbhjain from "../assets/img/Surbhjain.png";
import GrtPlcetoWork from "../assets/img/GrtPlcetoWork.webp";  
import Lavanya from "../assets/img/Lavanya-data.png";
import Tanishka from "../assets/img/Tanishka-Shah.webp";
import Sarthak from "../assets/img/Sarthak.png"
import Mobile2Section from "../pageLayout/CustomerSuccessStoryLayout/Mobile2Section";

import ai2 from "../assets/img/Gen-AI-Investment.webp";
import ai2as from "../assets/img/AI-ML-for-decision.webp";
import EUAIAct from "../assets/img/EUAIAct.webp";
import AipwrdmdrnMarket from "../assets/img/AipwrdmdrnMarket.png";
import AIAct from "../assets/img/AIAct.webp"
import LglnguegeModel from "../assets/img/LglnguegeModel.png"
import Drivingautomatic from "../assets/img/Driving-Automatic.webp";
import AiandGenai from "../assets/img/AiandGenai.png";
import TrnsformArFinance from "../assets/img/TrnsformArFinance.webp";
import AnmlyDtectionFinance from "../assets/img/AnmlyDtectionFinance.webp";
import FpaconsumerIndustry from "../assets/img/FpaconsumerIndustry.webp";
import Transformingfinance from "../assets/img/Transforming-finance-funct.webp"
import Datascince from "../assets/img/Data-science-finance.webp"
import Impletantion from "../assets/img/Impletantion-journey.webp";
import ai54 from "../assets/img/Blog-Img-Square.webp"
import SupplychainriskAi from "../assets/img/SupplychainriskAi.webp";
import DemndFrcasting from  "../assets/img/DemndFrcasting.webp";
import AIandInventoryMngement from "../assets/img/AIandInventoryMngement.png";
import Analytics from "../assets/img/Analytics-reliable-chain.webp"
import Transformingcpg from "../assets/img/Transforming-cpg-pricing.webp"
import Optimise from "../assets/img/Optimise-chain-cost.webp";
import ai23 from "../assets/img/Supply-Reduction.webp";
import tools1 from "../assets/img/Architectural-Patterns.png";
import tools2 from "../assets/img/Blogs-databricks.png";
import tools3 from "../assets/img/Performance-Optimization-Spark.png";
import tools4 from "../assets/img/Powerbi-beginer.png";
import company3 from "../assets/img/company3.jpg";
import company4 from "../assets/img/Seasoned-vendor.webp";
import dataEngineering from "../assets/img/Pema-Quadrant.png";
import Singapore from "../assets/img/We-are-in-singapore.webp";
import Microsoftsolution from "../assets/img/Microsoft-solutions.webp"
import BlogsFinancialtimess from "../assets/img/BlogsFinancialtimess.webp";
import Blogdatascientists from "../assets/img/Blogdatascientists.webp";
import blog from "../assets/img/Best-firms-for-data-scientists.webp"
import Stratgeties from "../assets/img/AI-First-Strategies.png";
import Futureai from "../assets/img/FutureAI-driven.png"
import Sumit from "../assets/img/SpotlightSumit.png";
import Avinash from "../assets/img/Spotlight-Avinash.webp"



const nextstep = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y + 700);
};

const nextstepDown = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y - 700);
};
const lifeData = [
   {
      id: 1,
      // title1: "Inside Aays' Culture: A Senior Data Engineer’s Take on Innovation and Growth",
      content:
        "Building Data Science Teams: A Leadership Perspective with Avinash Sidha",
      class: "content-box-lifeaa",
      image: Avinash ,
      date: "26 Mar 2025",
      read :'2 min read',
      url: "/blogs/building-data-science-teams-avinash-sidha-interview",
    },
  {
    id: 2,
    // title1: "Inside Aays' Culture: A Senior Data Engineer’s Take on Innovation and Growth",
    content:
      "Analytics Consulting and Leadership: Sumit Shekhar on AI, Strategy & Innovation",
    class: "content-box-lifeaa",
    image: Sumit,
    date: "17 Mar 2025",
    read :'2 min read',
    url: "/blogs/analytics-consulting-leadership-ai-sumit-shekhar",
  },
  {
    id: 3,
    // title1: "Inside Aays' Culture: A Senior Data Engineer’s Take on Innovation and Growth",
    content:
      "Inside Aays' Culture: A Senior Data Engineer’s Take on Innovation and Growth",
    class: "content-box-lifeaa",
    image: Sarthak,
    date: "5 Feb 2025",
    read :'2 min read',
    url: "https://blog.aaysanalytics.com/post/aays-culture-innovation-career-growth",
  },
  {
    id: 4,
    content:
    "Data Engineer Skills: Insights from Lavanya’s Journey at Aays",
  class: "content-box-lifeaa",
  image:Lavanya,
  date: "22-Jan-25",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/career-insights-data-engineer-skills",
  },
  {
    id: 5,
    content:
    "Data Analyst Consultant Insights: Career Growth Journey with Tanishka Shah",
  class: "content-box-lifeaa",
  image: Tanishka,
  date: "6-Jan-25",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/data-analyst-consultant-insights-tanishka-shah",
  },
  {
    id: 6,
    content:
    "Dip Roy on Building Successful Data Science Careers: Insights, Skills, and Emerging Trends",
  class: "content-box-lifeaa",
  image: DpRoy,
  date: "Dec 18, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/data-science-careers-dip-roy-interview",
  },
  {
    id: 7,
    content:
    "Data Engineering Career Journey: Rahul Desai on Growth, Challenges, and Teamwork",
  class: "content-box-lifeaa",
  image:RhulDesai,
  date: "Nov 25, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/rahul-desai-data-engineering-career-journey",
  },
  {
    id: 8,
    content:
    "Deepak's Data Engineering Career: Insights, Challenges, and Advice for Aspiring Professionals",
  class: "content-box-lifeaa",
  image:DepkPrjapti,
  date: "Nov 21, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/deepak-data-engineering-career-insights",
  },
  {
    id: 9,
    content:
    "Data Analyst Career Insights: Interview with Saurabh Jain, Senior Data Analyst",
  class: "content-box-lifeaa",
  image:Surbhjain,
  date: "Nov 6, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/data-analyst-career-insights-interview-with-saurabh-jain-senior-data-analyst",
  },
  {
    id: 10,
    content:
    "Empowered to Learn and Grow: Amit Girnale's Data Analytics Career Growth at Aays",
  class: "content-box-lifeaa",
  image:AmitGrnle,
  date: "Oct 24, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/amit-girnale-data-analytics-career-growth",
  },
  {
    id: 11,
    content:
    "Devesh Pal's Journey in Data Engineering at Aays",
  class: "content-box-lifeaa",
  image:DvshPal,
  date: "Oct 16, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/data-engineering-at-aays-devesh-pal-interview",
  },
  {
    id: 12,
    content:
    "Aays Recognized as One of the 50 Best Firms for Data Scientists to Work for in 2024",
  class: "content-box-lifeaa",
  image:tools4,
  date: "May 26, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/50-best-firms-for-data-scientists-2024",
  },
  {
    id: 13,
    content:
    "Aays is now Great Place to Work Certified",
  class: "content-box-lifeaa",
  image:GrtPlcetoWork,
  date: "Aug 30, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/aays-great-place-to-work-certified",
  },
];
const AiGenai = [
  {
    id: 1,
    content:
      "The Future of AI-Powered Decision Intelligence: Insights from Industry Leaders",
    class: "content-box-lifeaa",
    image: Futureai,
    date: "Feb 17 ,2025",
    read :'2 min read',
    url: "/blogs/ai-powered-decision-intelligence",
  },
  {
    id: 2,
    content:
      "Techniques for Ensuring Responsible and Efficient Use of Large Language Models (LLMs)",
    class: "content-box-lifeaa",
    image: LglnguegeModel,
    date: "Nov 29 ,2024",
    read :'2 min read',
    url: "https://blog.aaysanalytics.com/post/enhancing-techniques-for-effective-governance-of-llms",
  },
  {
    id: 3,
    content:
    "EU AI Act and Its Far-Reaching Implications for Businesses and Innovation",
  class: "content-box-lifeaa",
  image: AIAct,
  date: "Oct 4 ,2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/eu-ai-act",
  },
  {
    id: 4,
    content:
    "The Role of AI-Driven Personalization in Modern Marketing",
  class: "content-box-lifeaa",
  image: AipwrdmdrnMarket,
  date: "September 24 ,2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/ai-driven-personalization-in-marketing",
  },
  {
    id: 5,
    content:
    "Leveraging AI and ML for Business Decision-Making?",
  class: "content-box-lifeaa",
  image:ai2as,
  date: "September 4, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/leveraging-ai-and-ml-for-business-decision-making",
  },
  {
    id: 6,
    content:
    "Prioritizing AI and GenAI Use Cases: A Strategic Quadrant Framework for Enterprise Adoption",
  class: "content-box-lifeaa",
  image: AiandGenai,
  date: "Jul 1, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/prioritizing-ai-and-gen-ai-use-cases",
  },
  {
    id: 7,
    content:
    "Why Invest in Generative AI Now? Exploring AI Investment Strategies",
  class: "content-box-lifeaa",
  image:ai2,
  date: "Jul 8, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/why-invest-in-gen-ai-now",
  },
  {
    id: 8,
    content:
    "How is big data and automotive analytics are transforming the automotive industry?",
  class: "content-box-lifeaa",
  image:Drivingautomatic,
  date: "Feb 16, 2023",
    read :'2 mins read',
  url: "https://blog.aaysanalytics.com/post/how-is-big-data-and-analytics-helping-the-automotive-industry",
  },
]
const fince = [
  {
    id: 1,
    content:
      "How AI is Transforming Accounts Receivable Processes",
    class: "content-box-lifeaa",
    image: TrnsformArFinance,
    date: "8-Nov-24",
    read :'2 min read',
    url: "https://blog.aaysanalytics.com/post/ai-in-accounts-receivable",
  },
  {
    id: 2,
    content:
    "How AI is Transforming Financial Anomaly Detection",
  class: "content-box-lifeaa",
  image: AnmlyDtectionFinance,
  date: "29-Oct-24",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/financial-anomalies-with-ai",
  },
  {
    id: 3,
    content:
    "Data Analytics Implementation Journey in Business and Finance",
  class: "content-box-lifeaa",
  image: Impletantion,
  date: "March 31, 2023",
  read :'2 min read',
  url: "/blogs/data-analytics-implementation-journey-in-business-and-finance",
  },
  {
    id: 4,
    content:
    "How is AI transforming FP&A in the consumer goods industry?",
  class: "content-box-lifeaa",
  image: FpaconsumerIndustry,
  date: "March 3, 2023",
  read :'2 min read',
  url: "/blogs/how-is-ai-transforming-fp-a-in-the-consumer-goods-industry",
  },
  {
    id: 5,
    content:
    "Transforming Finance Function with Advanced Analytics",
  class: "content-box-lifeaa",
  image: Transformingfinance,
  date: "January 29, 2023",
  read :'2 min read',
  url: "/blogs/transforming-finance-function-with-advanced-analytics",
  },
  {
    id: 6,
    content:
    "How large enterprises are democratizing data science, ML in the corporate finance space",
  class: "content-box-lifeaa",
  image:Datascince,
  date: "December 14, 2022",
  read :'2 min read',
  url: "/blogs/how-large-enterprises-are-democratising-data-science-ml-in-the-corporate-finance-space",
  },
]
const CmpnyUpdate = [
  {
    id: 1,
    content:
      "Aays at CDO Vision NYC 2025: Key Takeaways on AI-First Strategies for Enterprises",
    class: "content-box-lifeaa",
    image: Stratgeties,
    date: "Feb 14, 2025",
    read :'2 min read',
    url: "/blogs/ai-first-strategy-enterprise-transformation-cdo-vision-2025",
  },
  {
    id: 2,
    content:
    "Aays Listed in 500 High Growth Companies Asia Pacific 2024 by Financial Times and Statista",
  class: "content-box-lifeaa",
  image: BlogsFinancialtimess,
  date: "July 4, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/aays-high-growth-companies-asia-pacific-2024",
  },
  {
    id: 3,
    content:
    "Aays Recognized as one of the Leaders in Data Engineering PeMa Quadrant",
  class: "content-box-lifeaa",
  image: dataEngineering,
  date: "May 27, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/aim-ranks-aays-a-leader-in-data-engineering-service-providers",
  },
  {
    id: 4,
    content:
    "Recognised as a Workspace Leaders in the List of 50 Best Firms for Data Scientists to Work for",
  class: "content-box-lifeaa",
  image: blog,
  date: "May 26, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/50-best-firms-for-data-scientists-2024",
  },
  {
    id: 5,
    content:
    "Aays Recognized as 'Seasoned Vendor' in Gen AI Report by AIM Research",
  class: "content-box-lifeaa",
  image:company4,
  date: "Aug 7, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/top-gen-ai-service-providers-aim-report-2023",
  },
  {
    id: 6,
    content:
    "Renewing our partnership with Microsoft: From Gold to Azure Solutions Partner",
  class: "content-box-lifeaa",
  image:Microsoftsolution,
  date: "Aug 6, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/microsoft-solutions-partner-azure"
  },
  {
    id: 7,
    content:
    "Aays Analytics forays into APAC region; kick-starts operations in Singapore",
  class: "content-box-lifeaa",
  image:Singapore,
  date: "Sep 19, 2022",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/aays-in-singapore",
  },
]
const SuplyChain = [
  {
    id: 1,
    content:
      "Supply Chain Cost Reduction Strategies: How AI and Analytics Revolutionize CPG Efficiency",
    class: "content-box-lifeaa",
    image: ai23,
    date: "29-Jan, 2025",
    read :'2 min read',
    url: "/blog/cpg-supply-chain-cost-reduction",
  },
  {
    id: 2,
    content:
    "The Next Era of Supply Chain Risk Management with AI Intelligence",
  class: "content-box-lifeaa",
  image: SupplychainriskAi,
  date: "Dec 19, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/the-next-era-of-supply-chain-risk-management-with-ai-intelligence",
  },
  {
    id: 3,
    content:
    "How AI is Reshaping the Future of Demand Forecasting",
  class: "content-box-lifeaa",
  image: DemndFrcasting,
  date: "Dec 3, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/ai-demand-forecasting",
  },
  {
    id: 4,
    content:
    "Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge",
  class: "content-box-lifeaa",
  image: AIandInventoryMngement,
  date: "Sep 11, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/ai-inventory-management",
  },
  {
    id: 5,
    content:
    "The Future of Supply Chain Management: How AI is Leading the Way?",
  class: "content-box-lifeaa",
  image: ai54,
  date: "Sep 13, 2024",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/the-future-of-supply-chain-management-how-ai-is-leading-the-way",
  },
  {
    id: 6,
    content:
    "Increase Sales with CPG Pricing Analytics: A 5-10% Boost Opportunity",
  class: "content-box-lifeaa",
  image: Transformingcpg,
  date: "Aug 8, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/transforming-cpg-pricing-analytics",
  },
  {
    id: 7,
    content:
    "Supply Chain Cost Reduction in CPG Firms through Data-Driven Optimization",
  class: "content-box-lifeaa",
  image: Optimise,
  date: "May 4, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/cpg-supply-chain-cost-reduction",
  },
  {
    id: 8,
    content:
    "Enhancing Supply Chain Management with Analytics: Cost Reduction and Efficiency",
  class: "content-box-lifeaa",
  image: Analytics,
  date: "April 13, 2023",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/analytics-in-supply-chain-management",
  },

]

const Tchnlgy = [
  {
    id: 1,
    content:
      "New to Databricks? Here Are Some Useful Tips",
    class: "content-box-lifeaa",
    image: tools2,
    date: "Dec 06, 2021",
    read :'2 min read',
    url: "/blogs/databricks-tips-for-beginners",
  },
  {
    id: 2,
    content:
    "Common mistakes made by a Powerbi beginner & their best practice solutions",
  class: "content-box-lifeaa",
  image: tools4,
  date: "Oct 05, 202",
  read :'2 min read',
  url: "/blogs/common-mistakes-made-by-a-Powerbi-beginner",
  },
  {
    id: 3,
    content:
    "Architectural Patterns for Analytics Journey Leveraging Azure Cloud Tech-Stack",
  class: "content-box-lifeaa",
  image: tools1,
  date: "Feb 14, 2022",
  read :'2 min read',
  url: "/blogs/business-analytics-architecture",
  },
  {
    id: 4,
    content:
    "Performance Optimization for Apache Spark",
  class: "content-box-lifeaa",
  image: tools3,
  date: "Nov 30, 2021",
  read :'2 min read',
  url: "/blogs/apache-spark-performance-optimization",
  },
]

function Blog() {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  return (
    <div id="homePage" className="blogspage-cls">
      <Helmet>
      <script type="application/ld+json">
        {JSON.stringify( BlogSchema)}
    </script>
</Helmet>
      <Navbar />
      <GoogleAnalytics />

      {/* {isMobile ? (
        <NewHeaderSectionMobile
          text1="Explore Our Latest Blog Posts"
          subtext1="Dive into a Wealth of Knowledge"
          subtext2="on Industry Trends, Tips, AI and Innovation"
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Explore Our Latest Blog Posts"
          subtext1="Dive into a Wealth of Knowledge"
          subtext2="on Industry Trends, Tips, AI and Innovation"
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1="Explore Our Latest Blog Posts"
          subtext1="Dive into a Wealth of Knowledge"
          subtext2="on Industry Trends, Tips, AI and Innovation"
          picture={mobileBannerImage}
        />
      )} */}
      
     
              <BlogAnimation />


      <MiddleBanner text="Our employees sharing their unique perspectives and valuable learnings, offering an insider's view into the world of analytics." />

      <div className=" case-studies-pages-cls">
        {!isMobile && !isIpad && <StickyButtons />}
       
        
        {isMobile ? (
        <Mobile2Section heading="Company Update" data={CmpnyUpdate} />
      ) : (
        <Section5 />
      )}
       
        {isMobile ? (
        <Mobile2Section heading="AI and Generative AI" data={AiGenai} />
      ) : (
        <AiAndGenerativeAI />
      )}
      
      {isMobile ? (
        <Mobile2Section heading="Finance" data={fince} />
      ) : (
        <Section2 />
      )}
        {isMobile ? (
        <Mobile2Section heading=" Supply Chain & CPG" data={SuplyChain} />
      ) : (
        <Section3 />
      )}
         {isMobile ? (
        <Mobile2Section heading=" Technology" data={Tchnlgy} />
      ) : (
        <Section4 />
      )}
        
      
        {isMobile ? (
        <Mobile2Section heading="Life at Aays" data={lifeData} />
      ) : (
        <LifeAays />
      )}
      </div>

      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <CustomerSuccessSection
            data={CustomerSuccessStoriesData}
            tag="blog"
            show="true"
          />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="blog"
            show="true"
          />
        )}
        <BottomBanner contentChange={true} />
        <Footer />
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default Blog;
