import React, { useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import blog1 from "../../assets/img/blog1.png";
import blog2 from "../../assets/img/blog2.jpg";
import blog3 from "../../assets/img/blog3.jpg";
import blog4 from "../../assets/img/blog4.jpg";
import TrnsformArFinance from "../../assets/img/TrnsformArFinance.webp";
import AnmlyDtectionFinance from "../../assets/img/AnmlyDtectionFinance.webp";
import FpaconsumerIndustry from "../../assets/img/FpaconsumerIndustry.webp";
import Transformingfinance from "../../assets/img/Transforming-finance-funct.webp"
import Datascince from "../../assets/img/Data-science-finance.webp"
import Impletantion from "../../assets/img/Impletantion-journey.webp";
import { useMediaQuery, useTheme } from "@mui/material/";
import RightArrow from "../CustomerSuccessStoryLayout/RightArrow";
import LeftArrow from "../CustomerSuccessStoryLayout/LeftArrow";

import "react-multi-carousel/lib/styles.css";

const items = [
  {
    id: 1,
    content:
      "How AI is Transforming Accounts Receivable Processes",
    class: "content-box-lifeaa",
    image: TrnsformArFinance,
    date: "8-Nov-24",
    read :'2 min read',
    url: "https://blog.aaysanalytics.com/post/ai-in-accounts-receivable",
  },
  {
    id: 2,
    content:
    "How AI is Transforming Financial Anomaly Detection",
  class: "content-box-lifeaa",
  image: AnmlyDtectionFinance,
  date: "29-Oct-24",
  read :'2 min read',
  url: "https://blog.aaysanalytics.com/post/financial-anomalies-with-ai",
  },
  {
    id: 3,
    content:
    "Data Analytics Implementation Journey in Business and Finance",
  class: "content-box-lifeaa",
  image: Impletantion,
  date: "March 31, 2023",
  read :'2 min read',
  url: "/blogs/data-analytics-implementation-journey-in-business-and-finance",
  },
  {
    id: 4,
    content:
    "How is AI transforming FP&A in the consumer goods industry?",
  class: "content-box-lifeaa",
  image: FpaconsumerIndustry,
  date: "March 3, 2023",
  read :'2 min read',
  url: "/blogs/how-is-ai-transforming-fp-a-in-the-consumer-goods-industry",
  },
  {
    id: 5,
    content:
    "Transforming Finance Function with Advanced Analytics",
  class: "content-box-lifeaa",
  image: Transformingfinance,
  date: "January 29, 2023",
  read :'2 min read',
  url: "/blogs/transforming-finance-function-with-advanced-analytics",
  },
  {
    id: 6,
    content:
    "How large enterprises are democratizing data science, ML in the corporate finance space",
  class: "content-box-lifeaa",
  image:Datascince,
  date: "December 14, 2022",
  read :'2 min read',
  url: "/blogs/how-large-enterprises-are-democratising-data-science-ml-in-the-corporate-finance-space",
  },
]

function Section2() {
  const [startIndex, setStartIndex] = useState(0);

  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const showLength = isIpad ? 2 : 3;
  const visibleItems = isIpad
    ? items.slice(startIndex, startIndex + 2)
    : items.slice(startIndex, startIndex + 3);
  

  return (
    <div  id="section2-blog-success-story" className="customer-success-section3 content-box-lifeaays section scroll-section">      {/* customer-success-section3 */}
      <div className="customer-section3-body">
        <div className="success-story-page-section-3-Header-text">
        {/* id="caseStudySection3"  */}
        Finance
        </div>
       
        <div className="success-story-page-section-3-grid">
          {items.length > showLength && (
            <LeftArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
          {visibleItems.map((item) => (
            <div key={item.id}>
              <CustomerSectionBox
                picture={item.image}
                date={item.date}
                read={item.read}
                title1={item.title1}
                title2={item.title2}
                content={item?.content}
                class={item.class}
                redirectURL={item.url}
              />
            </div>
          ))}
          {items.length > showLength && (
            <RightArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Section2;
