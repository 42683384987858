import AipwrdmdrnMarket from "../../assets/img/AipwrdmdrnMarket.png";
import  { useState } from "react";
import { useMediaQuery } from "@mui/material";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import LeftArrow from "../../pageLayout/CustomerSuccessStoryLayout/LeftArrow";
import RightArrow from "../../pageLayout/CustomerSuccessStoryLayout/RightArrow";
import Leadersintop from "../../assets/img/Leadersintop.png";
import BlogsFinancialtimess from "../../assets/img/BlogsFinancialtimess.webp";
import  Bhmit from "../../assets/img/BumtSh.png"
import ai2as from "../../assets/img/AI-ML-for-decision.webp";




// const data = [
//     {
//         id: 1,
//         content:
//         "The Role of AI-Driven Personalization in Modern Marketing",
//         class: "content-box-section2 inventrynew",
//       image: AipwrdmdrnMarket,
//       date: "September 24 ,2024",
//       read :'2 min read',
//         url: "https://blog.aaysanalytics.com/post/ai-driven-personalization-in-marketing",
//       },
//       {
//         id: 2,
//         content:
//         "Building a Thriving Analytics Career: Insights from Bhumit Shah, AVP - Analytics",
//         class: "content-box-section2 inventrynew",
//       image: Bhmit,
//       date: "24-Dec-24",
//       read :'2 min read',
//       url: "https://blog.aaysanalytics.com/post/analytics-career-bhumit-shah-interview",
//       },
//       {
//         id: 3,
//         content:
//         "Leveraging AI and ML for Business Decision-Making?",
//         class: "content-box-section2 inventrynew",
//       image:ai2as,
//       date: "September 4, 2024",
//       read :'2 min read',
//       url: "https://blog.aaysanalytics.com/post/leveraging-ai-and-ml-for-business-decision-making",
//       },
    
//   ];
const data = [
  {
      id: 1,

      title1: "The Role of AI-Driven",
      title2 : "Personalization in Modern Marketing",
      class: "content-box-section2 inventrynew",
    image: AipwrdmdrnMarket,
    date: "September 24 ,2024",
    read :'2 min read',
      url: "https://blog.aaysanalytics.com/post/ai-driven-personalization-in-marketing",
    },
    {
      id: 2,
      title1: "Building a Thriving Analytics Career:",
      title2: "Insights from Bhumit Shah, AVP - Analytics",
      class: "content-box-section2 inventrynew",
    image: Bhmit,
    date: "24-Dec-24",
    read :'2 min read',
    url: "https://blog.aaysanalytics.com/post/analytics-career-bhumit-shah-interview",
    },
    {
      id: 3,
      title1: "Leveraging AI and ML",
      title2: "for Business Decision-Making?",
      class: "content-box-section2 inventrynew",
    image:ai2as,
    date: "September 4, 2024",
    read :'2 min read',
    url: "https://blog.aaysanalytics.com/post/leveraging-ai-and-ml-for-business-decision-making",
    },
  
];

const Marketrecent = () => {
    const [startIndex, setStartIndex] = useState(0);

    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });
  
    const showLength = isIpad ? 2 : 3;
    const visibleItems = isIpad ? data.slice(startIndex, startIndex + 2) : data.slice(startIndex, startIndex + 3);
  
    return (
      <div
        id="section2-customer-success-story"
        className="customer-success-section2 scroll-section"
        style={{position: 'relative',backgroundColor:'black'}}
      >
        <div className="customer-section2-body margin-box">
          <h1 className="success-story-page-section-2-Header-text" style={{color:'white'}}>
          Recent Posts 
          </h1>
          
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {data.length > showLength && <LeftArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
            <div className="success-story-page-section-2-grid">
              {visibleItems.map((item) => (
                <div>
                  <CustomerSectionBox
                    picture={item.image}
                    title1={item.title1}
                    title2={item.title2}
                    content={item?.content}
                    class={item.class}
                    redirectURL={item.url}
                  />
                </div>
              ))}
            </div>
            {data.length > showLength && <RightArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
          </div>
        </div>
      </div>
    );
  }
  

export default Marketrecent