import React from 'react'
import  { useState } from "react";
import { useMediaQuery } from "@mui/material";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import "react-multi-carousel/lib/styles.css";

 import RightArrow from "../../pageLayout/CustomerSuccessStoryLayout/RightArrow";
 import LeftArrow from "../../pageLayout/CustomerSuccessStoryLayout/LeftArrow";
 import Supplychain from  "../../assets/img/BlogsFinancialtimess.webp";
 import AiandInventory from "../../assets/img/Leadersintop.png";
 import Casestudyinventoanalytics from "../../assets/img/Dwarika-Gen-Ai.png"





const data = [
    {
        id: 1,
         title1: "Generative AI in Finance – Dwarika Patroy",
         title2: "Discusses AaDi’s Impact on Analytics and Decision-Making",
      //  content: "Generative AI in Finance – Dwarika Patro Discusses AaDi’s Impact on Analytics and Decision-Making ",
        class: "content-box-section2",
        url: "https://blog.aaysanalytics.com/post/generative-ai-in-finance-analytics-dwarika-patro-and-aim-research",
        image: Casestudyinventoanalytics,
      },
      {
        id: 2,
        title1: "Aays Listed in 500 ",
         title2: "High Growth Companies Asia Pacific 2024 by Financial Times",
        //content: "The Next Era of Supply Chain Risk Management with AI Intelligence",
        class: "content-box-section2 inventrynew",
        url: "https://blog.aaysanalytics.com/post/aays-high-growth-companies-asia-pacific-2024",
        image:Supplychain ,
      },
      {
      id: 3,
        title1: "Aays Recognized as one of the Leaders",
         title2: "in Data Engineering PeMa Quadrant 2024",
       // content: "Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge",
        class: "content-box-section2 inventrynew",
        url: "https://blog.aaysanalytics.com/post/aim-ranks-aays-a-leader-in-data-engineering-service-providers",
        image: AiandInventory,
        
      }
  ];

const FirstPost = () => {
    const [startIndex, setStartIndex] = useState(0);

    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });
  
    const showLength = isIpad ? 2 : 3;
    const visibleItems = isIpad ? data.slice(startIndex, startIndex + 2) : data.slice(startIndex, startIndex + 3);
  
    return (
      <div
        id="section2-customer-success-story"
        className="customer-success-section2 scroll-section"
        style={{position: 'relative',backgroundColor:'white'}}
      >
        <div className="customer-section2-body margin-box">
          <h2 className="success-story-page-section-2-Header-text" style={{color:'black'}}>
          Recent Posts 
          </h2>
          
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {data.length > showLength && <LeftArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
            <div className="success-story-page-section-2-grid">
              {visibleItems.map((item) => (
                <div>
                  <CustomerSectionBox
                    picture={item.image}
                    title1={item.title1}
                    title2={item.title2}
                    content={item?.content}
                    class={item.class}
                    redirectURL={item.url}
                  />
                </div>
              ))}
            </div>
            {data.length > showLength && <RightArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
          </div>
        </div>
      </div>
    );
  }
  

export default FirstPost